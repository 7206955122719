import React from 'react';
import {
  FaUser,
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTiktok,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Cookies: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };
  return (
    <div>
      {/* Top Bar */}
      <div className="flex justify-end items-center p-2.5 bg-[#6DD2BF]">
        <div className="flex items-center space-x-5">
          <button
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
            onClick={handleLoginRedirect}
          >
            <FaUser className="mr-2" /> Iniciar Sesión
          </button>
          <a
            href="https://wa.me/34622713212"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
          >
            <FaWhatsapp className="mr-2" /> Whatsapp
          </a>
          <a
            href="https://www.linkedin.com/company/e-pitinglish/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.instagram.com/epitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/ePitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.tiktok.com/@epitinglish"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaTiktok />
          </a>
        </div>
      </div>

      {/* Header Menu */}
      <div className="flex justify-between items-center p-4 bg-[#6DD2BF]">
        <div className="flex items-center">
          <img src="/logo.jpeg" alt="Logo" className="h-12" />
        </div>
        <div className="flex justify-around items-center space-x-8">
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            NUESTRA METODOLOGIA
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PROFESORES
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PRECIOS
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            BLOG
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            CONTACTO
          </button>
        </div>
      </div>
      {/* New Section */}
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-4 text-[#333]">
          Política de cookies{' '}
        </h1>
        <p className="mb-4 text-[#444] leading-7">
          Nuestro sitio web e-pitinglish.com utiliza una tecnología llamada
          cookie, con el fin de poder obtener información sobre el uso del sitio
          web, mejorar la experiencia del usuario y garantizar el su correcto
          funcionamiento.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Definición de Cookie
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Una cookie es un pequeño fragmento de texto que los sitios web envían
          al navegador y que se almacenan en el terminal del usuario, (ordenador
          o dispositivo móvil). Estos archivos tienen la finalidad de almacenar
          datos que podrán ser actualizados y recuperadas por la entidad
          responsable de su instalación. Así, cada vez que el usuario vuelve a
          visitar el mismo sitio web, el explorador recupera el archivo y lo
          envía al servidor del sitio web. Las cookies tienen varias funciones
          entre las que se incluyen, la de recoger información sobre las
          preferencias del usuario y recordarla, y en general, facilitar el uso
          del sitio web al usuario haciendo que el sitio sea más útil al
          personalizar su contenido.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Las cookies se pueden clasificar en base a diferentes parámetros:
        </p>
        <ol className="list-decimal list-inside space-y-4">
          <li>
            <span className="font-semibold">Titularidad:</span> cookies propias
            y cookies de terceros: dependiendo de cuál sea la entidad que
            gestione el dominio desde donde se envían las cookies y se traten
            los datos que se obtengan.
          </li>
          <li>
            <span className="font-semibold">Duración:</span> cookies de sesión y
            cookies persistentes: de acuerdo con el plazo de tiempo que
            permanecen almacenadas en el terminal del usuario.
          </li>
          <li>
            <span className="font-semibold">Finalidad:</span> de acuerdo con la
            finalidad para la que se traten los datos obtenidos.
            <ul className="list-disc list-inside mt-2 ml-5 space-y-2">
              <li>
                <span className="font-semibold">Cookies técnicas:</span> son
                aquellas que permiten al usuario la navegación a través de la
                página web o aplicación y la utilización de las diferentes
                opciones o servicios que en ella existen. Por ejemplo, controlar
                el tráfico y la comunicación de datos, identificar la sesión o
                acceder a las partes web de acceso restringido.
              </li>
              <li>
                <span className="font-semibold">
                  Cookies de preferencias o personalización:
                </span>{' '}
                Son aquellas que permiten recordar información para que el
                usuario acceda al servicio con determinadas características que
                pueden diferenciar su experiencia de la de otros usuarios, como,
                por ejemplo, el idioma, el número de resultados a mostrar cuando
                el usuario realiza una búsqueda, el aspecto o contenido del
                servicio en función del tipo de navegador a través del cual el
                usuario accede al servicio o de la región desde la que accede al
                servicio, etc.
              </li>
              <li>
                <span className="font-semibold">
                  Cookies de análisis o medición:
                </span>{' '}
                Son aquellas que permiten a su responsable el seguimiento y el
                análisis del comportamiento de los usuarios de los sitios web a
                los que están vinculadas, incluida la cuantificación de los
                impactos de los anuncios. La información recogida mediante este
                tipo de cookies se utiliza en la medición de la actividad de los
                sitios web, aplicación o plataforma, con el fin de introducir
                mejoras en función del análisis de los datos de uso que hacen
                los usuarios del servicio.
              </li>
              <li>
                <span className="font-semibold">
                  Cookies de publicidad comportamental:
                </span>{' '}
                Son aquellas que almacenan información del comportamiento de los
                usuarios obtenida a través de la observación continuada de sus
                hábitos de navegación, lo que permite desarrollar un perfil
                específico para mostrar publicidad en función de este perfil.
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Cookies utilizadas en nuestro sitio web
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Nuestro sitio web utiliza tanto cookies propias, que son las enviadas
          a su dispositivo y gestionadas exclusivamente por nosotros para el
          mejor funcionamiento del sitio web, como cookies de terceros, con la
          función de servirnos como herramienta de análisis de la interacción
          los usuarios con nuestra web, gracias a ellas obtenemos una
          información que puede ayudar a mejorar la navegación y dar un mejor
          servicio a los usuarios.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Utilizamos tanto cookies temporales de sesión como cookies
          persistentes. Las cookies de sesión almacenan datos únicamente
          mientras el usuario accede a la web y las cookies persistentes
          almacenan los datos en el terminal para que sean accedidas y
          utilizadas en más de una sesión.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Concretamente las cookies utilizadas en nuestro sitio web son:
        </p>

        <h3 className="text-2xl font-semibold mb-3 text-[#333]">
          Cookies propias:
        </h3>
        <p className="mb-4 text-[#444] leading-7">
          Las cookies propias que utilizamos en nuestro sitio web son de
          carácter técnico, fundamentales para el funcionamiento de la web.
        </p>

        <h3 className="text-2xl font-semibold mb-3 text-[#333]">
          Cookies de terceros:
        </h3>
        <p className="mb-4 text-[#444] leading-7">
          <strong>__utmz, __ utmc, __utma, __utmb,__utmt, __ga, __gat</strong>
        </p>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>Titular: Google Inc. (Google Analytics)</li>
          <li>Finalidad: Analíticas: analizan el tráfico de esta web.</li>
          <li>Duración: Sesión y persistentes</li>
        </ul>
        <p className="mb-4 text-[#444] leading-7">
          Para obtener más información sobre las cookies y/o para su
          desactivación puede clicar sobre los enlaces que hay en el nombre de
          los diferentes titulares.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Finalmente, también se utilizan cookies de complemento (plug-in) para
          intercambiar contenidos sociales.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Consentimiento
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          El consentimiento para el uso de las cookies antes enunciadas, por los
          plazos señalados y en las condiciones contenidas en la presente
          Política de Cookies, se obtendrá con la aceptación del aviso inicial
          de información sobre las cookies que se visualiza en el momento en què
          carga nuestra web.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Asimismo, le informamos de que si usted consulta contenidos en nuestra
          web alojados en webs de terceros, estos tienen su propia política de
          cookies.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Retirada del consentimiento
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          El usuario puede retirar su consentimiento para el uso de cookies en
          cualquier momento. Para ello, puede usar el botón de la web que haya a
          tal efecto o eliminar las cookies de su navegador.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Modificación de la configuración de las cookies
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          El usuario puede restringir, bloquear o borrar las cookies de
          cualquier sitio web, utilizando su navegador. En cada navegador la
          operativa es diferente, por lo que puede consultar los siguientes
          enlaces la forma de hacerlo
        </p>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>
            <a
              href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Edge
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/es/kb/clear-cookies-and-site-data-firefox"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              FireFox
            </a>
          </li>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=es"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/es-es/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Safari (Mac)
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/es-es/HT201265"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Safari (iPhone/iPad)
            </a>
          </li>
          <li>
            <a
              href="https://help.opera.com/en/latest/web-preferences/#cookies"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Opera
            </a>
          </li>
        </ul>
        <p className="mb-4 text-[#444] leading-7">
          Para obtener más información sobre la eliminación, deshabilitación o
          bloqueo de las cookies también puede visitar este{' '}
          <a
            href="https://www.aboutcookies.org/how-to-manage-and-delete-cookies"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            link
          </a>
          .
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Actualización de la política de cookies
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Este sitio web puede modificar esta Política sobre Cookies, por ello
          le recomendamos revisar esta política cada vez que acceda a nuestro
          sitio web para estar adecuadamente informado sobre el uso que hacemos
          de las cookies.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Última actualización: 30/07/2024.
        </p>
      </div>
    </div>
  );
};

export default Cookies;
