import React, { useState } from 'react';
import * as SolarIconSet from 'solar-icon-set';
import { useNavigate } from 'react-router-dom';
import ChatBot from '../../../Portal/Header/ChatBot';
import { User } from '../../../../@types/usersTypes';

interface HeaderProps {
  user: User | null;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({ user, setActivePage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    navigate('/login');
    setIsMenuOpen(false);
  };

  const handleMenuOptionClick = (page: string) => {
    setActivePage(page);
    setIsMenuOpen(false);
  };

  return (
    <header className="flex justify-end items-center mb-1 p-2 h-auto z-10 relative">
      {/* Header Icons */}
      <div className="flex items-center gap-8 mr-5">
        {/* <button>
          <SolarIconSet.Magnifer size={24} color="#1C274C" />
        </button>
        <button>
          <SolarIconSet.ChatRound size={24} color="#1C274C" />
        </button> */}
        <button>
          <SolarIconSet.Bell size={24} color="#1C274C" />
        </button>
        <div className="relative flex items-center gap-[6.5px]">
          <img
            src={user?.profilePicture || ''}
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
          <button onClick={toggleMenu}>
            <SolarIconSet.AltArrowDown size={12} />
          </button>

          {/* Dropdown Menu */}
          {isMenuOpen && (
            <div className="absolute top-9 right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-20">
              <ul className="py-1">
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={() => handleMenuOptionClick('Account')}
                  >
                    Mi Cuenta
                  </button>
                </li>
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={() => handleMenuOptionClick('AccountSettings')}
                  >
                    Ajustes
                  </button>
                </li>
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={handleLogout}
                  >
                    Cerrar Sesión
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Chat Bot Button */}
      <ChatBot />
    </header>
  );
};

export default Header;
