import React, { useState } from 'react';
import { TeacherProfile } from '../../../@types/usersTypes';
import TeacherIntroCard from './TeacherIntroCard';
import GreenButton from '../../UI/GreenButton';
import { teacherColors } from '../../../utils/teacherColors';

interface TeacherClassesProps {
  teacher: TeacherProfile | undefined;
  setActivePage: (page: string) => void;
}

const daysOfWeek = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];

const TeacherClasses: React.FC<TeacherClassesProps> = ({
  teacher,
  setActivePage,
}) => {
  const [selectedClass, setSelectedClass] = useState('');

  // Função para obter o primeiro dia da semana (domingo)
  const getStartOfWeek = (date: Date) => {
    const dayOfWeek = date.getDay(); // 0 = domingo, 1 = segunda, etc.
    const firstDayOfWeek = new Date(date);
    firstDayOfWeek.setDate(date.getDate() - dayOfWeek);
    return firstDayOfWeek;
  };

  // Função para obter os dias de uma semana
  const getWeekDays = (startOfWeek: Date) => {
    return Array.from({ length: 7 }, (_, i) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      return day;
    });
  };

  const startOfWeek = getStartOfWeek(new Date());
  const weekDays = getWeekDays(startOfWeek);

  const renderItems = () => {
    return Array.from({ length: 15 }).map((_, rowIndex) => (
      <>
        {/* Coluna de horários */}
        <div
          key={`time-${rowIndex}`}
          className="h-[36.27px] flex items-center justify-end pr-2 text-sm text-gray-500 text-opacity-100 opacity-50 border-t-[1px] border-solid border-grey-200 relative bottom-[0.05rem]"
        >
          <p className="w-[92px] relative bottom-[1.15rem] z-10 bg-white text-right pr-[2px]">
            {7 + rowIndex}:00
          </p>
        </div>

        {/* Células de cada dia da semana */}
        {weekDays.map((weekDay, dayIndex) => (
          <div
            key={`day-${dayIndex}-time-${rowIndex}`}
            className={getWeeklyClasses(dayIndex, rowIndex)}
          >
            {teacher?.Teacher?.availabilitySlots?.map((slot, index) => {
              const startTime = slot.startTime;
              const dateStart = new Date(`1970-01-01T${startTime}`);
              const localStartTime = dateStart.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              });

              const endTime = slot.endTime;
              const dateEnd = new Date(`1970-01-01T${endTime}`);
              const localEndTime = dateEnd.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              });
              let zIndex = 0;
              if (
                slot.dayOfWeek === weekDay.getDay() &&
                +localStartTime.slice(0, 2) - 7 === rowIndex
              ) {
                if (+localEndTime.slice(0, 2) - 7 === rowIndex) {
                  zIndex = 25 - rowIndex - index;
                  return (
                    <button
                      key={`${teacher?.id}-${teacher?.fullName}-${dayIndex}-${weekDay}-${rowIndex}-${startTime}`}
                      style={{ zIndex }}
                      onClick={() => {
                        setSelectedClass(slot.id);
                      }}
                      className={`${selectedClass === slot.id ? 'bg-opacity-100' : 'bg-opacity-10'} ${teacherColors(teacher?.Teacher?.backgroundColor)} relative cursor-pointer w-[100px] h-[18.12px] border-2 rounded-md gap-1 flex px-[2px] items-center justify-center border-emerald-600 border-opacity-20 bg-emerald-500 hover:bg-opacity-100 group`}
                    >
                      <img
                        src={teacher?.profilePicture ?? undefined}
                        className="rounded-3xl w-3 h-3"
                        alt=""
                      />
                      <div className="nunito text-left flex justify-center gap-1">
                        <span
                          className={`${selectedClass === slot.id ? 'text-white' : null} font-bold text-[10px] group-hover:text-white`}
                        >
                          {teacher?.fullName.slice(
                            0,
                            teacher?.fullName.indexOf(' ')
                          )}
                        </span>
                        <p
                          className={`${selectedClass === slot.id ? 'text-white' : null} font-normal text-[8px] mt-[2px] group-hover:text-white`}
                        >
                          {localStartTime}
                        </p>
                      </div>
                    </button>
                  );
                }
                if (+localEndTime.slice(0, 2) - 8 === rowIndex) {
                  zIndex = 24 - rowIndex - index;
                  return (
                    <button
                      key={`${teacher?.id}-${teacher?.fullName}-${dayIndex}-${weekDay}-${rowIndex}-${endTime}`}
                      style={{ zIndex }}
                      onClick={() => {
                        setSelectedClass(slot.id);
                      }}
                      className={`${selectedClass === slot.id ? 'bg-opacity-100' : 'bg-opacity-10'} ${teacherColors(teacher?.Teacher?.backgroundColor)} relative cursor-pointer w-[100px] h-[18.12px] border-2 border-solid rounded-md gap-1 flex px-[2px] items-center justify-center border-emerald-600 border-opacity-20 bg-emerald-500 hover:bg-opacity-100 group`}
                    >
                      <img
                        src={teacher?.profilePicture ?? undefined}
                        className="rounded-3xl w-3 h-3"
                        alt=""
                      />
                      <div className="nunito text-left flex justify-center gap-1">
                        <span
                          className={`${selectedClass === slot.id ? 'text-white' : null} font-bold text-[10px] group-hover:text-white`}
                        >
                          {teacher?.fullName.slice(
                            0,
                            teacher?.fullName.indexOf(' ')
                          )}
                        </span>
                        <p
                          className={`${selectedClass === slot.id ? 'text-white' : null} font-normal text-[8px] mt-[2px] group-hover:text-white`}
                        >
                          {localStartTime}
                        </p>
                      </div>
                    </button>
                  );
                }
              }
              return null;
            })}
          </div>
        ))}
      </>
    ));
  };

  const getWeeklyClasses = (dayIndex: number, rowIndex: number) => {
    let cellClasses;
    cellClasses = 'calendar-cell h-[36.27px] text-center font-bold';

    if (rowIndex === 0) {
      cellClasses += ' first-row';
    }

    if (rowIndex === 14) {
      cellClasses += ' last-row';
    }
    if (dayIndex % 7 === 0) {
      cellClasses += ' last-column';
    }
    if (
      dayIndex === 6 ||
      dayIndex === 13 ||
      dayIndex === 20 ||
      dayIndex === 27
    ) {
      cellClasses += ' last-column';
    }

    return cellClasses;
  };

  return (
    <>
      <div className="flex flex-row justify-between py-4">
        <div>
          <p className="font-mont font-extrabold text-[30px] leading-[39px] text-[#0F172A]">
            {teacher?.fullName}
          </p>
          <button
            className="border-none font-mont font-semibold text-[#94A3B8] text-[14px] leading-[20px]"
            onClick={() => {
              setActivePage('Teachers');
            }}
          >
            ‹ Volver al listado de profesores
          </button>
        </div>
        <GreenButton width="191px" height="44px">
          Reservar una Clase
        </GreenButton>
      </div>
      <div className="flex flex-row">
        <TeacherIntroCard teacher={teacher} />
        <div className="w-[865px] h-[700px] bg-white rounded-2xl shadow-lg py-6 pr-6 overflow-y-scroll no-scrollbar">
          {/* Cabeçalho */}
          <div className="flex flex-col px-6 py-2 gap-2">
            <h3 className="font-nunito font-extrabold text-[18px] leading-[25px] text-[#374151]">
              Sobre mí
            </h3>
            <p className="font-nunito font-medium text-[12px] leading-[17px] text-[#374151]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt ...
            </p>
          </div>
          <h3 className="px-6 py-2 mb-2 font-nunito font-extrabold text-[18px] leading-[25px] text-[#374151]">
            Disponibilidad semanal
          </h3>
          <div className="grid grid-cols-8 w-[804px] h-[584px]">
            {/* Linha 1: Cabeçalho da tabela */}
            <div></div> {/* Espaço 1x1 vazio */}
            {weekDays.map((day, index) => (
              <div
                key={index}
                className="border-l border-b border-grey-200 text-center text-grey-800 opacity-50 font-semibold text-sm leading-5 font-mont"
              >
                {daysOfWeek[index].slice(0, 3)} <br /> {day.getDate()}
              </div>
            ))}
            {/* Linhas 2 a 16: Horários e células dos dias */}
            {renderItems()}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherClasses;
