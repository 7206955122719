import React from 'react';
import logo from '../../../assets/logoText.png';
import partyEmoji from '../../../assets/partyEmoji.png';
import GreenButton from '../../UI/GreenButton';
import * as SolarIconSet from 'solar-icon-set';

const Interview: React.FC = () => {
  return (
    <div className="relative flex flex-col items-center max-w-[1416px] w-full mx-auto h-screen">
      {/* Div centralizada */}
      <div className="flex flex-col items-center justify-center w-full h-[346px] my-auto">
        {/* Div 1 */}
        <div className="w-full h-[94px] flex items-center justify-center mb-6">
          <img src={partyEmoji} alt="Emoji" className="w-[94px] h-[94px]" />
        </div>

        {/* Div 2 */}
        <div className="w-full h-[228px] flex flex-col items-center justify-center">
          {/* Div A */}
          <div className="w-full h-[36px] flex flex-col items-center justify-center mb-2">
            <p className="font-mont font-extrabold text-[36px] leading-9 text-[#0F172A] text-center mb-2">
              ¡Estás a un paso de unirte a Pitinglish!
            </p>
            <p className="font-nunito font-bold text-[16px] leading-[19.2px] text-center text-[#334155]">
              Tu perfil ha sido preseleccionado, y necesitamos conocerte mejor.
            </p>
          </div>

          {/* Div B */}
          <div className="w-[558px] h-[76px] flex flex-col items-center justify-center mt-2">
            <p className="font-mont font-bold text-[30px] leading-[38.34px] text-center text-[#334155] mt-6">
              ¿Qué sigue?
            </p>
            <p className="font-nunito font-bold text-[16px] leading-[19.2px] text-center text-[#334155] mt-2">
              Agenda tu entrevista con nuestro equipo para continuar el proceso.
            </p>
          </div>

          {/* Div C */}
          <div className="w-[558px] h-auto flex flex-col items-center justify-center mt-6">
            <GreenButton type="button" width="294px" height="52px">
              <span className="whitespace-nowrap font-mont mr-[6px]">
                Agendar entrevista
              </span>
              <SolarIconSet.RoundAltArrowRight size={20} iconStyle="Bold" />
            </GreenButton>
          </div>
        </div>
      </div>

      {/* Div abaixo */}
      <div className="absolute bottom-10 w-full flex justify-center">
        <img src={logo} alt="Logo" className="w-[150px] h-[42.7px]" />
      </div>
    </div>
  );
};

export default Interview;
