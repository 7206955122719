import React, { useState } from 'react';
import * as SolarIconSet from 'solar-icon-set';
import chatCanguru from '../../../assets/chat-canguru.png';
import closeIcon from '../../../assets/close-white.png';
import chevronLeft from '../../../assets/chevron-left.png';
import sendIcon from '../../../assets/send-icon.png';

const ChatBot: React.FC = () => {
  const [showChat, setShowChat] = useState(false);
  return (
    <>
      <button
        onClick={() => setShowChat(!showChat)}
        className="absolute -bottom-[89vh] -right-[1vh] bg-[#059669] w-[60px] h-[60px] rounded-full flex justify-center items-center"
      >
        {showChat ? (
          <img src={closeIcon} alt="Close Icon" width="30" height="30" />
        ) : (
          <SolarIconSet.ChatRound size={30} color="#ffffff" />
        )}
      </button>
      {showChat && (
        <div className="absolute flex flex-col items-center bg-white w-[423px] rounded-3xl -right-[1vh] -bottom-[80vh] shadow-lg">
          {/* Modal Header */}
          <div className="rounded-t-3xl w-full bg-[#065F46] flex items-center py-6 gap-3">
            <button className="flex justify-center items-center w-[48px] h-[48px]">
              <img src={chevronLeft} alt="Chevron Left" width="7" height="2" />
            </button>
            <img src={chatCanguru} alt="Canguru Team" width="40" height="40" />
            <div className="flex flex-col">
              <p className="font-inter font-bold text-[#ffffff] text-[24px] leading-[30px]">
                Pitinglish Team
              </p>
              <span className="font-inter text-[#A7F3D0] text-[12px] leading-[15px]">
                Online
              </span>
            </div>
          </div>
          {/* Modal Chat */}
          <div className="h-[492px]">
            <p>TESTE CONVERSA DO MODAL</p>
          </div>
          <div className="bg-gray-200 border-t border-gray-200 w-[100%] flex items-center px-4"></div>
          {/* Modal Message Input */}
          <div className="w-[423px] h-[66px] flex justify-start items-center px-4">
            <input
              name="chat-input"
              placeholder="Enviar un mensaje"
              className="w-full bg-transparent outline-none focus:ring-0 border-none text-gray-700 placeholder-gray-500"
            />
            <button>
              <img
                src={sendIcon}
                className="bg-white"
                alt="Send Icon"
                width="30"
                height="30"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBot;
