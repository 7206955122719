import React from 'react';
import logo from '../../../../assets/logoText.png';
import * as SolarIconSet from 'solar-icon-set';

interface SidebarProps {
  setActivePage: (page: string) => void;
  activePage: string;
}

const Sidebar: React.FC<SidebarProps> = ({ setActivePage, activePage }) => {
  return (
    <aside className="w-[236px] bg-white flex flex-col items-center h-screen border-r border-[#E5E7EB]">
      <img
        src={logo}
        alt="Logo"
        className="mb-10 mt-5 w-[150px] h-[42.7px] mr-[15px]"
      />

      {/* Menu */}
      <nav className="flex flex-col flex-grow w-full h-auto">
        <MenuItem
          icon={<SolarIconSet.HomeSmile size={24} color="#059669" />}
          label="Dashboard"
          onClick={() => setActivePage('Dashboard')}
          isActive={activePage === 'Dashboard'}
        />
      </nav>

      {/* Bottom Menu */}
      <nav className="w-full">
        <div className="mt-auto w-full">
          <MenuItem
            icon={
              <SolarIconSet.SettingsMinimalistic size={24} color="#059669" />
            }
            label="Configuración"
            onClick={() => setActivePage('Settings')}
            isActive={activePage === 'Settings'}
            hasTopBorder={true}
          />
        </div>
      </nav>
    </aside>
  );
};

interface MenuItemProps {
  icon: React.ReactNode;
  label: string;
  className?: string;
  onClick: () => void;
  isActive: boolean;
  hasTopBorder?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  label,
  onClick,
  isActive,
  hasTopBorder = false,
}) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 text-[14px] w-[236px] h-[56px]  pl-[24px] py-4 font-nunito font-semibold transition-colors ${
      isActive ? 'text-[#059669] bg-[#F3F4F6]' : 'text-[#64748B]'
    } ${hasTopBorder ? 'border-t border-[#E5E7EB]' : ''}`} // Adiciona borda superior se hasTopBorder for true
  >
    <span>{icon}</span>
    {label}
  </button>
);

export default Sidebar;
