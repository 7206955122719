import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getTeachers = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/client/endpoints/user/profile/users/TEACHER`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao pegar os professores:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao retornar professores: ', error);
    }
    throw error;
  }
};
