import { useState } from 'react';

export default function useToast() {
  const initialState = {
    showToast: false,
    message: '',
    variant: 'success',
    description: '',
  };
  const [toast, setToast] = useState(initialState);

  const handleHideToast = () => {
    setToast(initialState);
  };

  const handleShowToast = (background, text, subtext) => {
    const newToast = {
      showToast: true,
      message: text,
      variant: background,
      description: subtext,
    };

    setToast(newToast);
  };

  return { toast, handleShowToast, handleHideToast };
}
