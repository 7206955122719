import React, { useState } from 'react';
import backIcon from '../../../assets/back.svg';
import forwardIcon from '../../../assets/forward.svg';
import { AvailabilitySlot, TeacherProfile } from '../../../@types/usersTypes';
import closeIcon from '../../../assets/close.svg';
import GreenButton from '../../UI/GreenButton';
import { teacherColors } from '../../../utils/teacherColors';

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

interface TeacherCalendarProps {
  teachers: TeacherProfile[];
  childTeacher: TeacherProfile | null | undefined;
}

const daysOfWeek = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];

const TeacherCalendar: React.FC<TeacherCalendarProps> = ({ teachers }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewMode, setViewMode] = useState('monthly'); // "monthly" or "weekly"
  const [isModalOpen, setIsModalOpen] = useState({
    show: false,
    id: '',
    day: '',
  });
  const [selectedClass, setSelectedClass] = useState('');

  // Funções para avançar e retroceder no mês ou semana
  const handlePrevious = () => {
    if (viewMode === 'monthly') {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
      );
    } else {
      setCurrentDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 7
        )
      );
    }
  };

  const handleNext = () => {
    if (viewMode === 'monthly') {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
      );
    } else {
      setCurrentDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 7
        )
      );
    }
  };

  // Obtendo mês e ano atuais
  const currentMonth = months[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();

  // Função para obter o primeiro dia da semana (domingo)
  const getStartOfWeek = (date: Date) => {
    const dayOfWeek = date.getDay(); // 0 = domingo, 1 = segunda, etc.
    const firstDayOfWeek = new Date(date);
    firstDayOfWeek.setDate(date.getDate() - dayOfWeek);
    return firstDayOfWeek;
  };

  // Função para obter os dias de uma semana
  const getWeekDays = (startOfWeek: Date) => {
    return Array.from({ length: 7 }, (_, i) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      return day;
    });
  };

  const startOfWeek = getStartOfWeek(currentDate);
  const weekDays = getWeekDays(startOfWeek);

  // Função para obter a estrutura do calendário do mês
  const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Função para pegar o dia da semana do primeiro dia do mês
  const getFirstDayOfMonth = (month: number, year: number) => {
    return new Date(year, month, 1).getDay(); // 0 = domingo, 1 = segunda, etc.
  };

  // Calculando dias do mês e dia da semana do primeiro dia
  const daysInMonth = getDaysInMonth(
    currentDate.getMonth(),
    currentDate.getFullYear()
  );
  const firstDayOfMonth = getFirstDayOfMonth(
    currentDate.getMonth(),
    currentDate.getFullYear()
  );

  // Preenchendo os dias do mês no calendário
  const daysArray = Array.from(
    { length: daysInMonth },
    (_, index) => index + 1
  );

  const handleShowModal = (bool: boolean, id: string, day: string) => {
    setIsModalOpen({
      show: bool,
      id,
      day,
    });
  };

  const renderItems = () => {
    if (viewMode === 'monthly') {
      let days: string[] = [];
      Array.from({ length: firstDayOfMonth }).forEach(() => days.push('empty'));

      daysArray.forEach((day) => days.push(day.toString()));

      return days.map((day, index) => (
        <div key={index} className={getMonthlyClasses(index, days.length)}>
          {day === 'empty' ? (
            ''
          ) : (
            <div className="my-1 font-normal font-nunito text-[10px] text-grey-800 leading-[12.5px]">
              {day}
            </div>
          )}

          {day !== 'empty' &&
            teachers?.map((teacher) =>
              teacher?.Teacher?.availabilitySlots?.map((slot, slotIndex) => {
                let weekDay: number = 0;
                if (index < 7) weekDay = index;
                if (index >= 7 && index < 14) weekDay = index - 7;
                if (index >= 14 && index < 21) weekDay = index - 14;
                if (index >= 21 && index < 28) weekDay = index - 21;
                if (index > 28) weekDay = index - 28;

                // Show only the first slot that matches the day
                if (slot.dayOfWeek === weekDay && slotIndex === 0) {
                  return (
                    <>
                      <button
                        key={`${teacher.id}-${teacher.fullName}-${day}-${slot.id}`}
                        onClick={() => {
                          if (isModalOpen.show) {
                            handleShowModal(!isModalOpen.show, '', '');
                          } else {
                            handleShowModal(!isModalOpen.show, slot.id, day);
                          }
                        }}
                        className={`${
                          isModalOpen.id === slot.id && isModalOpen.day === day
                            ? 'bg-opacity-100'
                            : 'bg-opacity-10'
                        } w-[108px] h-[29px] border-2 border-solid ${teacherColors(teacher?.Teacher?.backgroundColor)} border-opacity-20 items-center rounded-md gap-1 flex px-[2px] hover:bg-opacity-100 group`}
                      >
                        <img
                          src={teacher.profilePicture ?? undefined}
                          className="rounded-3xl w-6 h-6 mt-[1px]"
                          alt=""
                        />
                        <div className="nunito text-left flex flex-col justify-center">
                          <span
                            className={`${
                              isModalOpen.id === slot.id &&
                              isModalOpen.day === day
                                ? 'text-white'
                                : `text-[#${teacher.Teacher.backgroundColor}]`
                            } font-bold text-[10px] leading-[10px] group-hover:text-white`}
                          >
                            {teacher.fullName.slice(
                              0,
                              teacher.fullName.indexOf(' ')
                            )}
                          </span>
                          <p
                            className={`${
                              isModalOpen.id === slot.id &&
                              isModalOpen.day === day
                                ? 'text-white'
                                : `text-[#${teacher.Teacher.backgroundColor}]`
                            } font-normal text-[8px] leading-[8px] group-hover:text-white`}
                          >
                            {getClassTimesMonth(
                              teacher.Teacher.availabilitySlots
                            )}
                          </p>
                        </div>
                      </button>
                      {isModalOpen.show &&
                        isModalOpen.id === slot.id &&
                        isModalOpen.day === day && (
                          <div
                            className={`${slot.dayOfWeek < 4 ? 'left-[109px] ' : 'right-[387px]'} ${index > 21 ? '-top-[341px]' : '-top-[30px]'} relative  w-[386px] bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]`}
                          >
                            {/* Modal Header */}
                            <div className="flex justify-between items-center mb-4">
                              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                                Clases con {teacher.fullName}
                              </h4>
                              <button
                                onClick={() => {
                                  setIsModalOpen({
                                    show: false,
                                    id: '',
                                    day: '',
                                  });
                                  setSelectedClass('');
                                }}
                              >
                                <img
                                  src={closeIcon}
                                  alt="Close Icon"
                                  width="24"
                                  height="24"
                                />
                              </button>
                            </div>
                            <p className="font-mont font-semibold text-[14px] leading-5 text-[#64748B] text-left mb-4">{`${daysOfWeek[slot.dayOfWeek]} ${day} de ${currentMonth}`}</p>
                            <div className="flex flex-col justify-center items-start gap-1">
                              <select
                                id={`grade-${index}`}
                                name="grade"
                                value={selectedClass}
                                onChange={(e) =>
                                  setSelectedClass(e.target.value)
                                }
                                required
                                className="mb-3 w-full p-3 rounded-lg border-[1px] border-[#CED3CE] border-solid border-opacity-20 items-center text-[#9CA3AF]"
                              >
                                <option value="">Selecione tu clase</option>
                                {teacher?.Teacher?.availabilitySlots
                                  ?.sort((a, b) => {
                                    if (a.startTime < b.startTime) return -1;
                                    if (a.startTime > b.startTime) return 1;
                                    return 0;
                                  })
                                  .map((slot) => {
                                    const startTime = slot.startTime;
                                    const dateStart = new Date(
                                      `1970-01-01T${startTime}`
                                    );
                                    const localStartTime =
                                      dateStart.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      });

                                    const endTime = slot.endTime;
                                    const dateEnd = new Date(
                                      `1970-01-01T${endTime}`
                                    );
                                    const localEndTime =
                                      dateEnd.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      });
                                    return (
                                      <option
                                        key={`option-${slot.id}-${localStartTime}`}
                                        value={`${localStartTime} - ${localEndTime}`}
                                        className="text-[#334155]"
                                      >{`${localStartTime} - ${localEndTime}`}</option>
                                    );
                                  })}
                              </select>
                            </div>
                            {selectedClass !== '' && (
                              <GreenButton width="338px" height="44px">
                                Reservar clase
                              </GreenButton>
                            )}
                          </div>
                        )}
                    </>
                  );
                } else {
                  return null;
                }
              })
            )}
        </div>
      ));
    } else {
      return Array.from({ length: 15 }).map((_, rowIndex) => (
        <>
          {/* Coluna de horários */}
          <div
            key={`time-${rowIndex}`}
            className="h-[36.27px] flex items-center justify-end pr-2 text-sm text-gray-500 text-opacity-100 opacity-50 border-t-[1px] border-solid border-grey-200 relative bottom-[0.05rem]"
          >
            <p className="w-[92px] relative bottom-[1.15rem] z-10 bg-white text-right pr-[2px]">
              {7 + rowIndex}:00
            </p>
          </div>

          {/* Células de cada dia da semana */}
          {weekDays.map((weekDay, dayIndex) => (
            <div
              key={`day-${dayIndex}-time-${rowIndex}`}
              className={getWeeklyClasses(dayIndex, rowIndex)}
            >
              {teachers?.map((teacher) =>
                teacher?.Teacher?.availabilitySlots?.map((slot, index) => {
                  const startTime = slot.startTime;
                  const dateStart = new Date(`1970-01-01T${startTime}`);
                  const localStartTime = dateStart.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  });

                  const endTime = slot.endTime;
                  const dateEnd = new Date(`1970-01-01T${endTime}`);
                  const localEndTime = dateEnd.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  });
                  let zIndex = 0;
                  if (
                    slot.dayOfWeek === weekDay.getDay() &&
                    +localStartTime.slice(0, 2) - 7 === rowIndex
                  ) {
                    if (+localEndTime.slice(0, 2) - 7 === rowIndex) {
                      zIndex = 25 - rowIndex - index;
                      return (
                        <>
                          {isModalOpen.show && isModalOpen.id === slot.id && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                              <div
                                key={`modal-${slot.id}-${localStartTime} - ${localEndTime}`}
                                className="absolute top-[500px] left-[800px] w-[386px] bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]"
                              >
                                {/* Modal Header */}
                                <div className="flex justify-between items-center mb-4">
                                  <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                                    Clases con {teacher.fullName}
                                  </h4>
                                  <button
                                    onClick={() => {
                                      setIsModalOpen({
                                        show: false,
                                        id: '',
                                        day: '',
                                      });
                                      setSelectedClass('');
                                    }}
                                  >
                                    <img
                                      src={closeIcon}
                                      alt="Close Icon"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                </div>
                                <p className="font-mont font-semibold text-[14px] leading-5 text-[#64748B] text-left">{`${daysOfWeek[slot.dayOfWeek]} ${weekDay.getDate()} de ${currentMonth}`}</p>
                                <p className="font-mont font-semibold text-[14px] leading-5 text-[#64748B] text-left mb-4">{`${localStartTime} - ${localEndTime}`}</p>
                                {selectedClass !== '' && (
                                  <GreenButton width="338px" height="44px">
                                    Reservar clase
                                  </GreenButton>
                                )}
                              </div>
                            </div>
                          )}
                          <button
                            key={`${teacher.id}-${teacher.fullName}-${dayIndex}-${weekDay}-${rowIndex}-${startTime}`}
                            style={{ zIndex }}
                            onClick={() => {
                              if (isModalOpen.show) {
                                handleShowModal(!isModalOpen.show, '', '');
                                setSelectedClass('');
                              } else {
                                handleShowModal(
                                  !isModalOpen.show,
                                  slot.id,
                                  '0'
                                );
                                setSelectedClass(
                                  `${localStartTime} - ${localEndTime}`
                                );
                              }
                            }}
                            className={`${isModalOpen.id === slot.id ? 'bg-opacity-100' : 'bg-opacity-10'} ${teacherColors(teacher.Teacher.backgroundColor)} relative cursor-pointer w-[100px] h-[18.12px] border-2 rounded-md gap-1 flex px-[2px] items-center justify-center border-opacity-20 hover:bg-opacity-100 group`}
                          >
                            <img
                              src={teacher.profilePicture ?? undefined}
                              className="rounded-3xl w-3 h-3"
                              alt=""
                            />
                            <div className="nunito text-left flex justify-center gap-1">
                              <span
                                className={`${isModalOpen.id === slot.id ? 'text-white' : null} font-bold text-[10px] group-hover:text-white`}
                              >
                                {teacher.fullName.slice(
                                  0,
                                  teacher.fullName.indexOf(' ')
                                )}
                              </span>
                              <p
                                className={`${isModalOpen.id === slot.id ? 'text-white' : null} font-normal text-[8px] mt-[2px] group-hover:text-white`}
                              >
                                {localStartTime}
                              </p>
                            </div>
                          </button>
                        </>
                      );
                    }
                    if (+localEndTime.slice(0, 2) - 8 === rowIndex) {
                      zIndex = 24 - rowIndex - index;
                      return (
                        <>
                          <button
                            key={`${teacher.id}-${teacher.fullName}-${dayIndex}-${weekDay}-${rowIndex}-${endTime}`}
                            style={{ zIndex }}
                            onClick={() => {
                              if (isModalOpen.show) {
                                handleShowModal(!isModalOpen.show, '', '');
                                setSelectedClass('');
                              } else {
                                handleShowModal(
                                  !isModalOpen.show,
                                  slot.id,
                                  '0'
                                );
                                setSelectedClass(
                                  `${localStartTime} - ${localEndTime}`
                                );
                              }
                            }}
                            className={`${isModalOpen.id === slot.id ? 'bg-opacity-100' : 'bg-opacity-10'} ${teacherColors(teacher.Teacher.backgroundColor)} relative cursor-pointer w-[100px] h-[18.12px] border-2 border-solid rounded-md gap-1 flex px-[2px] items-center justify-center border-[#${teacher.Teacher.backgroundColor}] border-opacity-20 bg-[#${teacher.Teacher.backgroundColor}] hover:bg-opacity-100 group`}
                          >
                            <img
                              src={teacher.profilePicture ?? undefined}
                              className="rounded-3xl w-3 h-3"
                              alt=""
                            />
                            <div className="nunito text-left flex justify-center gap-1">
                              <span
                                className={`${isModalOpen.id === slot.id ? 'text-white' : null} font-bold text-[10px] group-hover:text-white`}
                              >
                                {teacher.fullName.slice(
                                  0,
                                  teacher.fullName.indexOf(' ')
                                )}
                              </span>
                              <p
                                className={`${isModalOpen.id === slot.id ? 'text-white' : null} font-normal text-[8px] mt-[2px] group-hover:text-white`}
                              >
                                {localStartTime}
                              </p>
                            </div>
                          </button>
                          {isModalOpen.show && isModalOpen.id === slot.id && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                              <div
                                key={`modal-${slot.id}-${localStartTime} - ${localEndTime}`}
                                className="absolute top-[500px] left-[800px] w-[386px] bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]"
                              >
                                {/* Modal Header */}
                                <div className="flex justify-between items-center mb-4">
                                  <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                                    Clases con {teacher.fullName}
                                  </h4>
                                  <button
                                    onClick={() => {
                                      setIsModalOpen({
                                        show: false,
                                        id: '',
                                        day: '',
                                      });
                                      setSelectedClass('');
                                    }}
                                  >
                                    <img
                                      src={closeIcon}
                                      alt="Close Icon"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                </div>
                                <p className="font-mont font-semibold text-[14px] leading-5 text-[#64748B] text-left">{`${daysOfWeek[slot.dayOfWeek]} ${weekDay.getDate()} de ${currentMonth}`}</p>
                                <p className="font-mont font-semibold text-[14px] leading-5 text-[#64748B] text-left mb-4">{`${localStartTime} - ${localEndTime}`}</p>
                                {selectedClass !== '' && (
                                  <GreenButton width="338px" height="44px">
                                    Reservar clase
                                  </GreenButton>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    }
                  }
                  return null;
                })
              )}
            </div>
          ))}
        </>
      ));
    }
  };

  const getClassTimesMonth = (slots: AvailabilitySlot[] | undefined) => {
    let start = '24';
    let end = '0';

    slots?.forEach((slot) => {
      const startTime = slot.startTime;
      const dateStart = new Date(`1970-01-01T${startTime}`);
      const localStartTime = dateStart.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      const endTime = slot.endTime;
      const dateEnd = new Date(`1970-01-01T${endTime}`);
      const localEndTime = dateEnd.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      if (+localStartTime.slice(0, 2) < +start.slice(0, 2))
        start = localStartTime;
      if (+localEndTime.slice(0, 2) > +end.slice(0, 2)) end = localEndTime;
    });
    return `${start.slice(0, 5)} - ${end.slice(0, 5)}`;
  };

  const getMonthlyClasses = (index: number, length: number) => {
    let cellClasses;
    cellClasses =
      'calendar-cell h-[113px] text-center font-bold px-1 overflow-visible no-scrollbar';

    if (index < 7) {
      cellClasses += ' first-row';
    }
    if (length - index <= 7) {
      cellClasses += ' last-row';
    }
    if (index % 7 === 0) {
      cellClasses += ' first-column';
    }
    if (index === 6 || index === 13 || index === 20 || index === 27) {
      cellClasses += ' last-column';
    }

    return cellClasses;
  };

  const getWeeklyClasses = (dayIndex: number, rowIndex: number) => {
    let cellClasses;
    cellClasses = 'calendar-cell h-[36.27px] text-center font-bold';

    if (rowIndex === 0) {
      cellClasses += ' first-row';
    }

    if (rowIndex === 14) {
      cellClasses += ' last-row';
    }
    if (dayIndex % 7 === 0) {
      cellClasses += ' last-column';
    }
    if (
      dayIndex === 6 ||
      dayIndex === 13 ||
      dayIndex === 20 ||
      dayIndex === 27
    ) {
      cellClasses += ' last-column';
    }

    return cellClasses;
  };

  console.log('rocha', teachers);

  return (
    <div className="w-[865px] h-[709px] bg-white rounded-2xl shadow-lg py-6 pr-6">
      {/* Cabeçalho */}
      <div className="flex justify-between items-center mb-6 pl-6">
        <div className="flex items-center gap-4">
          <button onClick={handlePrevious}>
            <img src={backIcon} alt="Back Icon" />
          </button>
          <h2 className="text-[24px] font-bold">
            {viewMode === 'monthly'
              ? `${currentMonth} ${currentYear}`
              : `${weekDays[0].getDate()} ${months[weekDays[0].getMonth()]} - ${weekDays[6].getDate()} ${months[weekDays[6].getMonth()]} ${currentYear}`}
          </h2>
          <button onClick={handleNext}>
            <img src={forwardIcon} alt="Forward Icon" />
          </button>
        </div>
        <select
          className="border border-[#E5E7EB] rounded-md px-2 py-1"
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
        >
          <option value="monthly">Mensual</option>
          <option value="weekly">Semanal</option>
        </select>
      </div>

      {/* Calendário mensal */}
      {viewMode === 'monthly' && (
        <div className="grid grid-cols-7 w-[820px] h-[584px] ml-5">
          {/* Dias da semana */}
          {daysOfWeek.map((day, index) => (
            <div
              key={index}
              className={`${!day.startsWith('Dom') ? 'border-l border-grey-200' : ''} border-b border-grey-200 text-center text-grey-800 opacity-50 font-semibold text-sm leading-5 font-mont`}
            >
              {day.slice(0, 3)}
            </div>
          ))}
          {renderItems()}
        </div>
      )}
      {/* Calendário semanal */}
      {viewMode === 'weekly' && (
        <div className="grid grid-cols-8 w-[804px] h-[584px]">
          {/* Linha 1: Cabeçalho da tabela */}
          <div></div> {/* Espaço 1x1 vazio */}
          {weekDays.map((day, index) => (
            <div
              key={index}
              className="border-l border-b border-grey-200 text-center text-grey-800 opacity-50 font-semibold text-sm leading-5 font-mont"
            >
              {daysOfWeek[index].slice(0, 3)} <br /> {day.getDate()}
            </div>
          ))}
          {/* Linhas 2 a 16: Horários e células dos dias */}
          {renderItems()}
        </div>
      )}
    </div>
  );
};

export default TeacherCalendar;
