import IBAN from 'iban';

export const IBAN_REGEX = /^(ES|es)\d{22}/;

export const validateIban = (ibanString: string | undefined): boolean => {
  if (!ibanString) {
    return false;
  }

  if (typeof ibanString !== 'string') {
    return false;
  }

  if (IBAN_REGEX.test(ibanString) && IBAN.isValid(ibanString)) {
    return true;
  }

  return false;
};
