import React, { useState } from 'react';
import dashboardBG from '../../../assets/dashboardBG.jpeg';
import thumbsUp from '../../../assets/thumbs-up.png';
import border from '../../../assets/Star2.svg';
import pointingUp from '../../../assets/pointingUp.png';
import closeIcon from '../../../assets/close.svg';
import * as SolarIconSet from 'solar-icon-set';
import explanationVideo from '../../../assets/explanationVideo.png';
import headphone from '../../../assets/Headphone.png';
import fabricCubes from '../../../assets/FabricCubes.png';
import gift1 from '../../../assets/gift1.png';
import gift2 from '../../../assets/gift2.png';
import { StudentProfile, User } from '../../../@types/usersTypes';
import YouTube, { YouTubeProps } from 'react-youtube';

interface DashboardProps {
  user: User | null;
  selectedChild: StudentProfile | null;
  setActivePage: (page: string) => void;
}

const Dashboard: React.FC<DashboardProps> = ({
  selectedChild,
  setActivePage,
}) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const fallbackImage = 'url_da_imagem_padrao_aqui';

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '630',
    width: '985',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  // Função para pegar a próxima aula do filho selecionado
  const getNextLessonForChild = (child: StudentProfile | null) => {
    if (!child || !child.scheduledLessons) return null;

    if (child.scheduledLessons.length) {
      // Ordenar as aulas por data para pegar a próxima
      const sortedLessons = [...child.scheduledLessons].sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      return sortedLessons[0]; // Retorna a próxima aula
    }
    return null; // Caso não tenha aulas
  };

  // Aula filtrada para o filho selecionado
  const nextLesson = getNextLessonForChild(selectedChild);

  const handleGoToCalendar = () => {
    setActivePage('Calendar'); // Muda a aba para "Calendar"
  };

  return (
    <div className="flex max-w-[1160px] h-full overflow-hidden">
      {showVideoModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col gap-4 absolute bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                <p className="font-nunito font-bold text-[18px] leading-[18px] text-[#0F172A]">
                  Descubra tu primer clase!
                </p>
              </h4>
              <button onClick={() => setShowVideoModal(false)}>
                <img src={closeIcon} alt="Close Icon" width="24" height="24" />
              </button>
            </div>
            <YouTube
              videoId="ez8m4PXksQs"
              opts={opts}
              onReady={onPlayerReady}
            />
          </div>
        </div>
      )}
      {/* Main Content */}
      <div className="flex-grow bg-[#F3F4F6] overflow-y-auto overflow-x-hidden w-[1160px] mx-auto mb-10">
        <div>
          {/* Class Info Section */}
          <section
            className="relative flex gap-10 bg-cover bg-center p-5 mt-7 rounded-2xl max-w-[1160px] max-h-[355.54px]"
            style={{ backgroundImage: `url(${dashboardBG})` }}
          >
            {/* Teacher Card */}
            {nextLesson ? (
              <>
                <div className="relative w-[228px] h-[311px] bg-[#34D399] rounded-2xl p-5 flex flex-col items-center shadow-lg">
                  <button className="absolute top-4 right-4 rotate-90 bg-[#A7F3D0] rounded-full h-[24px] w-[24px]">
                    <SolarIconSet.MenuDots
                      size={16}
                      iconStyle="Bold"
                      className="mt-1"
                    />
                  </button>
                  <div className="relative w-[191px] h-[191px] mt-1">
                    <div
                      style={{
                        clipPath:
                          'path("M105.431 2.83291C109.869 -0.137089 115.902 1.38806 118.395 6.11031L125.363 19.3074C127.07 22.5386 130.269 24.7117 133.901 25.1069L148.737 26.7209C154.046 27.2984 157.687 32.3446 156.562 37.5648L153.417 52.1534C152.647 55.7252 153.701 59.4463 156.229 62.0839L166.557 72.8569C170.253 76.7117 169.798 82.9178 165.581 86.1933L153.794 95.3472C150.908 97.5884 149.324 101.116 149.565 104.762L150.552 119.653C150.905 124.982 146.568 129.444 141.232 129.242L126.319 128.678C122.667 128.54 119.186 130.224 117.027 133.172L108.211 145.214C105.057 149.522 98.8661 150.153 94.908 146.568L83.8464 136.55C81.1382 134.097 77.3886 133.149 73.8401 134.02L59.3466 137.578C54.1605 138.851 49.013 135.354 48.2851 130.064L46.2509 115.28C45.7529 111.66 43.4899 108.524 40.2116 106.91L26.8221 100.319C22.0311 97.9607 20.3354 91.9734 23.1783 87.4529L31.1233 74.8199C33.0686 71.7268 33.351 67.8696 31.8769 64.5262L25.8566 50.8707C23.7023 45.9844 26.2519 40.308 31.3354 38.6725L45.542 34.1019C49.0204 32.9829 51.716 30.2097 52.7359 26.701L56.9017 12.3704C58.3923 7.24261 63.9941 4.53302 68.9395 6.54779L82.7604 12.1784C86.1442 13.557 89.9918 13.1653 93.0284 11.1331L105.431 2.83291Z")',
                      }}
                    >
                      <img
                        src={
                          selectedChild?.scheduledLessons[0]?.teacher?.user
                            ?.profilePicture || fallbackImage
                        }
                        alt="Foto do professor"
                        className="relative -top-5 transform translate-y-[-10%] mx-auto"
                      />
                    </div>
                    <img
                      src={border}
                      alt="Orange Border"
                      className="absolute inset-0 w-full h-full pointer-events-none"
                      style={{ transform: 'rotate(15deg)' }}
                    />
                  </div>
                  <div
                    className="mt-[-60px] mb-[17.54px] bg-[#FFFFFFCC] rounded-2xl px-4 py-1 z-10 w-40 text-center"
                    style={{
                      boxShadow:
                        '0px 10px 15px -3px #0000001A, 0px 4px 6px -4px #0000001A',
                      backdropFilter: 'blur(10px)',
                    }}
                  >
                    <h3 className="text-[#0F172A] font-nunito text-[18px] font-bold text-lg">
                      {selectedChild?.teacher?.fullName.split(' ')[0]}
                    </h3>
                  </div>
                  <p className="text-[#0F172A] font-bold font-nunito text-[16px]">
                    {(() => {
                      const lessonDate = new Date(nextLesson.startTime);
                      const now = new Date();

                      if (
                        lessonDate.getDate() === now.getDate() &&
                        lessonDate.getMonth() === now.getMonth() &&
                        lessonDate.getFullYear() === now.getFullYear()
                      ) {
                        return `Hoy a las ${lessonDate.toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}`;
                      }

                      const tomorrow = new Date();
                      tomorrow.setDate(now.getDate() + 1);
                      if (
                        lessonDate.getDate() === tomorrow.getDate() &&
                        lessonDate.getMonth() === tomorrow.getMonth() &&
                        lessonDate.getFullYear() === tomorrow.getFullYear()
                      ) {
                        return `Mañana a las ${lessonDate.toLocaleTimeString(
                          [],
                          {
                            hour: '2-digit',
                            minute: '2-digit',
                          }
                        )}`;
                      }

                      return `${lessonDate.getDate()}/${lessonDate.getMonth() + 1} a las ${lessonDate.toLocaleTimeString(
                        [],
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        }
                      )}`;
                    })()}
                  </p>

                  <p className="text-[#065F46] font-nunito font-semibold">
                    {(() => {
                      const lessonDate = new Date(nextLesson.startTime);
                      const timeZoneName =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;
                      const offset = lessonDate.getTimezoneOffset() / 60;

                      const formattedOffset =
                        offset >= 0
                          ? `UTC+${Math.abs(offset)}`
                          : `UTC${offset}`;

                      return `${timeZoneName}, ${formattedOffset}`;
                    })()}
                  </p>

                  <button className="mt-1 text-white px-4 py-2 rounded-2xl text-[14px] font-bold font-nunito">
                    Añadir a tu calendario
                  </button>
                </div>

                {/* Class Info */}
                <div className="w-[424px] h-[307px] flex flex-col justify-center space-y-4">
                  <div className="flex items-center justify-center bg-[#A7F3D0] h-[48px] w-[64px] rounded-full">
                    <img
                      src={`https://storage.googleapis.com/e-pitinglish/${selectedChild?.avatarUrl}`}
                      alt={`Avatar de ${selectedChild?.childName}`}
                      className="w-[56px] h-[56px] mb-2"
                    />
                  </div>
                  <h2 className="text-[40px] font-black font-nunito text-[#0F172A] leading-10">
                    {selectedChild?.childName} tu clase <br />
                    <span className="block">esta reservada!</span>
                  </h2>
                  <p className="text-[#0F172A] text-[16px] font-nunito leading-[19.2px]">
                    Entra a clase 15 minutos antes del horario para <br />{' '}
                    configurar la cámara y el micrófono
                  </p>
                  {(() => {
                    const lessonStartTime = new Date(nextLesson.startTime);
                    const currentTime = new Date();
                    const timeDifference =
                      lessonStartTime.getTime() - currentTime.getTime();
                    const fifteenMinutesInMs = 15 * 60 * 1000; // 15 minutos em milissegundos

                    // Verifica se estamos no intervalo de 15 minutos antes da aula
                    const isWithin15Minutes =
                      timeDifference <= fifteenMinutesInMs &&
                      timeDifference > 0;

                    return (
                      <button
                        className={`mt-4 bg-white text-[#059669] border border-[#059669] font-bold px-6 py-3 rounded-2xl w-fit shadow-md ${
                          isWithin15Minutes
                            ? 'bg-[#FFFFFF] text-[#065F46] hover:bg-[#059669] hover:text-white cursor-pointer border-2 border-[#069668]'
                            : 'bg-[#FFFFFF] text-[#065F46]'
                        } shadow-[0px_1px_2px_0px_#0000000D]`}
                        disabled={!isWithin15Minutes}
                      >
                        {isWithin15Minutes
                          ? 'Unirme Ahora'
                          : `Entrar a clase a las ${new Date(
                              new Date(nextLesson.startTime).getTime() -
                                15 * 60000 // Subtrai 15 minutos
                            ).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}`}
                      </button>
                    );
                  })()}
                </div>
                <img
                  src={thumbsUp}
                  alt="Right Image"
                  className="absolute right-[-20px] bottom-0 w-[459.5px] h-[393.72px]"
                />
              </>
            ) : (
              <>
                {/* Class Info */}
                <div className="w-[424px] h-[307px] flex flex-col justify-center space-y-4">
                  <div className="flex items-center justify-center bg-[#A7F3D0] h-[48px] w-[64px] rounded-full">
                    <img
                      src={`https://storage.googleapis.com/e-pitinglish/${selectedChild?.avatarUrl}`}
                      alt={`Avatar de ${selectedChild?.childName}`}
                      className="w-[56px] h-[56px] mb-2"
                    />
                  </div>
                  <h2 className="text-[40px] font-black font-nunito text-[#0F172A] leading-10">
                    {selectedChild?.childName} reserve ahora tu clase! <br />
                  </h2>
                  <button
                    className="mt-4 bg-white text-[#059669] border border-[#059669] font-bold px-6 py-3 rounded-2xl w-fit shadow-md"
                    onClick={handleGoToCalendar}
                  >
                    Buscar profesor
                  </button>
                </div>
                <img
                  src={thumbsUp}
                  alt="Thumbs Up"
                  className="absolute right-[-20px] bottom-0 w-[459.5px] h-[393.72px]"
                />
              </>
            )}
          </section>
          <div className="flex flex-col gap-4 mt-10 justify-center max-w-[1160px] mx-auto">
            <h2 className="font-bold leading-[32.74px] text-[24px] text-[#64748B] font-nunito ml-4">
              Prepárate para la primera clase
            </h2>
            {/* Primeira section com 3 cards */}
            <section className="flex gap-1 justify-center w-full">
              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-[312px] mb-3">
                  {/* Ícone de número 1 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
          0px 4px 20px 0px #FFFAFA40 inset, 
          0px -4px 20px 0px #00000040 inset, 
          0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    1
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] h-[32px] leading-[19.2px]">
                    Mira el video para ver cómo será tu <br /> primera clase
                  </h3>
                </div>

                {/* Imagem do vídeo */}
                <button
                  onClick={() => setShowVideoModal(true)}
                  className="w-[286.84] h-[166px]"
                >
                  <img
                    src={explanationVideo}
                    alt="Video"
                    className="w-full h-[166px] rounded-2xl mb-8"
                  />
                </button>
              </div>

              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6 relative">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full">
                  {/* Ícone de número 2 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    2
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Prueba tu cámara <br /> y micrófono
                  </h3>
                </div>

                {/* Contêiner para o texto e a imagem */}
                <div className="relative">
                  {/* Descrição */}
                  <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal pr-[120px]">
                    El aula solicitará acceso a su cámara y micrófono para
                    proporcionar comunicación entre el maestro y el niño durante
                    la lección.
                  </p>

                  {/* Imagem ao lado direito do texto com posição absoluta */}
                  <img
                    src={headphone}
                    alt="Cámara"
                    className="w-[166.32px] h-[166.32px] rounded-2xl absolute top-[-40px] right-[-20px]"
                  />
                </div>

                {/* Botão */}
                <button className="mt-4 py-2 px-4 text-[#059669] border-2 border-[#059669] rounded-2xl font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors">
                  Haz el chequeo
                </button>
              </div>

              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6 relative">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full">
                  {/* Ícone de número 3 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    3
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Determina el nivel de <br /> inglés de{' '}
                    {selectedChild?.childName}
                  </h3>
                </div>

                {/* Contêiner para o texto e a imagem */}
                <div className="relative">
                  {/* Descrição */}
                  <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal pr-[120px]">
                    haz un breve test para determinar el nivel de inglés de tu
                    hijo/a y así poder seleccionar el mejor programa de clases.
                  </p>

                  {/* Imagem ao lado direito do texto com posição absoluta */}
                  <img
                    src={fabricCubes}
                    alt="Cámara"
                    className="w-[166.32px] h-[166.32px] rounded-2xl absolute top-[-40px] right-[-20px]"
                  />
                </div>

                {/* Botão */}
                <button className="mt-9 py-2 px-4 text-[#059669] border-2 border-[#059669] rounded-2xl font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors">
                  Haz el test
                </button>
              </div>
            </section>

            {/* Segunda section (Aviso para padres) */}
            <section className=" bg-[#E6E7EB] rounded-2xl flex items-center shadow-md mx-auto pl-2 w-full h-[88px]">
              <div className="flex items-center">
                <img src={pointingUp} alt="Hand Icon" className="w-16 h-16" />
                <div>
                  <h4 className="font-bold text-[#334155] font-nunito text-[16px] leading-[19.2px]">
                    Aviso para padres!
                  </h4>
                  <p className="text-[#64748B] font-nunito font-normal text-[14px]">
                    Para darle confianza a tu hijo/a y ayudarlo/a con los
                    ajustes técnicos de la llamada, necesitamos que estés
                    presente en esta primera llamada.
                  </p>
                </div>
              </div>
            </section>
          </div>
          {/* Two Column Section */}
          <section className="flex gap-6 max-w-[1160px] h-[283px] justify-center items-center mx-auto mt-10">
            {/* Seção de Perguntas Frequentes */}
            <div className="w-[760px] h-[283px]">
              <h2 className="font-bold leading-[32.74px] text-[24px] text-[#64748B] font-nunito mb-4">
                Preguntas frecuentes de las padres
              </h2>
              <div className="flex flex-col gap-2">
                {/* Pergunta 1 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px] ">
                    Pregunta 1
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 2 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    Pregunta 2
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 3 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    Pregunta 3
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 4 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer mb-[40px] border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    Pregunta 4
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>
              </div>
            </div>

            {/* Seção de Convite e Descontos */}
            <div className="w-[376px] h-[283px] bg-[#E5E7EB] rounded-2xl shadow-md p-6 flex flex-col relative mt-10">
              {/* Título */}
              <h2 className="font-nunito text-[16px] leading-[19.2px] font-bold text-[#334155] mb-4 mt-2">
                Invita a un amigo y consigue descuentos
              </h2>

              {/* Texto explicativo */}
              <p className="text-[#64748B] text-[14px] leading-[20px] pr-[140px]">
                Después de que tu amigo se registre y pague un paquete de
                lecciones, ambos recibirán €50 en una cuenta de bonificación que
                podrán usar para pagar lecciones.
              </p>

              {/* Imagem 1 - posicionada à direita */}
              <img
                src={gift1}
                alt="Presente"
                className="w-[146.01px] h-[146.01] absolute top-[40px] right-[30px]"
              />

              {/* Imagem 2 - posicionada abaixo da primeira à direita */}
              <img
                src={gift2}
                alt="Presente 2"
                className="w-[75.21px] h-[82.34px] absolute top-[135px] right-[30px]"
              />

              {/* Botão de convite centralizado */}
              <button className="mt-auto py-2 px-4 text-[#059669] font-bold text-[14px] leading-[20px] hover:bg-[#16C984] hover:text-white transition-colors self-center">
                Invita a un amigo
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
