import React, { useEffect, useRef, useState } from 'react';
import { User } from '../../../../@types/usersTypes';
import * as SolarIconSet from 'solar-icon-set';
import YouTube, { YouTubeProps } from 'react-youtube';
import GreenButton from '../../../UI/GreenButton';
import { updateVideos } from '../../../../service/teacher/updateVideos.service';
import type { YouTubePlayer } from 'react-youtube';
import redBlock from '../../../../assets/red-block.png';
import greenEye from '../../../../assets/green-eye.png';

declare const clearInterval: (handle?: number) => void;

interface VideosProps {
  user: User | null;
  setActivePage: (page: string) => void;
}

const Videos: React.FC<VideosProps> = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const playerRef = useRef<YouTubePlayer | null>(null);
  const userId = localStorage.getItem('userId');

  const opts: YouTubeProps['opts'] = {
    height: '724',
    width: '1100',
    playerVars: {
      autoplay: 0,
      controls: 1,
      disablekb: 1,
    },
  };

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    playerRef.current = event.target;
  };

  const onPlayerStateChange: YouTubeProps['onStateChange'] = (event) => {
    const player = playerRef.current;

    if (!player) return;

    switch (event.data) {
      case 0:
        if (!isComplete) {
          setIsComplete(true);

          // Obtém o videoId dinamicamente
          const videoId = player.getVideoData().video_id;

          // Passa o videoId para handleVideoCompletion
          handleVideoCompletion(videoId);
        }
        break;

      default:
        break;
    }
  };

  const handleVideoCompletion = async (videoId: string) => {
    if (!userId) {
      console.error('Usuário não autenticado.');
      return;
    }

    setIsUpdating(true);
    try {
      const response = await updateVideos(videoId, userId);
      console.log('Atualização bem-sucedida:', response);
    } catch (error) {
      console.error('Erro ao atualizar o banco de dados:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleToggle = (event: React.SyntheticEvent<HTMLDetailsElement>) => {
    setIsOpen(event.currentTarget.open);
  };

  useEffect(() => {
    return () => {
      if (playerRef.current?.__interval) {
        clearInterval(playerRef.current.__interval);
      }
    };
  }, []);

  return (
    <div className="flex flex-col max-w-[1160px] h-full overflow-auto mt-4 gap-6">
      <div className="bg-[#F3F4F6]">
        <p className="font-mont text-[30px] text-[#0F172A] font-extrabold leading-[38.34px]">
          ¡Prepárate para enseñar en Pitinglish!
        </p>
        <p className="font-mont text-[14px] text-[#94A3B8] font-semibold leading-[20px]">
          Conoce nuestra metodología y aprende cómo brindar clases increíbles.{' '}
          <br />
          Mira los videos y completa el quiz para segurar que estás listo/a.
        </p>
      </div>
      <div className="w-full h-[283px]">
        <div className="flex flex-col gap-2">
          {/* Video 1 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={handleToggle}
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Introduction to Neuroeducation
              <div className="flex gap-2 mt-2">
                {user?.Teacher?.academyVideos?.includes('Mvgn2wcnOsk') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                <span className="icon transition-transform duration-300">
                  <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                </span>
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="Mvgn2wcnOsk"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 2 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('Mvgn2wcnOsk')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Personalizing, our main strategy
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('Sm01TjJenQo') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('Mvgn2wcnOsk') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="Sm01TjJenQo"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 3 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('Sm01TjJenQo')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Motivation is the key to Success
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('MMZ1Rk9poMc') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('Sm01TjJenQo') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="MMZ1Rk9poMc"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 4 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('MMZ1Rk9poMc')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Incorporating Learning Disabilities
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('2cq158Psxq4') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('MMZ1Rk9poMc') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="2cq158Psxq4"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 5 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('2cq158Psxq4')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Building Confidence in Students
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('EPs0mkTE3vE') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('2cq158Psxq4') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="EPs0mkTE3vE"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 6 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('EPs0mkTE3vE')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Age-Based Strategies
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('tfUlKrSAe80') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('EPs0mkTE3vE') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="tfUlKrSAe80"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 7 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('tfUlKrSAe80')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              The Role of Play in Learning
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('rMyhlvYvPM8') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('tfUlKrSAe80') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="rMyhlvYvPM8"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 8 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('rMyhlvYvPM8')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Using Storytelling as a Teaching Tool
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('NKRBXRkADEQ') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('rMyhlvYvPM8') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="NKRBXRkADEQ"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>

          {/* Video 9 */}
          <details
            className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB] transition-all duration-300 overflow-hidden open:h-[787px] h-auto"
            onToggle={
              user?.Teacher?.academyVideos?.includes('NKRBXRkADEQ')
                ? handleToggle
                : (e) => {
                    e.currentTarget.open = false;
                  }
            }
          >
            <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
              Guide to a Successful First Session
              <div className="flex gap-2">
                {user?.Teacher?.academyVideos?.includes('Q4FK7GfYL3A') ? (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-emerald-600 border-opacity-20 bg-emerald-500 bg-opacity-10 rounded-md">
                    <img src={greenEye} alt="eye icon" />
                    <p className="font-nunito font-medium text-xs text-emerald-700">
                      Visto
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-2 gap-1 h-[21px] border-2 border-rose-600 border-opacity-20 bg-rose-500 bg-opacity-10 rounded-md">
                    <img src={redBlock} alt="block icon" />
                    <p className="font-nunito font-medium text-xs text-rose-700">
                      No visto
                    </p>
                  </div>
                )}
                {user?.Teacher?.academyVideos?.includes('NKRBXRkADEQ') && (
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                )}
              </div>
            </summary>
            {isOpen && (
              <>
                <YouTube
                  videoId="Q4FK7GfYL3A"
                  opts={opts}
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange}
                  className="mt-[10px]"
                />
                {isComplete && (
                  <p className="mt-4 text-green-500 font-bold">
                    🎉 ¡Felicidades! Has completado el vídeo.
                  </p>
                )}
                {isUpdating && (
                  <p className="mt-4 text-blue-500 font-bold">
                    Guardando progreso...
                  </p>
                )}
              </>
            )}
          </details>
          <div className="mt-6 mb-[100px]">
            <GreenButton type="button" width="204px" height="44px">
              <span className="whitespace-nowrap font-mont mr-[6px]">
                Realizar Quiz
              </span>
            </GreenButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
