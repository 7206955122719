import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export interface UpdateUserData {
  email?: string;
  profilePicture?: string | null; // URL ou caminho da imagem, se necessário
  password?: string;
  phone?: string;
  passwordChanged?: boolean;
  description?: string;
  availability?: string;
  username?: string;
  childLikes?: string;
  studentPassword?: string;
  studentId?: string;
  answerIntroduction?: string;
  answerEnglishLevel?: string;
  answerLikesChildren?: string;
  answerSituation1?: string;
  answerSituation2?: string;
  selectionPhase?: string;
  nationality?: string;
  countryOfResidence?: string;
  documentNumber?: string;
  iban?: string;
  masteredDisabilities?: string;
  billingAddress?: string;
}

export const updateUser = async (userId: string, data: UpdateUserData) => {
  try {
    const response = await axios.put(
      `${API_URL}/client/endpoints/user/profile/${userId}`,
      data
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao atualizar usuário:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao atualizar usuário:', error);
    }
    throw error;
  }
};
