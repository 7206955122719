import React, { useEffect, useState } from 'react';
import onboardingImage from '../../assets/onboarding.png';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import * as SolarIconSet from 'solar-icon-set';
import TeacherOnboardingLoading from '../Loading/OnboardingLoading/TeacherOnboardingLoading';
import countries from '../../constants/countries.json';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { SingleValue } from 'react-select';
import CountryFlag from 'react-country-flag';
import { User } from '../../@types/usersTypes';
import nationalities from '../../constants/nationalities.json';
// import { uploadProfileImage } from '../../service/auth/user/uploadProfileImage.service';
import {
  UpdateUserData,
  updateUser,
} from '../../service/auth/user/updateUser.service';
import { updateAvailability } from '../../service/teacher/updateAvailability.service';
import { getUser } from '../../service/auth/user/getUser.service';

// Indicador de progresso
const ProgressIndicator = ({ currentStep }: { currentStep: number }) => {
  return (
    <div className="flex justify-center items-center gap-2 mt-4">
      {[1, 2, 3, 4].map((step) => (
        <div
          key={step}
          className={`w-[35.2px] h-[4px] rounded-full ${
            step < currentStep
              ? 'bg-[#059669]'
              : step === currentStep
                ? 'bg-[#059669]'
                : 'bg-[#E5E7EB]'
          }`}
        />
      ))}
    </div>
  );
};

const TeacherOnboarding: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [answerIntroduction, setAnswerIntroduction] = useState('');
  const [masteredDisabilities, setMasteredDisabilities] = useState('');
  // const [file, setFile] = useState<File | null>();
  const [phone, setPhone] = useState('+');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [nationality, setNationality] = useState('');
  // const [documentType, setDocumentType] = useState('');
  // const [documentNumber, setDocumentNumber] = useState('');
  // const [iban, setIban] = useState('');
  const [answerEnglishLevel, setAnswerEnglishLevel] = useState('');
  // const [selectedDays, setSelectedDays] = useState<number[]>([]);
  // const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [answerLikesChildren, setAnswerLikesChildren] = useState('');
  const [answerSituation1, setAnswerSituation1] = useState('');
  const [answerSituation2, setAnswerSituation2] = useState('');
  const [daysAndHours, setDaysAndHours] = useState<
    Record<number, { start: string; end: string } | null>
  >({});

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const token = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    const checkUser = async () => {
      if (!token || !userId) {
        navigate('/login');
        return;
      } else {
        const userInfo = await getUser(userId);
        setUser(userInfo);
      }
    };
    checkUser();
  }, [token, userId, navigate]);

  type Field =
    | string
    | string[]
    | Date[]
    | Record<string, string>
    | number[]
    | File
    | null
    | undefined;

  const isEmpty = (field: Field): boolean => {
    if (field === undefined) {
      return true;
    }

    if (typeof field === 'string') {
      return field.trim() === '';
    }

    if (Array.isArray(field)) {
      return field.length === 0;
    }

    // Verificação específica para objetos do tipo Record<string, string>
    if (typeof field === 'object' && field !== null) {
      // Checa se algum valor do objeto é uma string vazia
      return Object.values(field).some((value) => value.trim() === '');
    }

    return false;
  };

  const daysOfWeek = [
    { label: 'Domingo', value: 0 },
    { label: 'Lunes', value: 1 },
    { label: 'Martes', value: 2 },
    { label: 'Miércoles', value: 3 },
    { label: 'Jueves', value: 4 },
    { label: 'Viernes', value: 5 },
    { label: 'Sábado', value: 6 },
  ];

  const availableTimes = [
    { label: '09:00', value: '08:00:00.000Z' },
    { label: '10:00', value: '09:00:00.000Z' },
    { label: '11:00', value: '10:00:00.000Z' },
    { label: '12:00', value: '11:00:00.000Z' },
    { label: '13:00', value: '12:00:00.000Z' },
    { label: '14:00', value: '13:00:00.000Z' },
    { label: '15:00', value: '14:00:00.000Z' },
    { label: '16:00', value: '15:00:00.000Z' },
    { label: '17:00', value: '16:00:00.000Z' },
    { label: '18:00', value: '17:00:00.000Z' },
    { label: '19:00', value: '18:00:00.000Z' },
    { label: '20:00', value: '19:00:00.000Z' },
    { label: '21:00', value: '20:00:00.000Z' },
  ];

  const handleDayChange = (day: number) => {
    setDaysAndHours((prev) => ({
      ...prev,
      [day]: prev[day] ? null : { start: '', end: '' }, // Ativa/desativa o dia
    }));
  };

  const handleTimeChange = (
    day: number,
    type: 'start' | 'end',
    value: string
  ) => {
    setDaysAndHours((prev) => ({
      ...prev,
      [day]: {
        start: prev[day]?.start || '', // Fallback para garantir uma string válida
        end: prev[day]?.end || '', // Fallback para garantir uma string válida
        [type]: value, // Atualiza a propriedade específica
      },
    }));
  };

  const handleNext = async () => {
    setError('');

    // Objeto de validação para cada passo do formulário
    const validationRules: { [key: number]: () => boolean } = {
      1: () =>
        isEmpty(answerIntroduction) ||
        isEmpty(masteredDisabilities) ||
        isEmpty(answerEnglishLevel),
      2: () =>
        (isEmpty(phone) && isEmpty(user?.phone)) ||
        isEmpty(countryOfResidence) ||
        isEmpty(billingAddress) ||
        isEmpty(nationality),
      3: () => {
        // Verifica se não há dias selecionados
        const noDaysSelected = Object.keys(daysAndHours).length === 0;

        // Verifica se algum dia selecionado tem horários incompletos
        const incompleteHours = Object.entries(daysAndHours).some(
          ([, value]) => !value || isEmpty(value.start) || isEmpty(value.end)
        );

        return noDaysSelected || incompleteHours;
      },
      4: () =>
        isEmpty(answerLikesChildren) ||
        isEmpty(answerSituation1) ||
        isEmpty(answerSituation2),
    };

    // // Verifica se o passo atual tem campos inválidos
    const hasError = validationRules[currentStep]?.();

    if (hasError) {
      setError('Por favor complete todos los campos antes de continuar');
      return;
    }

    if (currentStep < 4) {
      setError(''); // Limpa o erro se todos os campos estiverem preenchidos
      setCurrentStep(currentStep + 1);
    }

    if (currentStep === 4) {
      setIsLoading(true);
      // let imageUrl;

      // if (file && userId) {
      //   try {
      //     imageUrl = await uploadProfileImage(userId, file);
      //   } catch (error) {
      //     console.log(error);
      //     setError('Error al subir la foto: ');
      //   }
      // }
      // if (user && user.phone) {
      //   setPhone(user.phone);
      // }
      // console.log(phone);

      const formData: UpdateUserData = {
        answerIntroduction: answerIntroduction,
        masteredDisabilities: masteredDisabilities,
        // profilePicture: imageUrl ?? undefined,
        phone: user?.phone ?? phone,
        countryOfResidence: countryOfResidence,
        billingAddress: billingAddress,
        nationality: nationality,
        // documentNumber: documentNumber,
        // iban: iban,
        answerEnglishLevel: answerEnglishLevel,
        answerLikesChildren: answerLikesChildren,
        answerSituation1: answerSituation1,
        answerSituation2: answerSituation2,
        selectionPhase: 'UNDER_REVIEW',
      };

      const availability = Object.entries(daysAndHours).flatMap(
        ([day, hours]) => {
          if (!hours || !hours.start || !hours.end) {
            return [];
          }

          const startIndex = availableTimes.findIndex(
            (time) => time.value === hours.start
          );
          const endIndex = availableTimes.findIndex(
            (time) => time.value === hours.end
          );

          // Certifique-se de que os índices são válidos
          if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) {
            return [];
          }

          const intervals = [];

          for (let i = startIndex; i < endIndex; i++) {
            intervals.push({
              dayOfWeek: Number(day),
              startTime: availableTimes[i].value, // Usa o `value` exatamente como está
              endTime: availableTimes[i + 1].value, // Pega o próximo horário em UTC
            });
          }

          return intervals;
        }
      );

      try {
        if (userId) {
          await updateUser(userId, formData);
          if (availability.length > 0) {
            await updateAvailability(userId, availability);
          }
        }
      } catch (error) {
        console.log(error);
        setError('Error al actualizar usuario');
      } finally {
        setIsLoading(false);
        navigate('/teacher-portal');
      }
    }
  };

  const handlePrevious = () => {
    setError('');
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleIntroductionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswerIntroduction(e.target.value);
  };

  const handleMasteredChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMasteredDisabilities(e.target.value);
  };

  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     setFile(file);
  //   }
  // };

  const handlePhoneChange = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    setPhone(`+${sanitizedValue}`);
  };

  const countryOptions = Object.keys(countries as Record<string, string>).map(
    (code) => ({
      value: code,
      label: countries[code as keyof typeof countries],
    })
  );

  const handleCountryChange = (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    if (selectedOption) {
      setCountryOfResidence(selectedOption.label);
    }
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBillingAddress(e.target.value);
  };

  const nationalityOptions = Object.keys(
    nationalities as Record<string, string>
  ).map((code) => ({
    value: code,
    label: (nationalities as Record<string, string>)[code],
  }));

  const handleNationalityChange = (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    if (selectedOption) {
      setNationality(selectedOption.label);
    }
  };

  // const handleDocTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setDocumentType(e.target.value);
  // };

  // const handleDocNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setDocumentNumber(e.target.value);
  // };

  // const handleIbanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setIban(e.target.value);
  // };

  const handleEnglishLevelChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setAnswerEnglishLevel(e.target.value);
  };

  // const handleDayChange = (dayValue: number) => {
  //   setSelectedDays((prevDays) =>
  //     prevDays.includes(dayValue)
  //       ? prevDays.filter((day) => day !== dayValue)
  //       : [...prevDays, dayValue]
  //   );
  // };

  // const handleHourChange = (hourValue: string) => {
  //   setSelectedTimes((prevHours) =>
  //     prevHours.includes(hourValue)
  //       ? prevHours.filter((hour) => hour !== hourValue)
  //       : [...prevHours, hourValue]
  //   );
  // };

  const handleLikesChildrenChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswerLikesChildren(e.target.value);
  };

  const handleSituation1Change = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswerSituation1(e.target.value);
  };

  const handleSituation2Change = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswerSituation2(e.target.value);
  };

  return (
    <div className="relative min-h-screen">
      {isLoading && (
        <div
          className="absolute inset-0 flex items-center justify-center z-50"
          style={{ backgroundColor: '#0F172A99' }}
        >
          <TeacherOnboardingLoading />
        </div>
      )}
      <div
        className={`relative z-10 ${isLoading ? 'opacity-50' : ''} min-h-screen max-h-[890px] w-screen flex items-center justify-center overflow-hidden`}
      >
        <div className="flex w-full h-full items-center justify-center">
          <div className="flex items-center justify-center mx-auto max-h-[890px]">
            <div className="hidden md:flex items-center justify-center max-h-[890px] overflow-hidden">
              <img
                src={onboardingImage}
                alt="Descrição da imagem"
                className="object-cover w-[700px] max-h-[890px] h-auto opacity-100 rounded-2xl"
              />
            </div>

            {/* Cabeçalho com espaçamento */}
            <div className="flex flex-col items-start justify-between w-full max-w-[629px] h-full max-h-[890px] pl-[48px] gap-[72px] opacity-100">
              <div className="flex flex-col items-center w-full h-full mt-5">
                <img
                  src={logo}
                  alt="Logo"
                  className="max-w-full w-[75.16px] h-[80px]"
                />

                <div className="flex flex-col items-center w-full gap-[8px] mt-[40px] mb-[8px]">
                  <h2 className="text-[36px] font-mont font-extrabold leading-[36px] text-center text-[#0F172A]">
                    Cuéntanos un poco más
                  </h2>
                  <p className="text-[16px] font-bold font-nunito leading-[19.2px] text-center text-[#334155] mb-10">
                    Responde las preguntas a continuación para avanzar en el
                    proceso.
                  </p>
                  <ProgressIndicator currentStep={currentStep} />
                </div>

                {/* Contêiner das Etapas com espaçamento para evitar sobreposição */}
                <div className="relative flex flex-col items-center w-full h-[750px] overflow-hidden mt-10 mb-11">
                  {/* Step 1 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
                      currentStep === 1
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform -translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    <form className="w-full flex flex-col items-center gap-[8px]">
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="englishLevel"
                          className="w-[340px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          Nivel de inglés
                        </label>
                        <select
                          name="englishLevel"
                          id="englishLevel"
                          className="w-[421px] h-[44px] pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] font-mont placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={answerEnglishLevel}
                          onChange={handleEnglishLevelChange}
                          required
                        >
                          <option value="" className="text-gray-400">
                            Selecciona una opción
                          </option>
                          <option
                            value="Intermediate"
                            className="text-slate-700"
                          >
                            Intermediate
                          </option>
                          <option value="FCE" className="text-slate-700">
                            FCE
                          </option>
                          <option value="CAE" className="text-slate-700">
                            CAE
                          </option>
                          <option
                            value="Proficiency"
                            className="text-slate-700"
                          >
                            Proficiency
                          </option>
                          <option value="Nativo" className="text-slate-700">
                            Nativo
                          </option>
                        </select>
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="introduction"
                          className="w-[421px] h-[38px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100 mb-4"
                        >
                          ¿Quién eres, qué edad tienes, cuáles son tus gustos e
                          intereses, años de experiencia como profesor...?
                        </label>
                        <textarea
                          placeholder="Ingrese su mensaje aquí..."
                          name="introduction"
                          id="introduction"
                          className="w-[421px] h-[84px] min-h-[56px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={answerIntroduction}
                          onChange={handleIntroductionChange}
                        ></textarea>
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="experience"
                          className="w-[421px] h-[38px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100 mb-4"
                        >
                          Experiencia con algún trastorno de aprendizaje
                        </label>
                        <textarea
                          placeholder="Ingrese su mensaje aquí..."
                          name="experience"
                          id="experience"
                          className="w-[421px] h-[84px] min-h-[56px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={masteredDisabilities}
                          onChange={handleMasteredChange}
                        ></textarea>
                      </div>
                      {error && (
                        <div
                          className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                          style={{
                            boxShadow:
                              '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                          }}
                          role="alert"
                        >
                          <SolarIconSet.DangerCircle
                            iconStyle="BoldDuotone"
                            size={24}
                            color="#EF4444"
                          />
                          <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                            {error}
                          </span>
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={handleNext}
                        className="flex items-center justify-center w-[221px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition mt-5 font-mont"
                        style={{
                          boxShadow: '0px 1px 2px 0px #0000000D',
                        }}
                      >
                        SIGUIENTE PASO
                        <SolarIconSet.RoundAltArrowRight
                          size={20}
                          iconStyle="Bold"
                          className="ml-[6px]"
                        />
                      </button>
                    </form>
                  </div>
                  {/* Fim da Etapa 1 */}
                  {/* Step 2 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
                      currentStep === 2
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    <div className="w-full flex flex-col items-center gap-[8px]">
                      {!user?.phone && (
                        <div className="mb-4 flex flex-col">
                          <label
                            htmlFor="phone"
                            className="w-[421px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                          >
                            Teléfono
                          </label>
                          <PhoneInput
                            country={'es'} // País inicial
                            value={phone} // Valor do input
                            onChange={handlePhoneChange} // Função para lidar com alterações
                            containerStyle={{
                              width: '421px',
                              height: '44px',
                              backgroundColor: 'white',
                              border: '1px solid #D1D5DB', // Border similar ao input de email
                              borderRadius: '8px',
                              boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                              position: 'relative',
                            }}
                            inputStyle={{
                              width: '100%',
                              height: '100%',
                              paddingLeft: '48px', // Espaço para a bandeira do país
                              paddingRight: '16px',
                              paddingTop: '8px',
                              paddingBottom: '8px',
                              backgroundColor: 'white',
                              border: 'none',
                              fontFamily: 'Mont, sans-serif',
                              fontSize: '14px',
                              lineHeight: '20px',
                              color: '#334155',
                              boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                            }}
                            buttonStyle={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              height: '100%',
                              paddingLeft: '12px',
                              paddingRight: '12px',
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            dropdownStyle={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>
                      )}
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="residence"
                          className="w-[421px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          País de Residencia
                        </label>

                        <Select
                          options={countryOptions}
                          onChange={handleCountryChange}
                          value={countryOptions.find(
                            (option) => option.label === countryOfResidence
                          )}
                          formatOptionLabel={(option) => (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <CountryFlag
                                countryCode={option.value}
                                svg
                                style={{ marginRight: '8px' }}
                              />
                              {option.label}
                            </div>
                          )}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '421px',
                              height: '44px',
                              backgroundColor: 'white',
                              border: '1px solid #D1D5DB',
                              borderRadius: '8px',
                              boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                              paddingLeft: '12px',
                              paddingRight: '12px',
                            }),
                            input: (provided) => ({
                              ...provided,
                              fontFamily: 'Mont, sans-serif',
                              fontSize: '14px',
                              lineHeight: '20px',
                              color: '#334155',
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: '#9CA3AF',
                              fontFamily: 'Mont, sans-serif',
                              fontSize: '14px',
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? '#34D399'
                                : 'white',
                              color: state.isSelected ? 'white' : '#334155',
                              padding: '8px 16px',
                              '&:hover': {
                                backgroundColor: '#E5E7EB',
                              },
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: '#64748B',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: 'white',
                              borderRadius: '8px',
                              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: '#334155',
                            }),
                          }}
                          placeholder="Seleccione su país"
                        />
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="address"
                          className="w-[421px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          Dirección
                        </label>
                        <textarea
                          placeholder="Ingrese su mensaje aquí..."
                          name="address"
                          id="address"
                          className="w-[421px] h-[44px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={billingAddress}
                          onChange={handleAddressChange}
                        ></textarea>
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="nationality"
                          className="w-[421px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          Nacionalidad
                        </label>
                        <Select
                          options={nationalityOptions}
                          onChange={handleNationalityChange}
                          value={nationalityOptions.find(
                            (option) => option.label === nationality
                          )}
                          formatOptionLabel={(option) => (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <CountryFlag
                                countryCode={option.value}
                                svg
                                style={{ marginRight: '8px' }}
                              />
                              {option.label}
                            </div>
                          )}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '421px',
                              height: '44px',
                              backgroundColor: 'white',
                              border: '1px solid #D1D5DB',
                              borderRadius: '8px',
                              boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                              paddingLeft: '12px',
                              paddingRight: '12px',
                            }),
                            input: (provided) => ({
                              ...provided,
                              fontFamily: 'Mont, sans-serif',
                              fontSize: '14px',
                              lineHeight: '20px',
                              color: '#334155',
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: '#9CA3AF',
                              fontFamily: 'Mont, sans-serif',
                              fontSize: '14px',
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? '#34D399'
                                : 'white',
                              color: state.isSelected ? 'white' : '#334155',
                              padding: '8px 16px',
                              '&:hover': {
                                backgroundColor: '#E5E7EB',
                              },
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: '#64748B',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: 'white',
                              borderRadius: '8px',
                              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: '#334155',
                            }),
                          }}
                          placeholder="Seleccione su país"
                        />
                      </div>
                      {error && (
                        <div
                          className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                          style={{
                            boxShadow:
                              '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                          }}
                          role="alert"
                        >
                          <SolarIconSet.DangerCircle
                            iconStyle="BoldDuotone"
                            size={24}
                            color="#EF4444"
                          />
                          <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                            {error}
                          </span>
                        </div>
                      )}
                      {/* Botão para avançar para o próximo passo */}
                      <div className="flex w-full justify-between mt-20">
                        <button
                          onClick={handlePrevious}
                          className="flex items-center font-mont text-[14px] font-bold leading-[19.2px] text-center text-[#64748B] cursor-pointer"
                        >
                          <SolarIconSet.ArrowLeft size={20} color="#64748B" />
                          Paso Anterior
                        </button>

                        <button
                          type="button"
                          onClick={handleNext}
                          className="flex items-center justify-center w-[221px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition font-mont"
                          style={{
                            boxShadow: '0px 1px 2px 0px #0000000D',
                          }}
                        >
                          SIGUIENTE PASO
                          <SolarIconSet.RoundAltArrowRight
                            size={20}
                            iconStyle="Bold"
                            className="ml-[6px]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Fim da Etapa 2 */}
                  {/* Step 3 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out flex flex-col items-center ${
                      currentStep === 3
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    <div className="w-full flex flex-col items-center gap-[8px]">
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="availability"
                          className="w-[421px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100 mb-6"
                        >
                          Selecciona los días y horarios disponibles
                        </label>

                        {daysOfWeek.map((day) => (
                          <div
                            key={day.value}
                            className="flex items-center mb-4 ml-3"
                          >
                            <label className="font-nunito font-bold text-[16px] leading-[19.2px] text-[#64748B] flex items-center">
                              <input
                                type="checkbox"
                                checked={!!daysAndHours[day.value]}
                                onChange={() => handleDayChange(day.value)}
                                className="w-[16px] h-[16px] border border-solid border-[#CED3CE] bg-white shadow-inner mr-2"
                                style={{
                                  boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                                  borderRadius: '4px',
                                }}
                              />
                              <span>{day.label}</span>
                            </label>

                            {daysAndHours[day.value] && (
                              <div className="flex items-center ml-4">
                                <select
                                  value={daysAndHours[day.value]?.start || ''}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day.value,
                                      'start',
                                      e.target.value
                                    )
                                  }
                                  className="w-[120px] h-[32px] border border-solid border-[#CED3CE] rounded-md mr-2"
                                >
                                  <option value="">Início</option>
                                  {availableTimes.map((time) => (
                                    <option key={time.value} value={time.value}>
                                      {time.label}
                                    </option>
                                  ))}
                                </select>

                                <span className="mx-2">-</span>

                                <select
                                  value={daysAndHours[day.value]?.end || ''}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day.value,
                                      'end',
                                      e.target.value
                                    )
                                  }
                                  className="w-[120px] h-[32px] border border-solid border-[#CED3CE] rounded-md"
                                >
                                  <option value="">Término</option>
                                  {availableTimes.map((time) => (
                                    <option key={time.value} value={time.value}>
                                      {time.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      {error && (
                        <div
                          className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                          style={{
                            boxShadow:
                              '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                          }}
                          role="alert"
                        >
                          <SolarIconSet.DangerCircle
                            iconStyle="BoldDuotone"
                            size={24}
                            color="#EF4444"
                          />
                          <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                            {error}
                          </span>
                        </div>
                      )}
                      {/* Botão para avançar para o próximo passo */}
                      <div className="flex w-full justify-between mt-20">
                        <button
                          onClick={handlePrevious}
                          className="flex items-center font-mont text-[14px] font-bold leading-[19.2px] text-center text-[#64748B] cursor-pointer"
                        >
                          <SolarIconSet.ArrowLeft size={20} color="#64748B" />
                          Paso Anterior
                        </button>

                        <button
                          type="button"
                          onClick={handleNext}
                          className="flex items-center justify-center w-[221px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition font-mont"
                          style={{
                            boxShadow: '0px 1px 2px 0px #0000000D',
                          }}
                        >
                          SIGUIENTE PASO
                          <SolarIconSet.RoundAltArrowRight
                            size={20}
                            iconStyle="Bold"
                            className="ml-[6px]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Fim da Etapa 3 */}
                  {/* Step 4 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out flex flex-col items-center ${
                      currentStep === 4
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    <div className="w-full flex flex-col items-center gap-[8px]">
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="likesChildren"
                          className="w-[421px] h-[38px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          ¿Qué es lo que más te gusta de los niños?
                        </label>
                        <textarea
                          placeholder="Ingrese su mensaje aquí..."
                          name="likesChildren"
                          id="likesChildren"
                          className="w-[421px] h-[84px] min-h-[56px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={answerLikesChildren}
                          onChange={handleLikesChildrenChange}
                        ></textarea>
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="situation1"
                          className="w-[421px] h-[38px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          SITUACIÓN 1: ¿Cómo captarías la atención de un niño
                          distraído en una clase online?
                        </label>
                        <textarea
                          placeholder="Ingrese su mensaje aquí..."
                          name="situation1"
                          id="situation1"
                          className="w-[421px] h-[84px] min-h-[56px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={answerSituation1}
                          onChange={handleSituation1Change}
                        ></textarea>
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="situation2"
                          className="w-[421px] h-[38px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          SITUACIÓN 2: ¿Cómo planificarías clases personalizadas
                          sobre Dora la Exploradora?
                        </label>
                        <textarea
                          placeholder="Ingrese su mensaje aquí..."
                          name="situation2"
                          id="situation2"
                          className="w-[421px] h-[84px] min-h-[56px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={answerSituation2}
                          onChange={handleSituation2Change}
                        ></textarea>
                      </div>
                    </div>
                    {error && (
                      <div
                        className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                        style={{
                          boxShadow:
                            '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                        }}
                        role="alert"
                      >
                        <SolarIconSet.DangerCircle
                          iconStyle="BoldDuotone"
                          size={24}
                          color="#EF4444"
                        />
                        <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                          {error}
                        </span>
                      </div>
                    )}
                    {/* Botão para avançar para o próximo passo */}
                    <div className="flex w-full justify-between mt-20">
                      <button
                        onClick={handlePrevious}
                        className="flex items-center font-mont text-[14px] font-bold leading-[19.2px] text-center text-[#64748B] cursor-pointer"
                      >
                        <SolarIconSet.ArrowLeft size={20} color="#64748B" />
                        Paso Anterior
                      </button>

                      <button
                        type="button"
                        onClick={handleNext}
                        className="flex items-center justify-center w-[221px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition font-mont"
                        style={{
                          boxShadow: '0px 1px 2px 0px #0000000D',
                        }}
                      >
                        ENVIAR
                        <SolarIconSet.RoundAltArrowRight
                          size={20}
                          iconStyle="Bold"
                          className="ml-[6px]"
                        />
                      </button>
                    </div>
                  </div>
                  {/* Fim da Etapa 4 */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherOnboarding;
