export const teacherColors = (code: string | null | undefined) => {
  switch (code) {
    case '6EE7B7':
      return ' border-cyan-500 bg-cyan-400 text-cyan-600 ';
    case '86EFAC':
      return ' border-emerald-500 bg-emerald-400 text-emerald-600 ';
    case 'BEF264':
      return ' border-lime-500 bg-lime-400 text-lime-600 ';
    case '5EEAD4':
      return ' border-teal-500 bg-teal-400 text-teal-600 ';
    case 'FDBA74':
      return ' border-orange-500 bg-orange-400 text-orange-600 ';
    case 'FCD34D':
      return ' border-amber-500 bg-amber-400 text-amber-600 ';
    case 'FDE047':
      return ' border-yellow-500 bg-yellow-400 text-yellow-600 ';
    case '67E8F9':
      return ' border-blue-500 bg-blue-400 text-blue-600 ';
    case '7DD3FC':
      return ' border-sky-500 bg-sky-400 text-sky-600 ';
    case '93C5FD':
      return ' border-indigo-500 bg-indigo-400 text-indigo-600 ';
    case 'A5B4FC':
      return ' border-violet-500 bg-violet-400 text-violet-600 ';
    case 'C4B5FD':
      return ' border-purple-500 bg-purple-400 text-purple-600 ';
    case 'D8B4FE':
      return ' border-fuchsia-500 bg-fuchsia-400 text-fuchsia-600 ';
    case 'F0ABFC':
      return ' border-pink-500 bg-pink-400 text-pink-600 ';
    case 'FDA4AF':
      return ' border-red-500 bg-red-400 text-red-600 ';
    case 'FCA5A5':
    case 'F9A8D4':
      return ' border-rose-500 bg-rose-400 text-rose-600 ';
    default:
      return;
  }
};
