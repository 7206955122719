import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/onboardingLoading.json';

const TeacherOnboardingLoading: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-white rounded-3xl w-[448px] h-[380.22px]">
      {/* Animação Lottie */}
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: '156.22px', width: '159.57px' }}
      />

      {/* Texto "Procesando..." com três pontos pulando */}
      <h3 className="text-[36px] font-mont leading[36px] text-center font-extrabold mt-2 text-[#0F172A]">
        Procesando...
        {/* <span className="dot-pulse">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span> */}
      </h3>

      {/* Texto descritivo */}
      <p className="text-start font-mont font-medium text-[14px] leading-[20px] text-[#0F172A] mt-2 px-4">
        Nuestra AI está revisando los datos para <br />
        confirmar que todo este bien antes de <br />
        continuar con el proceso.
      </p>
    </div>
  );
};

export default TeacherOnboardingLoading;
