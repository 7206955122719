import React from 'react';
import {
  FaUser,
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTiktok,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();

  const handleLoginRedirect = async () => {
    if (token && userId) {
      navigate('/portal');
    } else {
      navigate('/login');
    }
  };

  const handleJoinTheTeam = () => {
    navigate('/register');
  };

  return (
    <div>
      {/* Top Bar */}
      <div className="flex justify-end items-center p-2.5 bg-[#6DD2BF]">
        <div className="flex items-center space-x-5">
          <button
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
            onClick={handleLoginRedirect}
          >
            <FaUser className="mr-2" /> Iniciar Sesión
          </button>
          <a
            href="https://wa.me/34622713212"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
          >
            <FaWhatsapp className="mr-2" /> Whatsapp
          </a>
          <a
            href="https://www.linkedin.com/company/e-pitinglish/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.instagram.com/epitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/ePitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.tiktok.com/@epitinglish"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaTiktok />
          </a>
        </div>
      </div>

      {/* Header Menu */}
      <div className="flex justify-between items-center p-4 bg-[#6DD2BF]">
        <div className="flex items-center">
          <img src="/logo.jpeg" alt="Logo" className="h-12" />
        </div>
        <div className="flex justify-around items-center space-x-8">
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            NUESTRA METODOLOGIA
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PROFESORES
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PRECIOS
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            BLOG
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            CONTACTO
          </button>
        </div>
      </div>
      {/* New Section */}
      <div className="bg-[#84D4C8] p-8 rounded-lg text-center my-8 mx-auto max-w-3xl">
        <h2 className="text-white text-2xl font-bold mb-4">
          ¿Quieres formar parte de nuestro equipo?
        </h2>
        <p className="text-white mb-6">
          ¡Empieza tu proceso rellenando el formulario, nos pondremos en
          contacto contigo lo antes posible!
        </p>
        <button
          onClick={handleJoinTheTeam}
          className="bg-[#FA877A] text-white py-3 px-6 rounded-full font-bold hover:bg-[#e66f60] transition-colors duration-300"
        >
          ¡QUIERO UNIRME!
        </button>
      </div>
    </div>
  );
};

export default Home;
