import React from 'react';
import errorImage from '../../../assets/error.png';
import logo from '../../../assets/logo.png';
import { useMediaQuery } from 'usehooks-ts';

const MaintenancePage: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 766px)');

  if (isMobile) {
    return (
      <div className="min-h-screen w-screen flex items-center justify-center overflow-hidden">
        <div className="flex items-center">
          {/* Contêiner Principal */}
          <div className="flex items-center mx-auto">
            {/* Área ao lado da Imagem */}
            <div className="flex flex-col opacity-100">
              {/* Logo e Formulário */}
              <div className="flex flex-col items-center w-full">
                {/* Logo */}
                <img
                  src={logo}
                  alt="Logo"
                  className="max-w-full w-[75.16px] h-[80px] mb-[36px] mr-[8rem]"
                />

                {/* Formulário de Login */}
                <div className="flex flex-col items-center w-full gap-6">
                  <div className="flex flex-col items-center gap-[8px]">
                    <h2 className="text-[36px] font-mont font-extrabold leading-[36px] text-center text-[#0F172A]">
                      ¡Oh No!
                    </h2>
                    <p className="text-[16px] font-nunito font-bold leading-[19.2px] text-center text-[#EF4444]">
                      Un error inesperado ha sucedido.
                      <br />
                      <br />
                    </p>
                    <p className="text-[16px] font-bold leading-[19.2px] text-center text-[#0F172A] font-nunito">
                      Estamos trabjando para solucionarlo lo antes posible.{' '}
                      <br />
                      Intenta conectarte más tarde
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen max-h-[890px] w-screen flex items-center justify-center overflow-hidden">
      <div className="flex w-full h-full items-center justify-center">
        {/* Contêiner Principal */}
        <div className="flex items-center justify-center mx-auto max-h-[890px]">
          {/* Área da Imagem */}
          <div className="hidden md:flex items-center justify-center max-h-[890px] overflow-hidden">
            <img
              src={errorImage}
              alt="Descrição da imagem"
              className="object-cover w-[700px] max-h-[890px] h-auto opacity-100"
            />
          </div>

          {/* Área ao lado da Imagem */}
          <div className="flex flex-col items-start justify-between w-full max-w-[629px] h-full max-h-[890px] gap-[72px] opacity-100">
            {/* Logo e Formulário */}
            <div className="flex flex-col items-center w-full h-full mb-[15rem]">
              {/* Logo */}
              <img
                src={logo}
                alt="Logo"
                className="max-w-full w-[75.16px] h-[80px] mb-[36px]"
              />

              {/* Formulário de Login */}
              <div className="flex flex-col items-center w-full mt-32">
                <div className="flex flex-col items-center gap-4">
                  <h2 className="text-[36px] font-mont font-extrabold leading-[36px] text-center text-[#0F172A]">
                    ¡Oh No!
                  </h2>
                  <p className="text-[16px] font-nunito font-bold leading-[19.2px] text-center text-[#EF4444]">
                    Un error inesperado ha sucedido.
                    <br />
                    <br />
                  </p>
                  <p className="text-[16px] font-bold font-nunito leading-[19.2px] text-center text-[#0F172A]">
                    Estamos trabjando para solucionarlo lo antes posible. <br />
                    Intenta conectarte más tarde
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
