import React, { useState } from 'react';
import logo from '../../../assets/logo.png';
import loginImage from '../../../assets/login.png';
import * as SolarIconSet from 'solar-icon-set';
import LoadingSpinner from '../../Loading/Spinner/Spinner';
import { Eye } from 'solar-icon-set';
import GreenButton from '../../UI/GreenButton';
import Input from '../../UI/Input';

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handlePasswordRecover = async (e: React.FormEvent) => {
    e.preventDefault(); // Previne o recarregamento da página
    setPasswordError([]); // Limpa o erro anterior, se houver
    setIsLoading(true);

    if (!passwordRegex.test(password)) {
      setPasswordError([
        'El password debe contener:',
        'Al menos 8 caracteres',
        'Un número',
        'Una minúscula',
        'Una mayúscula',
        'Un carácter especial (ej. !”·$%&/()',
      ]);
      setIsLoading(false);
      return;
    }

    // try {
    //   if (response.status === 404) {
    //     setPasswordError('Email incorrecto');
    //   } else {
    //     // Logica para enviar o email de recuperaçao para o usuario.
    //     console.log(response);
    //   }
    // } catch (error) {
    //   setPasswordError('Email incorrectos');
    // } finally {
    //   setIsLoading(false);
    // }
  };

  return (
    <div className="min-h-screen w-screen flex items-center justify-center overflow-hidden">
      <div className="flex w-full h-full items-center justify-center">
        {/* Contêiner Principal */}
        <div className="flex items-center justify-center mx-auto">
          {/* Área da Imagem */}
          <div className="flex items-center justify-center">
            <img
              src={loginImage}
              alt="Descrição da imagem"
              className="object-cover w-[700px] h-[897.89px] opacity-100"
            />
          </div>

          {/* Área ao lado da Imagem */}
          <div className="flex flex-col items-start justify-between w-[629px] h-[890px] pl-[48px] gap-[72px] opacity-100">
            {/* Logo e Formulário */}
            <div className="flex flex-col items-center w-full">
              {/* Container para Voltar e Logo */}
              <img
                src={logo}
                alt="Logo"
                className="max-w-full w-[75.16px] h-[80px] mb-[36px]"
              />

              {/* Formulário de Senha */}
              <div className="flex flex-col items-center w-full gap-6">
                <div className="flex flex-col items-center w-[581px] gap-[8px] mt-[72px] mb-[40px]">
                  <h2 className="text-[36px] font-extrabold leading-[36px] font-mont text-center text-[#0F172A]">
                    Crea tu Nuevo Password
                  </h2>
                  <p className="text-[16px] font-bold font-nunito leading-[19.2px] text-center text-[#334155]">
                    Tu nuevo Password tiene que tener como mínimo 8 letras, una
                    minúscula, una mayúscula y un caracter especial
                  </p>
                </div>
                <form
                  className="w-full flex flex-col items-center gap-[8px]"
                  onSubmit={handlePasswordRecover}
                >
                  <div className="mb-4 flex flex-col">
                    <div className="relative flex flex-col">
                      <Input
                        label="Nuevo Password"
                        width="340px"
                        height="44px"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      {/* Botão para mostrar/ocultar senha */}
                      <button
                        type="button"
                        onClick={() => setShowPassword((prev) => !prev)}
                        className="absolute top-9 right-0 flex items-center pr-3 text-[#94A3B8]"
                      >
                        <Eye className="w-5 h-5" />
                      </button>

                      {/* Ícone de erro dentro do input */}
                      {passwordError.length > 0 && (
                        <span className="absolute inset-y-0 right-2 flex items-center">
                          <SolarIconSet.DangerCircle
                            iconStyle="BoldDuotone"
                            size={18}
                            color="#EF4444"
                          />
                        </span>
                      )}
                    </div>

                    {/* Renderiza a lista de erros de senha */}
                    {passwordError.length > 0 && (
                      <ul className="text-[#EF4444] text-sm mt-1 list-disc ml-6">
                        {/* Renderiza a primeira linha sem o bullet point */}
                        <li className="list-none">{passwordError[0]}</li>
                        {/* Renderiza os itens restantes com o bullet point */}
                        {passwordError.slice(1).map((error, index) => (
                          <li key={index} className="list-disc">
                            {error}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <GreenButton type="submit" width="332px" height="52px">
                    <span className="whitespace-nowrap font-mont">
                      REESTABELECE TU PASSWORD
                    </span>
                    {/* Substitua pelo ícone que você tem */}
                    <SolarIconSet.RoundAltArrowRight
                      size={20}
                      iconStyle="Bold"
                    />
                  </GreenButton>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
