import axios from 'axios';

// Interfaces para o perfil do estudante
export interface StudentProfile {
  id: string;
  parentName: string;
  parentLastname: string;
  childName: string;
  childAge: number;
  childGender: string;
  schoolYear: string | null;
  childGrades: string;
  difficultyArea: string;
  concentrationIssue: string;
  productiveTime: string | null;
  childLikes: Record<string, string>;
  learningDisabilities: string[];
  prefersChildConvo: string | null;
  totalLessons: number;
  lastLessonDate: string | null;
  firstLessonCompleted: boolean;
  initialWritingScore: number | null;
  initialGrammarScore: number | null;
  initialUseOfEnglishScore: number | null;
  initialSpeakingScore: number | null;
  currentWritingScore: number | null;
  currentGrammarScore: number | null;
  currentUseOfEnglishScore: number | null;
  currentSpeakingScore: number | null;
  birthday: string | null;
  avatarUrl: string;
  username: string | null;
  password: string | null;
  createdAt: string;
  userId: string;
  teacherId: string | null;
  parentId: string;
}

// Interfaces para a disponibilidade do estudante
interface AvailabilityDay {
  date: Date;
  dayOfWeek: number;
}

interface AvailabilityStudent {
  selectedDays: AvailabilityDay[];
  selectedTimes: string[];
}

// Interfaces para a disponibilidade dos professores e aulas agendadas
interface ScheduledLesson {
  id: string;
  startTime: string;
  endTime: string;
  teacherId: string;
  studentId: string;
  parentId: string;
  createdAt: string;
  updatedAt: string;
}

interface Teacher {
  id: string;
  fullName: string;
  masteredDisabilities: string;
  countryOfResidence: string;
  dyslexiaLessonsGiven: number;
  teaLessonsGiven: number;
  tdahLessonsGiven: number;
  scheduledLessons: ScheduledLesson[];
}

interface TeacherAvailabilityDay {
  dayOfWeek: number;
  teachers: Teacher[];
}

interface TeacherAvailability {
  availableTeachers: TeacherAvailabilityDay[];
}

const AI_URL = process.env.REACT_APP_AI_URL;

export const recommendTeacher = async (
  studentProfile: StudentProfile,
  availability: AvailabilityStudent,
  teacherAvailability: TeacherAvailability
) => {
  try {
    const payload = {
      student_profile: {
        ...studentProfile,
        childLikes:
          typeof studentProfile.childLikes === 'string'
            ? JSON.parse(studentProfile.childLikes)
            : studentProfile.childLikes,
      },
      availability: {
        selectedDays:
          availability.selectedDays?.map((day) => ({
            date: day.date.toISOString(), // Convertendo Date para string ISO
            dayOfWeek: day.dayOfWeek,
          })) || [], // Valor padrão vazio caso `selectedDays` seja undefined
        selectedTimes: availability.selectedTimes || [], // Usando os horários convertidos para UTC
      },
      teacher_availability: {
        availableTeachers: teacherAvailability.availableTeachers.map(
          (entry) => ({
            dayOfWeek: entry.dayOfWeek,
            teachers: entry.teachers.map((teacher) => ({
              id: teacher.id,
              fullName: teacher.fullName,
              masteredDisabilities: teacher.masteredDisabilities,
              countryOfResidence: teacher.countryOfResidence,
              dyslexiaLessonsGiven: teacher.dyslexiaLessonsGiven,
              teaLessonsGiven: teacher.teaLessonsGiven,
              tdahLessonsGiven: teacher.tdahLessonsGiven,
              scheduledLessons: teacher.scheduledLessons.map((lesson) => ({
                id: lesson.id,
                startTime: lesson.startTime,
                endTime: lesson.endTime,
                teacherId: lesson.teacherId,
                studentId: lesson.studentId,
                parentId: lesson.parentId,
                createdAt: lesson.createdAt,
                updatedAt: lesson.updatedAt,
              })),
            })),
          })
        ),
      },
    };
    const response = await axios.post(`${AI_URL}/recommend-teacher`, payload);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao recomendar professor:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao recomendar professor:', error);
    }
    throw error;
  }
};
