import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const uploadProfileImage = async (
  userId: string,
  file: File
): Promise<string | null> => {
  try {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('fileType', 'profile'); // Especifica o tipo de arquivo

    const response = await axios.post(
      `${API_URL}/client/endpoints/user/profile/upload/${userId}`, // Rota do backend para upload
      fileData
    );

    return response.data.url; // Retorna a URL do arquivo
  } catch (error) {
    console.error('Erro ao fazer upload da imagem:', error);
    return null;
  }
};
