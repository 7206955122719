import React, { useEffect, useState } from 'react';
import {
  StudentProfile,
  TeacherProfile,
  User,
} from '../../../@types/usersTypes';
import { getTeachers } from '../../../service/teacher/getTeachers.service';
import TeacherList from './TeacherList';
import TeacherCalendar from './TeacherCalendar';
import LoadingSpinner from '../../Loading/Spinner/Spinner';

interface CalendarProps {
  user: User | null;
  selectedChild: StudentProfile | null;
  setActivePage: (page: string) => void;
}

const Calendar: React.FC<CalendarProps> = ({
  // user,
  selectedChild,
  // setActivePage,
}) => {
  const [teachersList, setTeachersList] = useState<TeacherProfile[]>([]);
  const [teachersCalendar, setTeachersCalendar] = useState<TeacherProfile[]>(
    []
  );
  const [childTeacher, setChildTeacher] = useState<TeacherProfile | null>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchTeachers = async () => {
    setIsLoading(true);
    try {
      const response = await getTeachers();
      setTeachersList(response);
      setTeachersCalendar(response);
    } catch (error) {
      console.error('Erro ao buscar professores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setChildTeacher(selectedChild?.teacher || null);
    setIsLoading(false);
  }, [selectedChild]);

  let hasScheduledLesson;
  let totalLessons;
  if (selectedChild) {
    hasScheduledLesson = selectedChild?.scheduledLessons?.length > 0;
    totalLessons = selectedChild?.totalLessons || 0;
  }

  // Função para pegar a próxima aula do filho selecionado
  const getNextLessonForChild = (child: StudentProfile | null) => {
    if (!child || !child.scheduledLessons) return null;

    if (child.scheduledLessons.length) {
      // Ordenar as aulas por data para pegar a próxima
      const sortedLessons = [...child.scheduledLessons].sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      return sortedLessons[0]; // Retorna a próxima aula
    }
    return null; // Caso não tenha aulas
  };

  // Aula filtrada para o filho selecionado
  const nextLesson = getNextLessonForChild(selectedChild);

  if (teachersList.length === 0 || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex max-w-[1160px] h-full overflow-hidden mt-10">
      <div className="flex-grow bg-[#F3F4F6] overflow-y-auto no-scrollbar w-[1160px] mx-auto mb-20">
        {/* Primeira Section com fundo verde */}
        <section className="w-full h-[116px] bg-[#34D399] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] flex items-center justify-start">
          {hasScheduledLesson ? (
            <div className="text-white flex items-center justify-between w-full px-6">
              <div className="text-start">
                <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                  ¡{selectedChild?.childName} tu clase está reservada!
                </h3>
                <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                  Entra a clase 15 minutos antes para configurar la cámara y el
                  micrófono.
                </p>
              </div>

              {nextLesson &&
                (() => {
                  const lessonStartTime = new Date(nextLesson.startTime);
                  const currentTime = new Date();
                  const timeDifference =
                    lessonStartTime.getTime() - currentTime.getTime();
                  const fifteenMinutesInMs = 15 * 60 * 1000; // 15 minutos em milissegundos

                  // Verifica se estamos no intervalo de 15 minutos antes da aula
                  const isWithin15Minutes =
                    timeDifference <= fifteenMinutesInMs && timeDifference > 0;

                  return (
                    <button
                      className={`font-mont font-extrabold text-[14px] leading-[17.89px] w-[228px] h-[38px] px-4 py-2 rounded-md ${
                        isWithin15Minutes
                          ? 'bg-[#FFFFFF] text-[#065F46] hover:bg-[#059669] hover:text-white cursor-pointer border-2 border-[#069668]'
                          : 'bg-[#FFFFFF] text-[#065F46]'
                      } shadow-[0px_1px_2px_0px_#0000000D]`} // Sombra adicionada
                      disabled={!isWithin15Minutes} // Desabilita o clique fora do intervalo
                    >
                      {isWithin15Minutes
                        ? 'Unirme Ahora'
                        : `Entrar a clase a las ${new Date(
                            lessonStartTime.getTime() - 15 * 60000 // Subtrai 15 minutos
                          ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`}
                    </button>
                  );
                })()}
            </div>
          ) : totalLessons === 0 ? (
            <div className="text-white text-start ml-6">
              <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                ¡Reserva tu clase de prueba gratuita!
              </h3>
              <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                Conoce a nuestros profesores y elige el horario que mejor se
                adapte a ti.
              </p>
            </div>
          ) : (
            <div className="text-white text-start ml-6">
              <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                ¡Reserva ahora tu clase!
              </h3>
              <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                Elige uno de nuestros profesores y la fecha que más te convenga.
              </p>
            </div>
          )}
        </section>

        {/* Segunda Section com duas divs */}
        <section className="flex flex-row w-full mt-5 mb-10">
          {/* Div esquerda */}
          <div className="w-[256px] h-[476px] bg-white mr-4 rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A]">
            <TeacherList
              teachers={teachersList}
              childTeacher={childTeacher}
              setTeachers={setTeachersCalendar}
            />
          </div>

          {/* Div direita */}
          <div className="w-[888px] h-[709px] bg-white rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A]">
            <TeacherCalendar
              teachers={teachersCalendar}
              childTeacher={childTeacher}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Calendar;
