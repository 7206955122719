import React, { InputHTMLAttributes } from 'react';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  width: string;
  height: string;
  placeholder: string;
  name: string;
}

const Input = ({
  name,
  label,
  width,
  height,
  placeholder,
  ...props
}: IInput) => {
  return (
    <div className="flex flex-col">
      <label
        htmlFor={name}
        className="h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#334155] opacity-100 font-nunito"
        style={{
          width: width,
        }}
      >
        {label}
      </label>
      <input
        id={name}
        className="mt-1 pl-4 py-2 font-mont bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
        style={{
          boxShadow: 'inset 0px 2px 5px 0px #0000001A',
          width: width,
          height: height,
        }}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default Input;
