import React, { useState } from 'react';
import { User } from '../../../@types/usersTypes';
import * as SolarIconSet from 'solar-icon-set';
import canguruSubs from '../../../assets/canguruSubs.png';
import starBlue from '../../../assets/StarBlue.png';
import closeIcon from '../../../assets/close.svg';
import dashboardBG from '../../../assets/dashboardBG.jpeg';
import GreenButton from '../../UI/GreenButton';

interface WithCreditsProps {
  user: User | null;
}

const plansMock: {
  plan: string;
  expirationDate: string;
  subscribeDate: string;
  status: string;
}[] = [
  /* {
    plan: "10 e-Classes",
    expirationDate: "01/01/2025",
    subscribeDate: "10/10/2024",
    status: "Activa",
  },
  {
    plan: "20 e-Classes",
    expirationDate: "01/01/2025",
    subscribeDate: "10/10/2024",
    status: "Completada",
  }, */
];

const activePlan: string = '10';

const WithCredits: React.FC<WithCreditsProps> = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  return (
    <div className="flex flex-col flex-grow bg-[#F3F4F6] overflow-y-auto overflow-x-hidden mx-auto">
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="absolute top-[241px] left-[723px] w-[386px] gap-4 flex flex-col bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
            {/* Modal Header */}
            <div className="flex justify-between items-center">
              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                Comprar más Clases
              </h4>
              <button
                onClick={() => {
                  setShowModal(false);
                  setSelectedPlan('');
                }}
              >
                <img src={closeIcon} alt="Close Icon" width="24" height="24" />
              </button>
            </div>
            <p className="font-mont font-semibold text-[14px] leading-5 text-[#64748B] text-left">
              A continuación puedes elegir tu nuevo plan de e-Classes o e-Club
              según lo desees.
            </p>
            <div className="flex flex-col justify-center items-start gap-1">
              <select
                id="plan"
                name="plan"
                value={selectedPlan}
                onChange={(e) => setSelectedPlan(e.target.value)}
                required
                className="mb-3 w-full p-3 rounded-md bg-white border-[1px] border-[#CED3CE] border-solid border-opacity-20 items-center font-mont text-[14px] leading-[20px] text-[#334155]"
              >
                <option value="">Seleccionar plan *</option>
                <option value="10">
                  10 e-Classes - <span className="font-black">80,00 €</span>
                </option>
                <option value="20">
                  20 e-Classes - <span className="font-black">140,00 €</span>
                </option>
                <option value="40">
                  40 e-Classes - <span className="font-black">240,00 €</span>
                </option>
              </select>
            </div>
            <GreenButton
              width="338px"
              height="44px"
              onClick={() => console.log(selectedPlan)}
            >
              Comprar Ahora
            </GreenButton>
            <button
              className="w-[338px] h-[44px] border-2 text-red-500 border-red-500 bg-white rounded-md font-mont font-bold text-[14px] leading-[20px] hover:bg-red-200"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      {/* Flex container para organizar lado a lado */}
      {/* Section de cima */}
      <section
        className="relative mt-12 w-[1140px] h-[323px] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] bg-cover bg-center"
        style={{ backgroundImage: `url(${dashboardBG})` }}
      >
        {/* Div do conteúdo de texto */}
        <div className="text-white flex flex-col items-start w-full px-6 pt-6 gap-6">
          <h3 className="text-[36px] font-extrabold font-mont text-[#FFFFFF] leading-[36px]">
            Mi Suscripción
          </h3>
          <div className="flex flex-col">
            <p className="text-[16px] font-nunito font-bold text-[#FFFFFF] leading-[21.82px]">
              Paquete:{' '}
              <span className="font-nunito font-extrabold text-[16px] leading-[21.82px]">
                10 e-Classes
              </span>
            </p>
            <p className="text-[16px] font-nunito font-bold text-[#FFFFFF] leading-[21.82px]">
              Precio por sesión:{' '}
              <span className="font-nunito font-extrabold text-[16px] leading-[21.82px]">
                {' '}
                8,00 €
              </span>
            </p>
            <p className="text-[16px] font-nunito font-bold text-[#FFFFFF] leading-[21.82px]">
              Vencimiento:{' '}
              <span className="font-nunito font-extrabold text-[16px] leading-[21.82px]">
                01/01/2025
              </span>
            </p>
            <p className="text-[16px] font-nunito font-bold text-[#FFFFFF] leading-[21.82px]">
              Precio total:{' '}
              <span className="font-nunito font-extrabold text-[16px] leading-[21.82px]">
                {' '}
                80,00 €
              </span>
            </p>
          </div>
          <div className="mt-3 flex items-center justify-center w-[200px] h-[30px] gap-[6px] rounded-lg bg-[#FEECEF] text-[#BE123C]">
            <SolarIconSet.NotebookMinimalistic color="#B91C1C" size={16} />
            <span className="font-nunito font-normal text-[16px] leading-[21.82px]">
              {user?.Wallet.credits} clases disponibles
            </span>
          </div>
          {user?.Wallet?.credits === 0 && (
            <button
              onClick={() => setShowModal(true)}
              className="w-[215px] h-[38px] rounded-md bg-white font-mont font-extrabold text-[14px] leading-[18px] text-[#059669] border-2 border-[#059669] hover:bg-emerald-100"
            >
              Compra más clases!
            </button>
          )}
        </div>

        {/* Div da imagem */}

        <img
          src={canguruSubs}
          alt="Canguru Subscription"
          className="absolute w-[332px] h-[371px] right-[65px] -top-[48px]"
        />
      </section>
      {/* Div com os planos */}
      <div className="flex flex-col mt-3 items-center">
        <h3 className="mb-3 font-nunito font-bold text-[24px] leading-[32.74px] text-[#64748B]">
          Otros Planes Disponibles
        </h3>
        <div className="w-full h-[246px] mb-6 flex justify-center gap-4">
          {activePlan !== '10' && (
            <div className="w-full h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between relative overflow-visible">
              <div className="bg-[#0284C7] text-center rounded-t-2xl pt-6 pb-3">
                <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                  10 e-Classes
                </h3>
              </div>

              <div className="flex flex-col items-center justify-center mt-3">
                <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                  8,00 € por sesión
                </p>
              </div>

              <div className="flex flex-col items-center justify-center mt-6">
                <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                  Total: 80,00 €
                </p>
              </div>

              <div className="flex justify-center mx-4 mt-6 mb-8">
                <button className="w-full h-[40px] border-2 border-[#0284C7] rounded-lg text-[#0284C7] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#0284C7] hover:text-white transition-colors">
                  Comprar 10 e-Classes
                </button>
              </div>
            </div>
          )}

          {activePlan !== '20' && (
            <div className="relative w-full h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between overflow-visible">
              {/* Selo posicionado com overflow visível */}
              <div
                className="absolute top-[-14px] right-[0px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white z-10"
                style={{
                  backgroundImage: `url('${starBlue}')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <p className="font-nunito font-normal text-[10px] leading-[14px]">
                  Ahorro
                </p>
                <span className="font-nunito font-bold text-[14px] leading-[14px]">
                  12,5%
                </span>
              </div>

              <div className="bg-[#0284C7] text-center rounded-t-2xl pt-6 pb-3">
                <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                  20 e-Classes
                </h3>
              </div>

              <div className="flex flex-col items-center justify-center mt-3">
                <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                  7,00 € por sesión
                </p>
              </div>

              <div className="flex flex-col items-center justify-center mt-6">
                <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                  Total: 140,00 €
                </p>
              </div>

              <div className="flex justify-center mx-4 mt-6 mb-8">
                <button className="w-full h-[40px] border-2 border-[#0284C7] rounded-lg text-[#0284C7] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#0284C7] hover:text-white transition-colors">
                  Comprar 20 e-Classes
                </button>
              </div>
            </div>
          )}

          {activePlan !== '40' && (
            <div className="relative w-full h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between overflow-visible">
              {/* Selo posicionado com overflow visível */}
              <div
                className="absolute top-[-14px] right-[0px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white z-10"
                style={{
                  backgroundImage: `url('${starBlue}')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <p className="font-nunito font-normal text-[10px] leading-[14px]">
                  Ahorro
                </p>
                <span className="font-nunito font-bold text-[14px] leading-[14px]">
                  25%
                </span>
              </div>

              <div className="bg-[#0284C7] text-center rounded-t-2xl pt-6 pb-3">
                <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                  40 e-Classes
                </h3>
              </div>

              <div className="flex flex-col items-center justify-center mt-3">
                <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                  6,00 € por sesión
                </p>
              </div>

              <div className="flex flex-col items-center justify-center mt-6">
                <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                  Total: 240,00 €
                </p>
              </div>

              <div className="flex justify-center mx-4 mt-6 mb-8">
                <button className="w-full h-[40px] border-2 border-[#0284C7] rounded-lg text-[#0284C7] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#0284C7] hover:text-white transition-colors">
                  Comprar 40 e-Classes
                </button>
              </div>
            </div>
          )}
        </div>
        {/* <div className="w-full h-[246px] flex justify-center gap-4">
          <div className="w-[276.67px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between">
            <div className="bg-[#F59E0B] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                1 e-Club
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                16,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 16,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[218.67px] h-[40px] border-2 border-[#F59E0B] rounded-lg text-[#F59E0B] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#F59E0B] hover:text-white transition-colors">
                Comprar 1 e-Club
              </button>
            </div>
          </div>
          <div className="w-[276.67px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between relative">
            <div
              className="absolute top-[-14px] right-[-7px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white"
              style={{
                backgroundImage: `url('${starYellow}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="font-nunito font-normal text-[10px] leading-[14px]">
                Ahorro
              </p>
              <span className="font-nunito font-bold text-[14px] leading-[14px]">
                12,5%
              </span>
            </div>

            <div className="bg-[#F59E0B] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                5 e-Club
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                14,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 70,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[218.67px] h-[40px] border-2 border-[#F59E0B] rounded-lg text-[#F59E0B] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#F59E0B] hover:text-white transition-colors">
                Comprar 5 e-Club
              </button>
            </div>
          </div>
          <div className="relative w-[276.67px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between">
            <div
              className="absolute top-[-14px] right-[0px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white z-10"
              style={{
                backgroundImage: `url('${starYellow}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="font-nunito font-normal text-[10px] leading-[14px]">
                Ahorro
              </p>
              <span className="font-nunito font-bold text-[14px] leading-[14px]">
                25%
              </span>
            </div>

            <div className="bg-[#F59E0B] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                10 e-Club
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                12,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 120,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[218.67px] h-[40px] border-2 border-[#F59E0B] rounded-lg text-[#F59E0B] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#F59E0B] hover:text-white transition-colors">
                Comprar 10 e-Club
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <div className="bg-white rounded-2xl p-8 gap-6 shadow-md">
        <div>
          <p className="font-nunito text-[20px] text-[#64748B] font-bold leading-[27px]">
            Historial de Subscripciones
          </p>
        </div>
        <div>
          {plansMock.length > 0 ? (
            <table className="w-full my-4">
              <thead>
                <tr className="font-mont text-[#334155] text-[14px] leading-[20px] font-bold text-left">
                  <th className="w-[221.6px] h-[44px]">
                    Fecha de Subscripción
                  </th>
                  <th className="w-[221.6px] h-[44px]">Paquete</th>
                  <th className="w-[221.6px] h-[44px]">Vencimiento</th>
                  <th className="w-[221.6px] h-[44px]">Estado</th>
                </tr>
              </thead>
              <tbody>
                {plansMock.map((item) => {
                  return (
                    <tr
                      key={`${item.plan}-${item.status}-${item.expirationDate}`}
                      className="text-sm font-normal h-[58px] text-[#64748B] text-left border-b border-solid border-[#E5E7EB]"
                    >
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.subscribeDate}</p>
                      </td>
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.plan}</p>
                      </td>
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.expirationDate}</p>
                      </td>
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.status}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="w-max h-[24px] px-2 mt-4 flex items-center justify-center rounded-lg bg-[#FEECEF] text-[#BE123C] border-opacity-10 border-2 border-rose-700">
              <SolarIconSet.DangerCircle
                color="#B91C1C"
                size={16}
                className="mx-1"
              />
              <span className="font-nunito font-normal text-[16px] leading-[21.82px]">
                Aún no tienes historial de suscripciones disponible.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WithCredits;
