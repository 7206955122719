import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/registerLoading.json';

const RegisteringLoading: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-white rounded-3xl w-[448px] h-[380.22px]">
      {/* Animação Lottie */}
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: '156.22px', width: '159.57px' }}
      />

      {/* Texto "Procesando..." com três pontos pulando */}
      <h3 className="text-[36px] font-mont leading[36px] text-center font-extrabold mt-2 text-[#0F172A]">
        Registrando peque
        <span className="dot-pulse">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </h3>
    </div>
  );
};

export default RegisteringLoading;
