import React, { useState } from 'react';
import circle from '../../../assets/circle.png';
import closeIcon from '../../../assets/close.svg';
import play from '../../../assets/play.png';
import locationIcon from '../../../assets/map-point.png';
import soundIcon from '../../../assets/volume.png';
import { TeacherProfile } from '../../../@types/usersTypes';
import YouTube, { YouTubeProps } from 'react-youtube';
import { Rating, Star } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';

interface TeacherIntroCardProps {
  teacher: TeacherProfile | undefined;
}

const customStyles = {
  itemShapes: Star,
  activeFillColor: '#f5bc00',
  inactiveFillColor: '#f5bc00',
};

const TeacherIntroCard: React.FC<TeacherIntroCardProps> = ({ teacher }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '630',
    width: '985',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  console.log('rocha', teacher);

  return (
    <div className="relative w-[256px] h-[593px] bg-white mr-[20px] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] p-4">
      {showVideoModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col gap-4 absolute bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                <p className="font-nunito font-bold text-[18px] leading-[18px] text-[#0F172A]">
                  {`Introdución de ${teacher?.fullName}`}
                </p>
              </h4>
              <button onClick={() => setShowVideoModal(false)}>
                <img src={closeIcon} alt="Close Icon" width="24" height="24" />
              </button>
            </div>
            <YouTube
              videoId="ez8m4PXksQs"
              opts={opts}
              onReady={onPlayerReady}
            />
          </div>
        </div>
      )}
      {/* Cabeçalho com a thumbnail do vídeo */}
      <div className="relative flex justify-center pb-4 border-b-2 border-opacity-20 border-gray-300">
        <img
          src={teacher?.profilePicture || ''}
          alt={`${teacher?.fullName}'s profile`}
          className="w-[208px] h-[127px] object-cover rounded-2xl"
        />
        {/* Ícone de Play sobreposto */}
        <button
          onClick={() => {
            setShowVideoModal(true);
          }}
          className="absolute inset-0 flex items-center justify-center"
          aria-label="Play Video"
        >
          {/* Círculo vazado */}
          <img
            src={circle} // Caminho da imagem do círculo vazado
            alt="Circle Icon"
            className="h-[41.67px] w-[41.67px]" // Ajuste o tamanho conforme necessário
          />
          {/* Ícone de Play sobreposto ao círculo */}
          <img
            src={play} // Caminho da imagem do ícone de play
            alt="Play Icon"
            className="absolute h-[16.67px] w-[12.5px]" // Centraliza o ícone de play dentro do círculo
          />
        </button>
      </div>
      {/* Dados de localização e sotaque */}
      <div className="border-b-2 py-4 border-opacity-20 border-gray-300">
        <p className="font-nunito font-black text-lg text-[#374151]">
          Datos Adicionales
        </p>
        <div className="flex flex-row justify-start items-center gap-1">
          <img
            src={locationIcon}
            alt="Location Icon"
            width="14"
            height="14"
            className="w-[14px] h-[14px]"
          />
          <p className="font-nunito font-medium text-sm text-[#0F172A]">
            {teacher?.Teacher.countryOfResidence}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center gap-1">
          <img
            src={soundIcon}
            alt="Sound Icon"
            width="14"
            height="14"
            className="w-[14px] h-[14px]"
          />
          <p className="font-nunito font-medium text-sm text-[#0F172A]">
            Britanico | Americano | Australiano
          </p>
        </div>
      </div>
      {/* Parte de notas */}
      <div>
        <div className="flex flex-col">
          <h3 className="font-nunito font-extrabold text-[18px] leading-[25px] text-[#374151] mt-4">
            Calificación general
          </h3>
          <div className="flex items-center mb-4">
            <Rating
              readOnly
              value={5}
              style={{ maxWidth: 90 }}
              items={5}
              itemStyles={customStyles}
            />
            <p className="font-nunito font-medium text-[12px] leading-[16px] text-[#0F172A] pt-[3px] pl-[2px]">
              5,0
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <div className="h-[32px] flex justify-between font-nunito font-semibold text-[14px] leading-[20px] text-[#64748B] border-b-2 border-opacity-20 border-gray-300">
              <p>Claridad</p>
              <span>5,0</span>
            </div>
            <div className="h-[32px] flex justify-between font-nunito font-semibold text-[14px] leading-[20px] text-[#64748B] border-b-2 border-opacity-20 border-gray-300">
              <p>Empatia</p>
              <span>5,0</span>
            </div>
            <div className="h-[32px] flex justify-between font-nunito font-semibold text-[14px] leading-[20px] text-[#64748B] border-b-2 border-opacity-20 border-gray-300">
              <p>Paciencia</p>
              <span>5,0</span>
            </div>
            <div className="h-[32px] flex justify-between font-nunito font-semibold text-[14px] leading-[20px] text-[#64748B] border-b-2 border-opacity-20 border-gray-300">
              <p>Pronunciación</p>
              <span>5,0</span>
            </div>
            <div className="h-[32px] flex justify-between font-nunito font-semibold text-[14px] leading-[20px] text-[#64748B]">
              <p>Punctualidad</p>
              <span>5,0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherIntroCard;
