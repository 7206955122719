import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import TeacherDashboard from './components/TeacherDashboard/TeacherDashboard';
import UserProfile from './components/UserProfile/UserProfile';
import LoginPage from './components/Home/LoginPage/LoginPage';
import './App.css';
import RegisterPage from './components/Home/RegisterPage/RegisterPage';
import MaintenancePage from './components/Home/Maintenance/Maintenance';
import { ref, onValue } from 'firebase/database';
import { database } from './firebase';
import Spinner from './components/Loading/Spinner/Spinner';
import ForgotPassword from './components/Home/ForgotPassword/ForgotPassword';
import ResetPassword from './components/Home/ResetPassword/ResetPassword';
import ParentOnboarding from './components/ParentOnboarding/ParentOnboarding';
import Portal from './components/Portal/Portal';
import TeacherOnboarding from './components/TeacherOnbording/TeacherOnbording';
import Policy from './components/Home/PolicyAndTerms/Policy';
import Terms from './components/Home/PolicyAndTerms/Terms';
import Cookies from './components/Home/PolicyAndTerms/Cookies';
import LegalAdvice from './components/Home/PolicyAndTerms/LegalAdvice';
import TeacherPortal from './components/TeacherPortal/TeacherPortal';
// import Forca from './components/Games/Forca/Forca';
// import CrossWord from './components/Games/CrossWord/CrossWord';

const App: React.FC = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const maintenanceRef = ref(database, 'maintenanceMode');

    const unsubscribe = onValue(
      maintenanceRef,
      (snapshot) => {
        const data = snapshot.val();
        if (typeof data === 'boolean') {
          setIsMaintenanceMode(data);
        } else {
          console.error('Valor inesperado para maintenanceMode:', data);
        }
      },
      (error) => {
        console.error('Erro ao ler o modo de manutenção:', error);
        setIsMaintenanceMode(false);
      }
    );

    return () => unsubscribe();
  }, []);

  if (isMaintenanceMode === null) {
    return <Spinner />;
  }

  return (
    <Router>
      <Routes>
        {/* Página inicial */}
        <Route path="/" element={<Home />} />

        {/* Rotas de manutenção e autenticação */}
        <Route
          path="/login"
          element={isMaintenanceMode ? <MaintenancePage /> : <LoginPage />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<RegisterPage />} />

        {/* Rotas para diferentes dashboards */}
        <Route path="/teacher-dashboard" element={<TeacherDashboard />} />

        {/* Onboarding para pais */}
        <Route path="/parent-onboarding" element={<ParentOnboarding />} />
        <Route path="/teacher-onboarding" element={<TeacherOnboarding />} />

        {/* Dashboard para pais dentro do Portal */}
        <Route path="/portal" element={<Portal />} />

        <Route path="/teacher-portal" element={<TeacherPortal />} />

        {/* Página de perfil do usuário */}
        <Route path="/user-profile" element={<UserProfile />} />

        {/* Terms and Policy */}
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cookies-policy" element={<Cookies />} />
        <Route path="/legal-advice" element={<LegalAdvice />} />

        {/* Rota de teste */}
        {/* <Route path="/test" element={<Academy />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
