import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export interface AddChildData {
  name: string;
  age: string;
  gender: string;
  selectedDisorders: string[];
  englishLevel: string;
  improveArea: string;
  likesDetails: Record<string, string>;
}

export const addChild = async (parentId: string, childData: AddChildData) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/profile/${parentId}/add-child`,
      childData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao adicionar filho:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao adicionar filho:', error);
    }
    throw error;
  }
};
