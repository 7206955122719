import axios from 'axios';
import { TeacherForm } from '../../../@types/teacherTypes';

const API_URL = process.env.REACT_APP_API_URL;

export const registerTeacher = async (form: TeacherForm) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/teacher/auth/register`,
      form,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (!response.status) {
      throw new Error('Erro ao registrar usuário');
    }
    return response.data;
  } catch (error) {
    console.error('Erro:', error);
    throw error;
  }
};
