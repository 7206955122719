import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export interface ScheduleLessonData {
  teacherId: string;
  studentId: string;
  parentId: string;
  startTime: string; // Formato ISO: '2024-10-03T09:00:00.000Z'
  endTime: string; // Formato ISO: '2024-10-03T10:00:00.000Z'
}

export const scheduleLesson = async (lessonData: ScheduleLessonData) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/profile/schedule-lesson`,
      lessonData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao agendar a aula:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao agendar a aula:', error);
    }
    throw error;
  }
};
