import React from 'react';
import { User } from '../../../@types/usersTypes';
import * as SolarIconSet from 'solar-icon-set';
import NoCredits from './NoCredits';
import WithCredits from './WithCredits';

interface SubscriptionProps {
  user: User | null;
}

const Subscription: React.FC<SubscriptionProps> = ({ user }) => {
  let hasCredits;
  if (user) {
    hasCredits = user.Wallet.credits > 0;
  }

  return (
    <div className="flex max-w-[1160px] h-full">
      <div className="flex-grow bg-[#F3F4F6] overflow-y-auto overflow-x-hidden w-[1160px] mx-auto mb-20 h-full no-scrollbar">
        {hasCredits ? <WithCredits user={user} /> : <NoCredits />}
        <section className="flex max-w-[1160px] h-[500px] justify-center items-center mx-auto mt-6">
          {/* Seção de Perguntas Frequentes */}
          <div className="w-full h-full">
            <h2 className="font-bold leading-[32.74px] text-[24px] text-[#64748B] font-nunito mb-3">
              Preguntas frecuentes
            </h2>
            <div className="flex flex-col gap-2">
              {/* Pergunta 1 */}
              <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                  ¿Cómo funcionan los créditos?
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                </summary>
                <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus tempor lorem non est congue blandit.
                </p>
              </details>

              {/* Pergunta 2 */}
              <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                  ¿Cuándo caducan los créditos?
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                </summary>
                <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus tempor lorem non est congue blandit.
                </p>
              </details>

              {/* Pergunta 3 */}
              <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                  ¿Puedo combinar e-classes con e-clubs?
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                </summary>
                <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus tempor lorem non est congue blandit.
                </p>
              </details>

              {/* Pergunta 4 */}
              <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                  ¿Puedo cambiar de plan después de haber comprado uno?
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                </summary>
                <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus tempor lorem non est congue blandit.
                </p>
              </details>

              <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer mb-[40px] border border-[#E5E7EB]">
                <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                  ¿Cómo invito a mis amigos y obtengo recompensas?
                  <span className="icon transition-transform duration-300">
                    <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                  </span>
                </summary>
                <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus tempor lorem non est congue blandit.
                </p>
              </details>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Subscription;
