import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export interface SelectedDay {
  date: Date; // A data completa
  dayOfWeek: number; // O dia da semana (0-6)
}

export const checkAvailability = async (requestData: {
  selectedDays: SelectedDay[];
  selectedTimes: string[];
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/profile/check-availability`,
      requestData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao checar disponibilidade:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao checar disponibilidade:', error);
    }
    throw error;
  }
};
