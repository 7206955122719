import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../Loading/Spinner/Spinner';
import facebookIcon from '../../../assets/facebook.svg';
import googleIcon from '../../../assets/Google.svg';
import registerImage from '../../../assets/register.png';
import logo from '../../../assets/logo.png';
import { registerParent } from '../../../service/auth/user/registerParent.service';
import axios from 'axios';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../../firebase';
import { registerParentWithGoogle } from '../../../service/auth/registerGoogle/registerParentGoogle.service';
import { registerTeacherWithGoogle } from '../../../service/auth/registerGoogle/registerTeacherGoogle.service';
import { useMediaQuery } from 'usehooks-ts';
import PhoneInput from 'react-phone-input-2';
// import { validatePhoneNumber } from '../../../validators/phoneNumber';
import * as SolarIconSet from 'solar-icon-set';
import GreenButton from '../../UI/GreenButton';
import Input from '../../UI/Input';
import { registerTeacher } from '../../../service/auth/registerTeacher/registerTeacher.service';

const RegisterPage: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 766px)');
  const [nameError, setErrorName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    fullName: '',
    email: '',
    phone: '+',
  });
  const [role, setRole] = useState<string>();
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!role) {
      setRoleError('Por favor seleccione si eres padre o profesor');
      return;
    }
    setIsLoading(true);

    // Limpa os erros anteriores
    setPhoneError('');
    setEmailError('');
    setError('');
    setErrorName('');
    setRoleError('');

    // Validação de nome e sobrenome
    const nameParts = form.fullName.trim().split(' ');
    if (nameParts.length < 2) {
      setErrorName('Ingrese su nombre y apellido.');
      setIsLoading(false);
      return;
    }

    // Validação de número de telefone
    // if (!validatePhoneNumber(form.phone)) {
    //   setPhoneError('Teléfono inválido.');
    //   setIsLoading(false);
    //   return;
    // }

    // Se todas as validações passarem, prosseguir com o registro
    try {
      const registerFunction =
        role === 'padre' ? registerParent : registerTeacher;
      const response = await registerFunction(form);
      const { token, userId, redirectTo } = response;
      if (token && userId && redirectTo) {
        localStorage.setItem('authToken', token);
        localStorage.setItem('userId', userId);
        navigate(redirectTo);
      } else {
        setError('Ocurrió un error al registrarse');
        return;
      }
    } catch (error) {
      // Verificação de tipo do erro como instância de AxiosError
      if (axios.isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 409:
            setEmailError('Este email ya está registrado.');
            break;
          case 400:
            setEmailError('Formato de email inválido.');
            break;
          case 401:
            setError('Usuario no encontrado. Por favor, regístrese primero.');
            break;
          case 503:
            setError(
              'Error de red al verificar el registro. Por favor, inténtelo más tarde.'
            );
            break;
          case 500:
            setError(
              'Error interno del servidor. Por favor, inténtelo más tarde.'
            );
            break;
          default:
            setError(
              'Ocurrió un error inesperado. Por favor, inténtelo de nuevo.'
            );
        }
      } else {
        console.error('Erro ao registrar:', error);
        setError('Ocurrió un error al registrarse');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRole(e.target.value);
  };

  const handleGoogleRegistration = async () => {
    if (!role) {
      setRoleError('Por favor seleccione si es padre o profesor');
      return;
    }
    setRoleError('');
    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken();

      // Decide qual função de registro usar com base na role
      const registerFunction =
        role === 'padre' ? registerParentWithGoogle : registerTeacherWithGoogle;

      const response = await registerFunction(token);
      const { token: backendToken, userId, redirectTo } = response;
      if (backendToken && userId && redirectTo) {
        localStorage.setItem('authToken', backendToken);
        localStorage.setItem('userId', userId);
        navigate(response.redirectTo);
      } else {
        setError('Error al registrarse');
        return;
      }
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status === 409
      ) {
        setError('Este email ya está registrado.');
      } else {
        setError(
          'Error al registrarse en Google. Por favor inténtalo de nuevo.'
        );
      }
      console.error('Error al registrarse a través de Google:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleLoginNavigate = () => {
    navigate('/login');
  };

  const handlePhoneChange = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    setForm((prevForm) => ({
      ...prevForm,
      phone: `+${sanitizedValue}`,
    }));
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isMobile) {
    return (
      <div className="min-h-screen w-screen flex items-center justify-center overflow-hidden">
        <div className="flex w-full h-full items-center justify-center">
          {/* Contêiner Principal */}
          <div className="flex items-center justify-center mx-auto">
            {/* Área ao lado da Imagem */}
            <div className="flex flex-col items-start justify-between opacity-100">
              {/* Logo e Formulário */}
              <div className="flex flex-col items-center w-full">
                {/* Logo */}
                <img
                  src={logo}
                  alt="Logo"
                  className="max-w-full w-[75.16px] h-[80px] mb-[36px]"
                />
                {/* Formulário de Registro */}
                <div className="flex flex-col items-center w-full">
                  <div className="flex flex-col items-center w-[581px] gap-[8px] mt-8 mb-8">
                    <h2 className="text-[1.8rem] font-extrabold leading-[36px] text-center text-[#0F172A] font-mont">
                      ¡Aquí comienza la magia!
                    </h2>
                    <p className="text-[16px] font-bold mx-[9rem] leading-[19.2px] text-center text-[#334155] font-nunito">
                      Regístrate en nuestra plataforma y empieza a disfrutar de
                      una forma de aprender inglés única
                    </p>
                  </div>
                  <form
                    className="w-full flex flex-col items-center mb-14"
                    onSubmit={handleRegister}
                  >
                    <div className="flex flex-col">
                      <div className="flex items-center gap-4 justify-between mb-8">
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="role"
                            value="padre"
                            checked={role === 'padre'}
                            onChange={handleRoleChange}
                            className="hidden" // Esconde o input original
                          />
                          <div
                            className={`h-5 w-5 border-2 rounded-full flex items-center justify-center transition-colors duration-300 ${
                              role === 'padre'
                                ? 'bg-[#059669] border-[#059669]'
                                : 'border-gray-300'
                            }`}
                          >
                            {role === 'padre' && (
                              <SolarIconSet.CheckCircle
                                size={20}
                                color="#FFFFFF"
                              />
                            )}
                          </div>
                          <span className="text-[16px] font-bold text-[#64748B] font-nunito">
                            Soy Padre
                          </span>
                        </label>

                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="gender"
                            value="profesor"
                            checked={role === 'profesor'}
                            onChange={handleRoleChange}
                            className="hidden" // Esconde o input original
                          />
                          <div
                            className={`w-5 h-5 border-2 rounded-full flex items-center justify-center transition-colors duration-300 ${
                              role === 'profesor'
                                ? 'bg-[#059669] border-[#059669]'
                                : 'border-gray-300'
                            }`}
                          >
                            {role === 'profesor' && (
                              <SolarIconSet.CheckCircle
                                size={20}
                                color="#FFFFFF"
                              />
                            )}
                          </div>
                          <span className="text-[16px] font-bold text-[#64748B] font-nunito">
                            Soy Profesor
                          </span>
                        </label>
                      </div>
                      {/* Nombre Completo */}
                      <div className="flex flex-col mb-4">
                        <label
                          htmlFor="fullName"
                          className="w-[280px] h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#0F172A] opacity-100 font-nunito"
                        >
                          Nombre Completo
                        </label>
                        <input
                          type="text"
                          id="fullName"
                          name="fullName"
                          className="mt-1 w-[280px] h-[44px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner "
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          placeholder="Escribe tu nombre"
                          value={form.fullName}
                          onChange={handleInputChange}
                          required
                        />
                        {nameError && (
                          <p className="text-[#EF4444] text-sm mt-1 ml-2">
                            {nameError}
                          </p>
                        )}
                      </div>

                      {/* Email */}
                      <div className="flex flex-col mb-4">
                        <label
                          htmlFor="email"
                          className="w-[280px] h-[19px] px-[12px] font-nunito text-[16px] font-bold leading-[19.2px] text-left text-[#0F172A] opacity-100"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="mt-1 w-[280px] h-[44px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          placeholder="Escribe tu Email"
                          value={form.email}
                          onChange={handleInputChange}
                          required
                        />
                        {emailError && (
                          <p className="text-[#EF4444] text-sm mt-1 ml-2">
                            {emailError}
                          </p>
                        )}
                      </div>

                      {/* Telefone com DDD */}
                      <div className="flex flex-col mb-8">
                        <label
                          htmlFor="phone"
                          className="w-[280px] h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#0F172A] opacity-100 font-nunito"
                        >
                          Teléfono
                        </label>
                        <PhoneInput
                          country={'es'} // País inicial
                          value={form.phone} // Valor do input
                          onChange={handlePhoneChange} // Função para lidar com alterações
                          containerStyle={{
                            width: '280px',
                            height: '44px',
                            backgroundColor: 'white',
                            border: '1px solid #D1D5DB', // Border similar ao input de email
                            borderRadius: '8px',
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A', // Ajustando a sombra interna para ser igual ao input de email
                            position: 'relative',
                          }}
                          inputStyle={{
                            width: '100%',
                            height: '100%',
                            paddingLeft: '48px', // Espaço para a bandeira do país
                            paddingRight: '16px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            backgroundColor: 'white',
                            border: 'none',
                            fontFamily: 'Mont, sans-serif',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#334155',
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A', // Ajustando a sombra interna para ser igual ao input de email
                          }}
                          buttonStyle={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                          dropdownStyle={{
                            backgroundColor: 'white',
                          }}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                            placeholder: 'Escribe tu Teléfono',
                          }} // Propriedades adicionais do input
                        />

                        {phoneError && (
                          <p className="text-[#EF4444] text-sm mt-1 ml-2">
                            {phoneError}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Exibe o erro se houver */}
                    {error && (
                      <div
                        className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner mb-4"
                        style={{
                          boxShadow:
                            '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A', // Sombra do alerta
                        }}
                        role="alert"
                      >
                        {/* Texto de erro */}
                        <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                          {error}
                        </span>
                      </div>
                    )}

                    <GreenButton type="submit" width="280px" height="52px">
                      <span className="whitespace-nowrap font-mont mr-[6px]">
                        CREA TU CUENTA
                      </span>
                      <SolarIconSet.RoundAltArrowRight
                        size={20}
                        iconStyle="Bold"
                      />
                    </GreenButton>
                    <div className="mt-4 text-[12px] text-[#6B7280] text-center">
                      {/* Primeira linha do texto */}
                      <p className="text-[#6B7280] font-mont font-medium text-[12px]">
                        Al registrarte, aceptas nuestros
                      </p>

                      {/* Segunda linha com os links */}
                      <p className="text-[#099E65]">
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[#099E65] underline"
                        >
                          Termos y Condiciones
                        </a>{' '}
                        y{' '}
                        <a
                          href="/policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[#099E65] underline"
                        >
                          Política de privacidad
                        </a>{' '}
                      </p>
                    </div>
                  </form>
                  {/* PARA REGISTRAR COM GOOGLE E FACEBOOKS */}
                  <div className="flex items-center w-[419px] gap-0 mt-6 mb-6">
                    <hr className="flex-grow border-t border-gray-300" />
                    <span className="px-4 text-[14px] font-medium leading-[20px] text-[#9CAFA5] font-nunito">
                      o regístrate con
                    </span>
                    <hr className="flex-grow border-t border-gray-300" />
                  </div>

                  <div className="flex flex-col gap-4 items-center">
                    {/* Botão Login con Google */}
                    <button
                      className="flex items-center w-[306px] h-[44px] bg-white border-t border-[#CFCFCF] rounded-md shadow-sm"
                      style={{
                        padding: '12px 16px',
                        boxShadow:
                          '0px 1px 2px 0px #00000026, inset 0px -4px 4px 0px #0000001A',
                      }}
                      onClick={handleGoogleRegistration}
                    >
                      {/* Ícone à Esquerda */}
                      <img
                        src={googleIcon}
                        alt="Google Icon"
                        className="w-[20px] h-[20px] mr-2"
                      />
                      {/* Texto do Botão */}
                      <span className="text-[14px] font-medium leading-[20px] text-[#374151] font-inter">
                        Regístrate con Google
                      </span>
                    </button>
                    {/* Botão Login con Facebook */}
                    <button
                      className="flex items-center w-[306px] h-[44px] bg-white border-t border-[#CFCFCF] rounded-md shadow-sm"
                      style={{
                        padding: '12px 16px',
                        boxShadow:
                          '0px 1px 2px 0px #00000026, inset 0px -4px 4px 0px #0000001A',
                      }}
                    >
                      {/* Ícone à Esquerda */}
                      <img
                        src={facebookIcon}
                        alt="Facebook Icon"
                        className="w-[20px] h-[20px] mr-2"
                      />
                      {/* Texto do Botão */}
                      <span className="text-[14px] font-medium leading-[20px] text-[#374151] font-inter">
                        Regístrate con Facebook
                      </span>
                    </button>
                    <div className="text-sm text-gray-500 text-center">
                      <p
                        className="text-gray-500 text-center"
                        style={{ fontFamily: 'Mont, sans-serif' }}
                      >
                        Ya tienes una cuenta?{' '}
                        <a href="/login" className="text-[#099E65] underline">
                          Inicia sesíon
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[1440px] max-h-[1000px] mx-auto flex overflow-hidden">
      {/* Div 1 */}
      <div className="w-1/2 h-full  flex justify-center items-center overflow-hidden pb-10">
        <img
          src={registerImage}
          alt="Imagem Div 1"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Div 2 */}
      <div className="w-1/2 h-full flex flex-col justify-between items-center overflow-hidden">
        {/* Div interna 1 */}
        <div className="w-[581px] h-[108.48px]  mb-3 mt-10">
          <div className="flex flex-col items-center w-full">
            <img
              src={logo}
              alt="Logo"
              className="max-w-full w-[75.16px] h-[80px] mb-[36px]"
            />
          </div>
        </div>

        {/* Div interna 2 */}
        <div className="w-[581px] h-[481px] mb-14">
          <div className="flex flex-col items-center w-full">
            <div className="flex flex-col items-center w-[581px] gap-[8px] mb-[24px]">
              <h2 className="text-[36px] font-extrabold leading-[36px] text-center text-[#0F172A] font-mont">
                ¡Aquí comienza la magia!
              </h2>
              <p className="text-[16px] font-bold leading-[19.2px] text-center text-[#334155] font-nunito">
                Regístrate en nuestra plataforma y empieza a disfrutar de una
                forma de aprender inglés única
              </p>
            </div>
            <form
              className="w-full flex flex-col items-center mb-14"
              onSubmit={handleRegister}
            >
              <div className="flex flex-col">
                <div className="flex flex-col gap-2 mb-4">
                  <div className="flex items-center gap-8">
                    {/* Opção Soy Padre */}
                    <label className="flex items-center gap-2 cursor-pointer">
                      <input
                        type="radio"
                        name="role"
                        value="padre"
                        checked={role === 'padre'}
                        onChange={handleRoleChange}
                        className="hidden" // Esconde o input original
                      />
                      <div
                        className={`w-5 h-5 border-2 rounded-full flex items-center justify-center transition-colors duration-300 ${
                          role === 'padre'
                            ? 'bg-[#059669] border-[#059669]'
                            : 'border-gray-300'
                        }`}
                      >
                        {role === 'padre' && (
                          <SolarIconSet.CheckCircle size={20} color="#FFFFFF" />
                        )}
                      </div>
                      <span className="text-[16px] font-bold text-[#64748B] font-nunito">
                        Soy Padre
                      </span>
                    </label>

                    {/* Opção Soy Profesor */}
                    <label className="flex items-center gap-2 cursor-pointer">
                      <input
                        type="radio"
                        name="role"
                        value="profesor"
                        checked={role === 'profesor'}
                        onChange={handleRoleChange}
                        className="hidden" // Esconde o input original
                      />
                      <div
                        className={`w-5 h-5 border-2 rounded-full flex items-center justify-center transition-colors duration-300 ${
                          role === 'profesor'
                            ? 'bg-[#059669] border-[#059669]'
                            : 'border-gray-300'
                        }`}
                      >
                        {role === 'profesor' && (
                          <SolarIconSet.CheckCircle size={20} color="#FFFFFF" />
                        )}
                      </div>
                      <span className="text-[16px] font-bold text-[#64748B] font-nunito">
                        Soy Profesor
                      </span>
                    </label>
                  </div>

                  {/* Mensagem de erro */}
                  {roleError && (
                    <p className="text-[#EF4444] text-sm">{roleError}</p>
                  )}
                </div>

                {/* Nombre Completo */}
                <div className="flex flex-col mb-4 relative">
                  {/* Container do input com posicionamento relativo */}
                  <div className="relative flex flex-col">
                    <Input
                      label="Nombre Completo"
                      width="280px"
                      height="44px"
                      type="text"
                      id="fullName"
                      name="fullName"
                      placeholder="Escribe tu nombre"
                      value={form.fullName}
                      onChange={(e) =>
                        setForm((prevForm) => ({
                          ...prevForm,
                          fullName: e.target.value,
                        }))
                      }
                      required
                    />

                    {/* Ícone de erro, mostrado apenas se nameError existir */}
                    {nameError && (
                      <SolarIconSet.DangerCircle
                        iconStyle="BoldDuotone"
                        size={20}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#EF4444] mt-3"
                        color="#EF4444"
                      />
                    )}
                  </div>

                  {/* Mensagem de erro */}
                  {nameError && (
                    <p className="text-[#EF4444] text-sm mt-1 ml-2">
                      {nameError}
                    </p>
                  )}
                </div>

                {/* Email */}
                <div className="flex flex-col mb-4 relative">
                  {/* Container do input com posicionamento relativo */}
                  <div className="relative flex flex-col">
                    <Input
                      label="Email"
                      type="email"
                      id="email"
                      name="email"
                      width="280px"
                      height="44px"
                      placeholder="Escribe tu Email"
                      value={form.email}
                      onChange={(e) =>
                        setForm((prevForm) => ({
                          ...prevForm,
                          email: e.target.value,
                        }))
                      }
                      required
                    />

                    {/* Ícone de erro, mostrado apenas se emailError existir */}
                    {emailError && (
                      <SolarIconSet.DangerCircle
                        iconStyle="BoldDuotone"
                        size={20}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#EF4444] mt-3"
                        color="#EF4444"
                      />
                    )}
                  </div>

                  {/* Mensagem de erro */}
                  {emailError && (
                    <p className="text-[#EF4444] text-sm mt-1 ml-2">
                      {emailError}
                    </p>
                  )}
                </div>

                {/* Telefone com DDD */}
                <div className="flex flex-col mb-8 relative">
                  <label
                    htmlFor="phone"
                    className="w-[280px] h-[19px] px-[12px] font-nunito text-[16px] font-bold leading-[19.2px] text-left text-[#0F172A] opacity-100"
                  >
                    Teléfono
                  </label>
                  <div className="relative">
                    <PhoneInput
                      country={'es'} // País inicial
                      value={form.phone} // Valor do input
                      onChange={handlePhoneChange} // Função para lidar com alterações
                      containerStyle={{
                        width: '280px',
                        height: '44px',
                        backgroundColor: 'white',
                        border: '1px solid #D1D5DB', // Border similar ao input de email
                        borderRadius: '8px',
                        boxShadow: 'inset 0px 2px 5px 0px #0000001A', // Ajustando a sombra interna para ser igual ao input de email
                        position: 'relative',
                      }}
                      inputStyle={{
                        width: '100%',
                        height: '100%',
                        paddingLeft: '48px', // Espaço para a bandeira do país
                        paddingRight: '16px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        backgroundColor: 'white',
                        border: 'none',
                        fontFamily: 'Mont, sans-serif',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#334155',
                        boxShadow: 'inset 0px 2px 5px 0px #0000001A', // Ajustando a sombra interna para ser igual ao input de email
                      }}
                      buttonStyle={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      dropdownStyle={{
                        backgroundColor: 'white',
                      }}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                        placeholder: 'Escribe tu Teléfono',
                      }} // Propriedades adicionais do input
                    />

                    {phoneError && (
                      <p className="text-[#EF4444] text-sm mt-3 ml-2">
                        {phoneError}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Exibe o erro se houver */}
              {error && (
                <div
                  className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                  style={{
                    boxShadow:
                      '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A', // Sombra do alerta
                  }}
                  role="alert"
                >
                  {/* Texto de erro */}
                  <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                    {error}
                  </span>
                </div>
              )}

              <GreenButton type="submit" width="280px" height="52px">
                <span className="whitespace-nowrap font-mont mr-[6px]">
                  CREA TU CUENTA
                </span>
                <SolarIconSet.RoundAltArrowRight size={20} iconStyle="Bold" />
              </GreenButton>
              <div className="mt-4 text-[12px] text-[#6B7280] text-center">
                {/* Primeira linha do texto */}
                <p className="text-[#6B7280] font-mont font-medium text-[12px]">
                  Al registrarte, aceptas nuestros
                </p>

                {/* Segunda linha com os links */}
                <p className="text-[#099E65]">
                  <a
                    href="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#099E65] underline"
                  >
                    Termos y Condiciones
                  </a>{' '}
                  y{' '}
                  <a
                    href="/policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#099E65] underline"
                  >
                    Política de privacidad
                  </a>{' '}
                </p>
              </div>
            </form>
          </div>
        </div>

        {/* Div interna 3 */}
        <div className="w-[306px] h-[171px] mb-[17.52px] mt-8">
          <div className="flex items-center w-full gap-0 mb-4">
            <hr className="flex-grow border-t border-gray-300" />
            <span className="px-4 text-[14px] font-medium leading-[20px] text-[#9CAFA5] font-nunito">
              o regístrate con
            </span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>
          <div className="flex flex-col items-center">
            {/* Botão Login con Google */}
            <button
              className="flex items-center w-[280px] h-[44px] bg-white border-t border-[#CFCFCF] rounded-md shadow-sm mb-4"
              style={{
                padding: '12px 16px',
                boxShadow:
                  '0px 1px 2px 0px #00000026, inset 0px -4px 4px 0px #0000001A',
              }}
              onClick={handleGoogleRegistration}
            >
              {/* Ícone à Esquerda */}
              <img
                src={googleIcon}
                alt="Google Icon"
                className="w-[20px] h-[20px] mr-2"
              />
              {/* Texto do Botão */}
              <span className="text-[14px] font-medium font-inter leading-[20px] text-[#374151]">
                Regístrate con Google
              </span>
            </button>
            {/* Botão Login con Facebook */}
            <button
              className="flex items-center w-[280px] h-[44px] bg-white border-t border-[#CFCFCF] rounded-md shadow-sm"
              style={{
                padding: '12px 16px',
                boxShadow:
                  '0px 1px 2px 0px #00000026, inset 0px -4px 4px 0px #0000001A',
              }}
            >
              {/* Ícone à Esquerda */}
              <img
                src={facebookIcon}
                alt="Facebook Icon"
                className="w-[20px] h-[20px] mr-2"
              />
              {/* Texto do Botão */}
              <span className="text-[14px] font-medium font-inter leading-[20px] text-[#374151]">
                Regístrate con Facebook
              </span>
            </button>
            <div className="mt-4 text-sm text-gray-500 text-center">
              <p className="text-gray-500 text-center font-mont">
                Ya tienes una cuenta?{' '}
                <button
                  onClick={handleLoginNavigate}
                  className="text-[#099E65] underline bg-transparent border-none p-0 cursor-pointer"
                  style={{
                    fontFamily: 'inherit',
                  }}
                >
                  {' '}
                  Inicia sesíon
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
