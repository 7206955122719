// import { User } from 'firebase/auth';
import React, { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { User } from '../../../@types/usersTypes';
import closeIcon from '../../../assets/close.svg';
import CVC from '../../../assets/creditCardCvc.png';
import CountryFlag from 'react-country-flag';
import GreenButton from '../../UI/GreenButton';
import Input from '../../UI/Input';
import countries from '../../../constants/countries.json';

interface PaymentSettingsProps {
  user: User | null;
  setActivePage: (page: string) => void;
}

const paymentsMock: {
  processorName: string;
  digits: string;
  method: string;
  expirationDate: string;
}[] = [
  {
    processorName: 'Visa',
    digits: '7777',
    method: 'Crédito',
    expirationDate: '10/1010',
  },
  {
    processorName: 'Master',
    digits: '8888',
    method: 'Crédito',
    expirationDate: '11/1111',
  },
  {
    processorName: 'Visa',
    digits: '9999',
    method: 'Crédito',
    expirationDate: '12/1212',
  },
];

const PaymentSettings: React.FC<PaymentSettingsProps> = () => {
  const [paymentCountry, setPaymentCountry] = useState('');
  const [showModal, setShowModal] = useState(false);
  const countryOptions = Object.keys(countries as Record<string, string>).map(
    (code) => ({
      value: code,
      label: countries[code as keyof typeof countries],
    })
  );

  const handleCountryChange = (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    if (selectedOption) {
      setPaymentCountry(selectedOption.label);
    }
  };
  return (
    <div className="flex flex-col max-w-[1160px] h-full mt-4 gap-6">
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col gap-4 absolute w-[432px] bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                <p className="font-nunito font-bold text-[18px] leading-[18px] text-[#0F172A]">
                  Agregar nuevo método
                </p>
              </h4>
              <button onClick={() => setShowModal(false)}>
                <img src={closeIcon} alt="Close Icon" width="24" height="24" />
              </button>
            </div>
            <div>
              <Input
                name="card-number"
                label="Número de Tarjeta *"
                width="384px"
                height="47px"
                placeholder="1234 1234 1234 1234"
              />
            </div>
            <div className="flex gap-4">
              <Input
                name="expiration"
                label="Fecha de expiración *"
                width="184px"
                height="47px"
                placeholder="MM / YY"
              />
              <Input
                name="CVC"
                label="CVC *"
                width="184px"
                height="47px"
                placeholder="CVC"
              />
              <img
                src={CVC}
                alt="CVC Icon"
                width="24"
                height="24"
                className="relative right-[45px] top-[35px] h-[24px] w-[24px]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="country"
                className="h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#334155] opacity-100 font-nunito"
              >
                País *
              </label>
              <Select
                id="country"
                name="country"
                options={countryOptions}
                onChange={handleCountryChange}
                value={countryOptions.find(
                  (option) => option.label === paymentCountry
                )}
                formatOptionLabel={(option) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CountryFlag
                      countryCode={option.value}
                      svg
                      style={{ marginRight: '8px' }}
                    />
                    {option.label}
                  </div>
                )}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '384px',
                    height: '47px',
                    backgroundColor: 'white',
                    border: '1px solid #D1D5DB',
                    borderRadius: '8px',
                    boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    marginTop: '0.25rem',
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontFamily: 'Mont, sans-serif',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#334155',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#9CA3AF',
                    fontFamily: 'Mont, sans-serif',
                    fontSize: '14px',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? '#34D399' : 'white',
                    color: state.isSelected ? 'white' : '#334155',
                    padding: '8px 16px',
                    '&:hover': {
                      backgroundColor: '#E5E7EB',
                    },
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#64748B',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#334155',
                  }),
                }}
                placeholder="Seleccione su país"
              />
            </div>
            <GreenButton width="384px" height="44px" onClick={() => {}}>
              Guardar cambios
            </GreenButton>
            <button
              className="w-[384px] h-[40px] border-2 text-red-500 border-red-500 bg-white rounded-md font-mont font-bold text-[14px] leading-[20px] hover:bg-red-200"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      {/* Main Content */}
      <div className="bg-[#F3F4F6]">
        <p className="font-mont text-[30px] text-[#0F172A] font-extrabold leading-[38.34px]">
          Ajustes de cuenta
        </p>
        <p className="font-mont text-[14px] text-[#94A3B8] font-semibold leading-[20px]">
          Administra tus ajustes
        </p>
      </div>
      <div className="bg-white rounded-2xl p-8 gap-6">
        <div>
          <p className="font-nunito text-[24px] text-[#000000] font-semibold leading-[32.74px]">
            Métodos de Pago
          </p>
          <p className="font-nunito text-[14px] text-[#94A3B8] font-normal leading-[20px]">
            Administra tus métodos de pago para una experiencia de compra más
            rápida y segura.
          </p>
        </div>
        <div>
          <table className="w-full my-4">
            <thead>
              <tr className="font-mont text-[#334155] text-sm font-bold text-left p-3">
                <th className="w-[221.6px] h-[44px]">Tarjeta</th>
                <th className="w-[221.6px] h-[44px]">Terminada en</th>
                <th className="w-[221.6px] h-[44px]">Tipo</th>
                <th className="w-[221.6px] h-[44px]">Fecha de Expiración</th>
              </tr>
            </thead>
            <tbody>
              {paymentsMock.map((item) => {
                return (
                  <tr
                    key={`${item.processorName}-${item.method}-${item.digits}`}
                    className="text-sm font-normal text-[#64748B] text-left p-3 border-b border-solid border-[#E5E7EB]"
                  >
                    <td className="w-[221.6px] h-[44px]">
                      <p className="font-mont">{item.processorName}</p>
                    </td>
                    <td className="w-[221.6px] h-[44px]">
                      <p className="font-mont">{item.digits}</p>
                    </td>
                    <td className="w-[221.6px] h-[44px]">
                      <p className="font-mont">{item.method}</p>
                    </td>
                    <td className="w-[221.6px] h-[44px]">
                      <p className="font-mont">{item.expirationDate}</p>
                    </td>
                    <td className="w-[221.6px] h-[44px] text-center">
                      <button className="font-mont text-[#059669] font-bold">
                        Editar
                      </button>
                    </td>
                    <td className="w-[221.6px] h-[44px] text-center">
                      <button className="font-mont text-[#E11D48] font-bold">
                        Eliminar
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button
          onClick={() => setShowModal(true)}
          className="font-mont font-bold text-[14px] leading-[20px] py-3 w-[191px] h-[44px] bg-[#34D399] text-white rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition"
        >
          Agregar nuevo método
        </button>
      </div>
      <div className="flex flex-col justify-around bg-white rounded-2xl p-8 gap-6 h-[197px]">
        <p className="font-nunito text-[24px] text-[#000000] font-semibold leading-[32.74px]">
          Autenticación en Dos Pasos (2FA)
        </p>
        <p className="font-nunito text-[14px] text-[#94A3B8] font-normal leading-[20px]">
          Activa la autenticación en dos pasos (2 Factor Authentication) para
          mayor seguridad en tu cuenta.
        </p>
        <button className="font-mont font-bold text-[14px] leading-[20px] py-3 w-[191px] h-[44px] bg-[#34D399] text-white rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition">
          Activar 2FA
        </button>
      </div>
    </div>
  );
};

export default PaymentSettings;
