import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import loginImage from '../../../assets/login.png';
import googleIcon from '../../../assets/Google.svg';
import facebookIcon from '../../../assets/facebook.svg';
import LoadingSpinner from '../../Loading/Spinner/Spinner';
import { loginUser } from '../../../service/auth/user/loginUser.service';
import { loginWithGoogle } from '../../../service/auth/loginGoogle/loginGoogle.service';
import { Eye } from 'solar-icon-set';
import * as SolarIconSet from 'solar-icon-set';
import GreenButton from '../../UI/GreenButton';
import Input from '../../UI/Input';
import axios from 'axios';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    setError('');
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await loginUser(email, password);
      localStorage.setItem('authToken', response.token);
      localStorage.setItem('userId', response.userId);

      if (response.token && response.userId && response.redirectTo) {
        navigate(`${response.redirectTo}`);
      } else {
        setError('Email o password incorreto.');
        return;
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 403:
            setError('Email o password incorreto');
            break;
          case 401:
            setError('Usuario no encontrado. Por favor, regístrese primero.');
            break;
          case 500:
            setError(
              'Error interno del servidor. Por favor, inténtelo más tarde.'
            );
            break;
          default:
            setError(
              'Ocurrió un error inesperado. Por favor, inténtelo de nuevo.'
            );
        }
      } else {
        console.error('Erro ao registrar:', error);
        setError('Ocurrió un error al registrarse, inténtalo más tarde');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handler para login com Google
  const handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      const result = await loginWithGoogle();
      const { token, redirectTo, userId } = result;
      if (token && userId && redirectTo) {
        localStorage.setItem('authToken', token);
        localStorage.setItem('userId', userId);
        navigate(redirectTo);
      } else {
        setError('Erro ao fazer login com o Google!');
        return;
      }
    } catch (error) {
      console.error('Erro ao fazer login com Google:', error);
      setError('Erro ao fazer login com o Google!');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordForgot = () => {
    navigate('/forgot-password');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen max-h-[890px] w-screen flex items-center justify-center overflow-hidden">
      <div className="flex w-full h-full items-center justify-center">
        {/* Contêiner Principal */}
        <div className="flex justify-center mx-auto max-h-[890px]">
          {/* Área da Imagem */}
          <div className="hidden md:flex items-center justify-center max-h-[890px] overflow-hidden">
            <img
              src={loginImage}
              alt="Descrição da imagem"
              className="object-cover w-[897.87px] max-h-[890px] h-auto opacity-100"
            />
          </div>

          {/* Área ao lado da Imagem */}
          <div className="flex flex-col items-start justify-between w-full max-w-[629px] h-full max-h-[890px] opacity-100">
            {/* Logo e Formulário */}
            <div className="flex flex-col items-center w-full h-full">
              {/* Logo */}
              <img
                src={logo}
                alt="Logo"
                className="max-w-full w-[75.16px] h-[80px] mb-[36px]"
              />

              {/* Formulário de Login */}
              <div className="flex flex-col items-center w-full max-h-[750px] overflow-y-auto">
                <div className="flex flex-col items-center w-full gap-[8px] mb-10">
                  <h2 className="text-[36px] font-extrabold leading-[36px] text-center text-[#0F172A] font-mont">
                    ¡Hola de nuevo!
                  </h2>
                  <p className="text-[16px] font-bold leading-[19.2px] text-center text-[#334155] font-nunito">
                    Inicia sesión y retomemos esas clases
                  </p>
                </div>

                <form
                  className="w-full flex flex-col items-center gap-[8px]"
                  onSubmit={handleLogin}
                >
                  <div className="mb-1 flex flex-col">
                    <Input
                      type="text"
                      name="Email"
                      label="Email"
                      width="340px"
                      height="44px"
                      placeholder="Escribe tu Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-10 flex flex-col">
                    <div className="relative flex flex-col">
                      <Input
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        name="Password"
                        width="340px"
                        height="44px"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword((prev) => !prev)}
                        className="absolute top-8 right-0 flex items-center pr-3 text-[#94A3B8]"
                      >
                        <Eye className="w-5 h-5 mt-1" />
                      </button>
                    </div>
                  </div>
                  {error && (
                    <div
                      className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner mb-10"
                      style={{
                        boxShadow:
                          '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A', // Sombra do alerta
                      }}
                      role="alert"
                    >
                      <SolarIconSet.DangerCircle
                        iconStyle="BoldDuotone"
                        size={18}
                        color="#EF4444"
                      />

                      {/* Texto de erro */}
                      <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                        {error}
                      </span>
                    </div>
                  )}

                  <GreenButton type="submit" width="253px" height="52px">
                    <span className="whitespace-nowrap font-mont mr-[6px]">
                      INICIA SESIÓN
                    </span>
                    <SolarIconSet.RoundAltArrowRight
                      size={20}
                      iconStyle="Bold"
                    />
                  </GreenButton>

                  <div className="mb-[72px] text-center">
                    <p className="text-[#6B7280] text-center font-mont text-[12px]">
                      Olvidaste tu Password?{' '}
                      <button
                        onClick={handlePasswordForgot}
                        className="text-[#099E65] underline bg-transparent border-none p-0 cursor-pointer font-mont"
                      >
                        Haz click aqui
                      </button>
                    </p>
                  </div>
                </form>

                {/* PARA INCIAR SESSAO COM GOOGLE E FACEBOOKS */}
                <div className="flex items-center w-[306px] gap-0 mb-6">
                  <hr className="flex-grow border-t border-gray-300" />
                  <span className="px-4 text-[14px] font-medium font-nunito leading-[20px] text-[#9CAFA5]">
                    o inicia sesión con
                  </span>
                  <hr className="flex-grow border-t border-gray-300" />
                </div>
                <div className="flex flex-col items-center">
                  {/* Botão Login con Google */}
                  <button
                    className="flex items-center w-[306px] h-[44px] bg-white border-t border-[#CFCFCF] rounded-md shadow-sm mb-4"
                    style={{
                      padding: '12px 16px',
                      boxShadow:
                        '0px 1px 2px 0px #00000026, inset 0px -4px 4px 0px #0000001A',
                    }}
                    onClick={handleGoogleLogin}
                  >
                    {/* Ícone à Esquerda */}
                    <img
                      src={googleIcon}
                      alt="Google Icon"
                      className="w-[20px] h-[20px] mr-2"
                    />
                    {/* Texto do Botão */}
                    <span className="text-[14px] font-medium leading-[20px] text-[#374151] font-inter">
                      Login con Google
                    </span>
                  </button>

                  {/* Botão Login con Facebook */}
                  <button
                    className="flex items-center w-[306px] h-[44px] bg-white border-t border-[#CFCFCF] rounded-md shadow-sm mb-6"
                    style={{
                      padding: '12px 16px',
                      boxShadow:
                        '0px 1px 2px 0px #00000026, inset 0px -4px 4px 0px #0000001A',
                    }}
                  >
                    {/* Ícone à Esquerda */}
                    <img
                      src={facebookIcon}
                      alt="Facebook Icon"
                      className="w-[20px] h-[20px] mr-2"
                    />
                    {/* Texto do Botão */}
                    <span className="text-[14px] font-medium leading-[20px] text-[#374151] font-inter">
                      Login con Facebook
                    </span>
                  </button>
                  <div className="text-center">
                    <p className="text-[#6B7280] text-center font-mont text-[12px]">
                      Aún no tienes una cuenta?{' '}
                      <button
                        onClick={handleRegister}
                        className="text-[#099E65] underline bg-transparent border-none p-0 cursor-pointer"
                        style={{
                          fontFamily: 'inherit',
                        }}
                      >
                        Regístrate aquí
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
