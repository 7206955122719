import { spainIdType, validNIE } from 'spain-id';

export const NIE_REGEX = /^[XYZxyz]\d{7,8}[A-Za-z]$/;

export const validateNie = (nieString: string | undefined): boolean => {
  try {
    if (!nieString) {
      return false;
    }

    const idType = spainIdType(nieString);

    if (!idType) {
      return false;
    }

    if (idType.toLowerCase() !== 'nie') {
      return false;
    }

    return validNIE(nieString);
  } catch (error) {
    console.log(error);
    return false;
  }
};
