import { spainIdType, validDNI } from 'spain-id';

export const DNI_REGEX = /^(\d{8})([A-Za-z])$/;

export const validateDni = (dniString: string | undefined): boolean => {
  try {
    if (!dniString) {
      return false;
    }

    const idType = spainIdType(dniString);

    if (!idType) {
      return false;
    }

    if (idType.toLowerCase() !== 'dni') {
      return false;
    }

    return validDNI(dniString);
  } catch (error) {
    console.log(error);
    return false;
  }
};
