import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../@types/usersTypes';
import { getUser } from '../../service/auth/user/getUser.service';
import LoadingSpinner from '../Loading/Spinner/Spinner';
import axios from 'axios';
import UnderReview from './Phases/UnderReview';
import Interview from './Phases/Interview';
import Rejected from './Phases/Rejected';
import Academy from './Phases/Academy/Academy';
import Portal from './Phases/Portal/Portal';

const TeacherPortal: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('authToken');

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      if (userId) {
        const response = await getUser(userId);
        setUser(response);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);

      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchUserData();
  }, [token, userId, navigate]);

  useEffect(() => {
    if (user) {
      if (user.role !== 'TEACHER') {
        navigate('/portal');
      }
    }
  }, [user, navigate]);

  const renderContent = () => {
    switch (user?.Teacher?.selectionPhase) {
      case 'UNDER_REVIEW':
        return <UnderReview />;
      case 'INTERVIEW':
        return <Interview />;
      case 'REJECTED':
        return <Rejected />;
      case 'ACADEMY':
        return <Academy />;
      case 'APPROVED':
        return <Portal />;
      default:
        return null;
    }
  };

  return isLoading ? <LoadingSpinner /> : renderContent();
};

export default TeacherPortal;
