import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../../firebase';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const loginWithGoogle = async () => {
  try {
    // Fazer login com o Google usando Firebase
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    // Obter o ID Token do Firebase
    const idToken = await user.getIdToken();

    // Enviar o ID Token para o backend
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/auth/google-login`,
      {
        idToken, // Enviar o ID Token do Firebase
      }
    );

    // Agora você tem o token JWT e pode armazená-lo no localStorage
    return response.data;
  } catch (error) {
    // Verifica se o erro é um erro do Axios
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.status === 404) {
        alert(
          'No account associated with this Google account. Please sign up first.'
        );
      } else {
        console.error(
          'Erro ao fazer login com Google:',
          error.response?.data || error.message
        );
      }
    } else {
      console.error('Erro desconhecido:', error);
    }
  }
};
