import React from 'react';

interface IGreenButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  width: string;
  height: string;
}

const GreenButton = ({ children, width, height, ...props }: IGreenButton) => {
  return (
    <button
      className="whitespace-nowrap flex items-center font-mont justify-center py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition"
      style={{
        boxShadow: '0px 1px 2px 0px #0000000D',
        width: width,
        height: height,
      }}
      {...props}
    >
      {children}
    </button>
  );
};

export default GreenButton;
