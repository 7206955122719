import React, { useState } from 'react';
import { User } from '../../../../@types/usersTypes';
import closeIcon from '../../../../assets/close.svg';
import dashboardBG from '../../../../assets/dashboardBG.jpeg';
import YouTube, { YouTubeProps } from 'react-youtube';
import thumbsUp from '../../../../assets/thumbs-up.png';
import headphone from '../../../../assets/Headphone.png';
import explanationVideo from '../../../../assets/explanationVideo.png';
import * as SolarIconSet from 'solar-icon-set';

interface DashboardProps {
  user: User | null;
  setActivePage: (page: string) => void;
}

const Dashboard: React.FC<DashboardProps> = ({ user, setActivePage }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '630',
    width: '985',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div className="flex max-w-[1160px] h-full overflow-hidden">
      {showVideoModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col gap-4 absolute bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                <p className="font-nunito font-bold text-[18px] leading-[18px] text-[#0F172A]">
                  Descubra tu primer clase!
                </p>
              </h4>
              <button onClick={() => setShowVideoModal(false)}>
                <img src={closeIcon} alt="Close Icon" width="24" height="24" />
              </button>
            </div>
            <YouTube
              videoId="ez8m4PXksQs"
              opts={opts}
              onReady={onPlayerReady}
            />
          </div>
        </div>
      )}
      <div className="flex-grow bg-[#F3F4F6] overflow-y-auto overflow-x-hidden w-[1160px] mx-auto mb-10">
        <div>
          <section
            className="relative flex gap-10 bg-cover bg-center p-5 mt-7 rounded-2xl max-w-[1160px] max-h-[355.54px]"
            style={{ backgroundImage: `url(${dashboardBG})` }}
          >
            <>
              <div className="w-[424px] h-[307px] flex flex-col justify-center space-y-4">
                <h2 className="text-[40px] font-black font-nunito text-[#0F172A] leading-10">
                  ¡{user?.fullName.split(' ')[0]}, bienvenida
                  <br />a Pitinglish!
                </h2>
                <p>
                  Estamos emocionados de que formes parte de nuestra <br />
                  comunidad. Ahora que has pasado la entrevista, es hora
                  <br />
                  de prepararte para ser parte del equipo de Pitinglish. <br />
                  Mira los videos para aprender nustra metodología y <br />
                  completa el quiz para confirmar que estás lista.
                </p>
                <button
                  className="mt-4 bg-white text-[#059669] border border-[#059669] font-bold px-6 py-3 rounded-2xl w-fit shadow-md"
                  onClick={() => setActivePage('Videos')}
                >
                  Ver videos
                </button>
              </div>
              <img
                src={thumbsUp}
                alt="Thumbs Up"
                className="absolute right-[-20px] bottom-0 w-[459.5px] h-[393.72px]"
              />
            </>
          </section>
          <div className="flex flex-col gap-4 mt-10 justify-center max-w-[1160px] mx-auto">
            <h2 className="font-bold leading-[32.74px] text-[24px] text-[#64748B] font-nunito ml-4">
              Prepárate para la primera clase
            </h2>
            {/* Primeira section com 3 cards */}
            <section className="flex gap-1 justify-center w-full">
              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-[312px] mb-3">
                  {/* Ícone de número 1 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
          0px 4px 20px 0px #FFFAFA40 inset, 
          0px -4px 20px 0px #00000040 inset, 
          0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    1
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] h-[32px] leading-[19.2px]">
                    Mira el video para ver cómo será tu <br /> primera clase
                  </h3>
                </div>

                {/* Imagem do vídeo */}
                <button
                  onClick={() => setShowVideoModal(true)}
                  className="w-[286.84] h-[166px]"
                >
                  <img
                    src={explanationVideo}
                    alt="Video"
                    className="w-full h-[166px] rounded-2xl mb-8"
                  />
                </button>
              </div>

              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6 relative">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full">
                  {/* Ícone de número 2 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    2
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Prueba tu cámara <br /> y micrófono
                  </h3>
                </div>

                {/* Contêiner para o texto e a imagem */}
                <div className="relative">
                  {/* Descrição */}
                  <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal pr-[120px]">
                    El aula solicitará acceso a su cámara y micrófono para
                    proporcionar comunicación entre el maestro y el niño durante
                    la lección.
                  </p>

                  {/* Imagem ao lado direito do texto com posição absoluta */}
                  <img
                    src={headphone}
                    alt="Cámara"
                    className="w-[166.32px] h-[166.32px] rounded-2xl absolute top-[-40px] right-[-20px]"
                  />
                </div>

                {/* Botão */}
                <button className="mt-4 py-2 px-4 text-[#059669] border-2 border-[#059669] rounded-2xl font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors">
                  Haz el chequeo
                </button>
              </div>
            </section>
          </div>
          {/* Two Column Section */}
          <section className="flex gap-6 max-w-[1160px] h-[283px] justify-center items-center mx-auto mt-10">
            {/* Seção de Perguntas Frequentes */}
            <div className="w-full h-[283px]">
              <h2 className="font-bold leading-[32.74px] text-[24px] text-[#64748B] font-nunito mb-4">
                Preguntas frecuentes de los profes
              </h2>
              <div className="flex flex-col gap-2">
                {/* Pergunta 1 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px] ">
                    Pregunta 1
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 2 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    Pregunta 2
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 3 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    Pregunta 3
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 4 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer mb-[40px] border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    Pregunta 4
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
