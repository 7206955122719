import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../Loading/Spinner/Spinner';
import Sidebar from './Sidebar';
import axios from 'axios';
import { getUser } from '../../../../service/auth/user/getUser.service';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../@types/usersTypes';
import Dashboard from './Dashboard';
import Header from './Header';
import Settings from '../../Settings/Settings';
import AccountSettings from '../../AccountSettings/AccountSettings';
import Videos from './Videos';
import Account from '../../../Portal/Account/Account';

const Academy: React.FC = () => {
  const [activePage, setActivePage] = useState('Dashboard');
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        if (userId) {
          const response = await getUser(userId);
          setUser(response);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);

        if (axios.isAxiosError(error) && error.response?.status === 401) {
          localStorage.removeItem('authToken');
          localStorage.removeItem('userId');
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [token, userId, navigate]);

  const renderContent = () => {
    switch (activePage) {
      case 'Dashboard':
        return <Dashboard user={user} setActivePage={setActivePage} />;
      // case 'Calendar':
      //   return <Calendar user={user} />;
      case 'Settings':
        return <Settings user={user} setActivePage={setActivePage} />;
      case 'Videos':
        return <Videos user={user} setActivePage={setActivePage} />;
      case 'Account':
        return <Account user={user} setActivePage={setActivePage} />;
      // case 'PaymentSettings':
      //   return <PaymentSettings user={user} setActivePage={setActivePage} />;
      // case 'Raiting':
      //   return <Raiting user={user} setActivePage={setActivePage} />
      case 'AccountSettings':
        return <AccountSettings user={user} setActivePage={setActivePage} />;

      default:
        return <Dashboard user={user} setActivePage={setActivePage} />;
    }
  };
  return (
    <div className="relative flex max-w-[1416px] w-full mx-auto h-screen">
      {isLoading && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{ backgroundColor: '#0F172A99' }} // Cor escurecida com opacidade
        >
          <LoadingSpinner />
        </div>
      )}
      {/* Sidebar */}
      <Sidebar setActivePage={setActivePage} activePage={activePage} />
      <div className="flex-grow p-5 bg-[#F3F4F6] overflow-y-hidden w-full">
        <Header
          user={user}
          activePage={activePage}
          setActivePage={setActivePage}
        />
        {renderContent()}
      </div>
    </div>
  );
};

export default Academy;
