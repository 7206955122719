import React from 'react';
import {
  FaUser,
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTiktok,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const LegalAdvice: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };
  return (
    <div>
      {/* Top Bar */}
      <div className="flex justify-end items-center p-2.5 bg-[#6DD2BF]">
        <div className="flex items-center space-x-5">
          <button
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
            onClick={handleLoginRedirect}
          >
            <FaUser className="mr-2" /> Iniciar Sesión
          </button>
          <a
            href="https://wa.me/34622713212"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
          >
            <FaWhatsapp className="mr-2" /> Whatsapp
          </a>
          <a
            href="https://www.linkedin.com/company/e-pitinglish/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.instagram.com/epitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/ePitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.tiktok.com/@epitinglish"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaTiktok />
          </a>
        </div>
      </div>

      {/* Header Menu */}
      <div className="flex justify-between items-center p-4 bg-[#6DD2BF]">
        <div className="flex items-center">
          <img src="/logo.jpeg" alt="Logo" className="h-12" />
        </div>
        <div className="flex justify-around items-center space-x-8">
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            NUESTRA METODOLOGIA
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PROFESORES
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PRECIOS
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            BLOG
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            CONTACTO
          </button>
        </div>
      </div>
      {/* New Section */}
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-4 text-[#333]">Aviso legal</h1>
        <p className="mb-4 text-[#444] leading-7">
          De conformidad con la Ley 34/2002, de Servicios de la Sociedad de la
          Información y de Comercio Electrónico, le informamos que esta web es
          titularidad de:
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Identidad: e-Pitinglish S.L (en adelante “EL TITULAR”)
        </p>
        <p className="mb-4 text-[#444] leading-7">CIF: B09703067</p>
        <p className="mb-4 text-[#444] leading-7">
          Domicilio: c. Lluís Domènech i Montaner, 6, esc. 7, 4-B
        </p>
        <p className="mb-4 text-[#444] leading-7">Teléfono: 671684568</p>
        <p className="mb-4 text-[#444] leading-7">
          Correo electrónico: hello@e-pitinglish.com
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          PROPIEDAD INTELECTUAL E INDUSTRIAL
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Los contenidos de este sitio, incluyendo los textos, imágenes y
          diseños gráficos, pertenecen a EL TITULAR, o a terceros que han
          autorizado su uso. EL TITULAR presenta estos contenidos con fines de
          información y promoción. EL TITULAR autoriza su utilización
          exclusivamente con estas finalidades. Cualquier utilización de estos
          diseños, imágenes o textos deberá citar expresamente su pertenencia a
          EL TITULAR, quien se reserva el derecho a iniciar las acciones legales
          oportunas para reparar los daños
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          EXCLUSIÓN DE RESPONSABILIDAD
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          EL TITULAR actúa con la máxima diligencia posible para que los datos y
          la información que ofrece en su sitio web esté actualizada en tomo
          momento, si bien no garantiza ni se hace responsable de la exactitud y
          actualización de los contenidos del sitio web, reservándose el derecho
          a modificar dichos contenidos en cualquier momento. EL TITULAR tampoco
          será responsable de la información que se pueda obtener a través de
          enlaces incluidos en el sitio web.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Las relaciones comerciales con los clientes se regirán por las
          Condiciones Generales que, en su caso, se establezcan por EL TITULAR
          en un documento específico a tal efecto, o por los pactos concretos
          que pudieran acordarse con los clientes.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          CONDICIONES DE USO
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          El uso de este sitio web implica la aceptación plena de los términos
          del presente aviso legal. Los posibles conflictos relativos a esta web
          se regirán exclusivamente por el derecho del Estado Español. Toda
          persona usuaria de la web, independientemente de la jurisdicción
          territorial desde la cual se produzca su acceso, acepta el
          cumplimiento y respeto de estas cláusulas con renuncia expresa a
          cualquier otro fuero que pudiera corresponderle, sin perjuicio de los
          derechos que legalmente correspondan al usuario, en caso de que este
          ostente la condición de consumidor.
        </p>
      </div>
    </div>
  );
};

export default LegalAdvice;
