import React, { useState } from 'react';
import * as SolarIconSet from 'solar-icon-set';
import { TeacherProfile } from '../../../@types/usersTypes'; // Ajuste conforme o caminho dos seus tipos
import closeIcon from '../../../assets/close.svg';

interface TeacherListProps {
  teachers: TeacherProfile[];
  setTeachers: React.Dispatch<React.SetStateAction<TeacherProfile[]>>;
  childTeacher: TeacherProfile | null | undefined;
}

const TeacherList: React.FC<TeacherListProps> = ({
  teachers,
  setTeachers,
  childTeacher,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Controla a visibilidade do modal
  const [isFiltered, setIsFiltered] = useState(false);
  const [activeTeacher, setActiveTeacher] = useState('');

  // Filtra o professor da criança da lista de professores e coloca ele no topo, se houver
  const sortedTeachers = childTeacher
    ? teachers.sort((a, b) => {
        if (a.Teacher.id === childTeacher.id) return -1;
        if (b.Teacher.id === childTeacher.id) return 1;
        return 0;
      }) // Reordena a lista de acordo com o professor que está selecionado
    : teachers; // Se não houver childTeacher, usa a lista de professores normal

  const getClasses = (id: string) => {
    if (id === childTeacher?.id) {
      return 'bg-[#059669] text-white';
    } else if (id === activeTeacher) {
      return 'bg-emerald-500 text-white bg-opacity-10';
    } else {
      return 'bg-white text-[#0F172A]';
    }
  };

  return (
    <div className="relative w-[252px] h-[476px] bg-white mr-[20px] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] p-4">
      {/* Cabeçalho com o título e o ícone */}
      <div className="flex justify-between items-center mb-6 mx-auto w-[208px] h-[24px]">
        <h3 className="text-[#334155] font-nunito font-bold text-[16px] leading-[19.2px]">
          Elige un profesor
        </h3>
        <button className="mt-1" onClick={() => setIsModalOpen(!isModalOpen)}>
          <SolarIconSet.Filter size={24} color="#059669" />
        </button>
      </div>

      {/* Lista de professores */}
      <div className="overflow-y-auto w-[208px] h-[372px] mx-auto no-scrollbar">
        {sortedTeachers.map((teacher) => (
          <button
            key={teacher.id}
            className={`w-[208px] h-[52px] ${getClasses(teacher.Teacher.id)} border border-[#E5E7EB] border-l-[#059669] border-l-4 shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_-1px_rgba(0,0,0,0.1)] rounded-lg mb-2 p-2 flex items-center gap-2 cursor-pointer`}
            onClick={() => {
              if (isFiltered) {
                setIsFiltered(false);
                setTeachers(sortedTeachers);
                setActiveTeacher('');
              } else {
                setIsFiltered(true);
                setTeachers((prevState) =>
                  prevState.filter((item) => item.id === teacher.id)
                );
                setActiveTeacher(teacher.Teacher.id);
              }
            }}
          >
            {/* Imagem do professor à esquerda */}
            <img
              src={teacher.user?.profilePicture || teacher.profilePicture || ''}
              alt={`Foto de ${teacher.fullName}`}
              className="w-10 h-10 rounded-full object-cover"
            />

            {/* Informações do professor à direita da foto */}
            <div className="flex flex-col items-baseline">
              <h4
                className={`font-nunito font-bold text-[12px] leading-[16.37px] ${
                  teacher.Teacher.id === childTeacher?.id
                    ? 'text-white'
                    : 'text-[#065F46]'
                }`}
              >
                {teacher.fullName}
              </h4>
              <p
                className={`font-nunito font-normal text-[10px] leading-[10px] ${
                  teacher.Teacher.id === childTeacher?.id
                    ? 'text-white'
                    : 'text-[#374151]'
                }`}
              >
                <SolarIconSet.MapPoint
                  size={9}
                  color={
                    teacher.id === childTeacher?.id ? '#ffffff' : '#374151'
                  }
                />{' '}
                {teacher.countryOfResidence ||
                  teacher.Teacher?.countryOfResidence}
              </p>
            </div>
          </button>
        ))}
      </div>

      {/* Modal - Abre ao clicar no filtro */}
      {isModalOpen && (
        <div className="absolute top-[0px] left-[251px] w-[386px] h-[530px] bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
          {/* Cabeçalho */}
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
              Filtros
            </h4>
            <button onClick={() => setIsModalOpen(false)}>
              <img src={closeIcon} alt="Close Icon" width="24" height="24" />
            </button>
          </div>

          {/* Input de texto para buscar professor */}
          <div className="relative mb-4">
            <SolarIconSet.Magnifer
              size={14}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
            <input
              type="text"
              placeholder="Buscar por nombre"
              className="w-full pl-10 pr-4 py-2 border font-mont font-normal text-[14px] leading-[20px] text-[#64748B] border-[#E5E7EB] rounded-md focus:outline-none focus:ring-2 focus:ring-[#059669] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_-1px_rgba(0,0,0,0.1)]"
            />
          </div>
          <hr className="flex-grow w-[338px] border-t border-[#F3F4F6] mt-6 mb-6" />

          {/* Inputs Select */}
          <div className="space-y-4 mb-6">
            <select className="w-[338px] font-mont font-normal text-[14px] leading-[20px] test-[#64748B] h-[36px] px-2 border border-[#E5E7EB] rounded-md shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_-1px_rgba(0,0,0,0.1)]">
              <option>Tipo de Profesor</option>
            </select>
            <select className="w-[338px] font-mont font-normal text-[14px] leading-[20px] test-[#64748B] h-[36px] px-2 border border-[#E5E7EB] rounded-md shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_-1px_rgba(0,0,0,0.1)]">
              <option>Nivel</option>
            </select>
            <select className="w-[338px] font-mont font-normal text-[14px] leading-[20px] test-[#64748B] h-[36px] px-2 border border-[#E5E7EB] rounded-md shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_-1px_rgba(0,0,0,0.1)]">
              <option>Género</option>
            </select>
            <select className="w-[338px] font-mont font-normal text-[14px] leading-[20px] test-[#64748B] h-[36px] px-2 border border-[#E5E7EB] rounded-md shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_-1px_rgba(0,0,0,0.1)]">
              <option>Acento</option>
            </select>
            <select className="w-[338px] font-mont font-normal text-[14px] leading-[20px] test-[#64748B] h-[36px] px-2 border border-[#E5E7EB] rounded-md shadow-[0px_1px_3px_0px_rgba(0,0,0,0.1),0px_1px_2px_-1px_rgba(0,0,0,0.1)]">
              <option>Personalidad</option>
            </select>
          </div>

          {/* Botão para aplicar filtros */}
          <button className="font-mont items-center justify-center w-[338px] h-[44px] px-[32px] bg-[#34D399] text-white font-bold text-[14px] leading-[20px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition mb-4">
            Aplicar Filtros
          </button>

          {/* Botão para borrar filtros */}
          <button className="w-[338px] h-[44px] text-[14px] leading-[20px] font-mont bg-white text-[#EF4444] border-2 border-[#EF4444] hover:bg-[#EF4444] hover:text-[#FFFFFF] py-2 rounded-md font-bold ">
            Borrar Filtros
          </button>
        </div>
      )}
    </div>
  );
};

export default TeacherList;
