// import { User } from 'firebase/auth';
import React, { useState } from 'react';
import { Rating, Star } from '@smastrom/react-rating';
import { StudentProfile, User } from '../../../@types/usersTypes';
import Input from '../../UI/Input';
import Toast from '../../Toast';
import closeIcon from '../../../assets/close.svg';
import GreenButton from '../../UI/GreenButton';
import useToast from '../../../hooks/useToast';
import '@smastrom/react-rating/style.css';

interface FamilyProps {
  user: User | null;
  selectedChild: StudentProfile | null;
  setActivePage: (page: string) => void;
}

const startWidth = 180;

const customStyles = {
  itemShapes: Star,
  inactiveFillColor: '#ffe48b',
  activeFillColor: '#f5bc00',
};

const classesMock: {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  teacher: string;
  type: string;
  state: string;
}[] = [
  {
    id: '0',
    date: '01/01/0101',
    startTime: '07:00',
    endTime: '07:30',
    teacher: 'Marta',
    type: 'e-Class',
    state: 'Cancelada',
  },
  {
    id: '1',
    date: '02/02/0202',
    startTime: '08:00',
    endTime: '08:30',
    teacher: 'Silvia',
    type: 'e-Club',
    state: 'Finalizada',
  },
];

const Family: React.FC<FamilyProps> = () => {
  const [evaluation, setEvaluation] = useState({
    id: '',
    show: false,
    date: '',
    startTime: '',
    endTime: '',
    teacher: '',
    type: '',
    state: '',
  });
  const [evaluationValues, setEvaluationValues] = useState<{
    clarity: number;
    empathy: number;
    patience: number;
    pronunciation: number;
    punctuality: number;
  }>({
    clarity: 0,
    empathy: 0,
    patience: 0,
    pronunciation: 0,
    punctuality: 0,
  });
  const { toast, handleShowToast, handleHideToast } = useToast();

  const handleInputChange = (e: number, key: string) => {
    setEvaluationValues((prevValues) => ({
      ...prevValues,
      [key]: e,
    }));
  };

  return (
    <div className="mt-4 overflow-y-auto no-scrollbar max-h-[822px]">
      {toast.showToast && (
        <Toast
          message={toast.message}
          variant={toast.variant}
          description={toast.description}
          onClick={() => handleHideToast()}
        />
      )}
      {evaluation.show && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col gap-4 absolute w-[432px] bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
            {/* Modal Header */}
            <div className="flex justify-between items-center">
              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                <p className="font-nunito font-bold text-[18px] leading-[18px] text-[#0F172A]">
                  Evaluar a {evaluation.teacher}
                </p>
              </h4>
              <button
                onClick={() => {
                  setEvaluation({
                    id: '',
                    show: false,
                    date: '',
                    startTime: '',
                    endTime: '',
                    teacher: '',
                    type: '',
                    state: '',
                  });
                  setEvaluationValues({
                    clarity: 0,
                    empathy: 0,
                    patience: 0,
                    pronunciation: 0,
                    punctuality: 0,
                  });
                }}
              >
                <img src={closeIcon} alt="Close Icon" width="24" height="24" />
              </button>
            </div>
            <div className="flex flex-col">
              <p className="font-mont text-[14px] text-[#94A3B8] font-semibold leading-[20px]">
                {`Fecha y hora de la clase: ${evaluation.date} ${evaluation.startTime}`}
              </p>
              <p className="font-mont text-[14px] text-[#94A3B8] font-semibold leading-[20px]">
                {`Tipo de clase: ${evaluation.type}`}
              </p>
            </div>
            {/* Evaluations */}
            <div className="flex h-[40px] border-b border-solid border-[#E5E7EB] justify-between items-center">
              <p className="font-mont text-[14px] text-[#334155] font-normal leading-[20px]">
                Claridad
              </p>
              <div>
                <Rating
                  style={{ maxWidth: startWidth }}
                  value={evaluationValues.clarity}
                  items={5}
                  itemStyles={customStyles}
                  onChange={(e: number) => handleInputChange(e, 'clarity')}
                />
              </div>
            </div>
            <div className="flex h-[40px] border-b border-solid border-[#E5E7EB] justify-between items-center">
              <p className="font-mont text-[14px] text-[#334155] font-normal leading-[20px]">
                Empatía
              </p>
              <div>
                <Rating
                  style={{ maxWidth: startWidth }}
                  value={evaluationValues.empathy}
                  items={5}
                  itemStyles={customStyles}
                  onChange={(e: number) => handleInputChange(e, 'empathy')}
                />
              </div>
            </div>
            <div className="flex h-[40px] border-b border-solid border-[#E5E7EB] justify-between items-center">
              <p className="font-mont text-[14px] text-[#334155] font-normal leading-[20px]">
                Paciencia
              </p>
              <div>
                <Rating
                  style={{ maxWidth: startWidth }}
                  value={evaluationValues.patience}
                  items={5}
                  itemStyles={customStyles}
                  onChange={(e: number) => handleInputChange(e, 'patience')}
                />
              </div>
            </div>
            <div className="flex h-[40px] border-b border-solid border-[#E5E7EB] justify-between items-center">
              <p className="font-mont text-[14px] text-[#334155] font-normal leading-[20px]">
                Pronunciación
              </p>
              <div>
                <Rating
                  style={{ maxWidth: startWidth }}
                  value={evaluationValues.pronunciation}
                  items={5}
                  itemStyles={customStyles}
                  onChange={(e: number) =>
                    handleInputChange(e, 'pronunciation')
                  }
                />
              </div>
            </div>
            <div className="flex h-[40px] justify-between items-center">
              <p className="font-mont text-[14px] text-[#334155] font-normal leading-[20px]">
                Puntualidad
              </p>
              <div>
                <Rating
                  style={{ maxWidth: startWidth }}
                  value={evaluationValues.punctuality}
                  items={5}
                  itemStyles={customStyles}
                  onChange={(e: number) => handleInputChange(e, 'punctuality')}
                />
              </div>
            </div>
            <GreenButton width="384px" height="44px" onClick={() => {}}>
              Enviar
            </GreenButton>
            <button
              className="w-[384px] h-[40px] border-2 text-red-500 border-red-500 bg-white rounded-md font-mont font-bold text-[14px] leading-[20px] hover:bg-red-200"
              onClick={() => {
                setEvaluation({
                  id: '',
                  show: false,
                  date: '',
                  startTime: '',
                  endTime: '',
                  teacher: '',
                  type: '',
                  state: '',
                });
                setEvaluationValues({
                  clarity: 0,
                  empathy: 0,
                  patience: 0,
                  pronunciation: 0,
                  punctuality: 0,
                });
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      {/* Main Content */}
      <div className="flex flex-col gap-6">
        <div className="bg-[#F3F4F6]">
          <p className="font-mont text-[30px] text-[#0F172A] font-extrabold leading-[38.34px]">
            Mi Familia
          </p>
          <p className="font-mont text-[14px] text-[#94A3B8] font-semibold leading-[20px]">
            Administra la información de tu familia
          </p>
        </div>
        <div className="bg-white rounded-2xl p-8 gap-6">
          <div>
            <p className="font-nunito text-[24px] text-[#000000] font-semibold leading-[32.74px]">
              Información personal
            </p>
          </div>
          <div className="flex items-center gap-4 py-6">
            <Input
              name="name"
              label="Nombre Completo *"
              width="350px"
              height="44px"
              placeholder="Nombre completo"
            />
            <Input
              name="age"
              label="Edád *"
              width="350px"
              height="44px"
              placeholder="Edád"
            />
          </div>
          <button
            onClick={() =>
              handleShowToast(
                'success',
                'Teste titulo toast',
                'teste mensagem toast'
              )
            }
            className="font-mont font-bold text-[14px] leading-[20px] py-3 w-[191px] h-[44px] bg-[#34D399] text-white rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition"
          >
            Actualizar Datos
          </button>
        </div>
        <div className="flex flex-col bg-white rounded-2xl p-8 gap-6">
          <p className="font-nunito text-[24px] text-[#000000] font-semibold leading-[32.74px]">
            Perfil Académico
          </p>
          <div className="flex items-center gap-4">
            <div></div>
          </div>
          <button className="font-mont font-bold text-[14px] leading-[20px] py-3 w-[191px] h-[44px] bg-[#34D399] text-white rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition">
            Actualizar Perfil
          </button>
        </div>
        <div className="bg-white rounded-2xl p-8 gap-6">
          <div>
            <p className="font-nunito text-[24px] text-[#000000] font-semibold leading-[32.74px]">
              Historial de Clases
            </p>
          </div>
          <div>
            <table className="w-full my-4">
              <thead>
                <tr className="font-mont text-[#334155] text-sm font-bold text-left p-3">
                  <th className="w-[221.6px] h-[44px]">Fecha y Hora</th>
                  <th className="w-[221.6px] h-[44px]">Profesor</th>
                  <th className="w-[221.6px] h-[44px]">Tipo de clase</th>
                  <th className="w-[221.6px] h-[44px]">Estado de la clase</th>
                  <th className="w-[221.6px] h-[44px]">Evaluación</th>
                </tr>
              </thead>
              <tbody>
                {classesMock.map((item) => {
                  return (
                    <tr
                      key={`${item.teacher}-${item.type}-${item.state}`}
                      className="text-sm font-normal text-[#64748B] text-left p-3 border-b border-solid border-[#E5E7EB]"
                    >
                      <td className="w-[221.6px] h-[44px]">
                        <p className="font-mont">
                          {item.date} {item.startTime}
                        </p>
                      </td>
                      <td className="w-[221.6px] h-[44px]">
                        <p className="font-mont">{item.teacher}</p>
                      </td>
                      <td className="w-[221.6px] h-[44px]">
                        <p className="font-mont">{item.type}</p>
                      </td>
                      <td className="w-[221.6px] h-[44px]">
                        <p className="font-mont">{item.state}</p>
                      </td>
                      <td className="w-[221.6px] h-[44px] text-center">
                        {item.state !== 'Cancelada' && (
                          <button
                            onClick={() =>
                              setEvaluation({
                                id: item.id,
                                show: true,
                                date: item.date,
                                startTime: item.startTime,
                                endTime: item.endTime,
                                teacher: item.teacher,
                                type: item.type,
                                state: item.state,
                              })
                            }
                            className="font-mont text-[#059669] font-bold flex justify-start"
                          >
                            Evaluar
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Family;
