import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const registerParentWithGoogle = async (token: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/auth/register-parent-google`,
      { googleToken: token }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao registrar professor via Google:',
        error.response?.data || error.message
      );
    } else {
      console.error(
        'Erro desconhecido ao registrar professor via Google:',
        error
      );
    }
    throw error;
  }
};
