import axios from 'axios';
import { ParentForm } from '../../../@types/parentTypes';

const API_URL = process.env.REACT_APP_API_URL;

export const registerParent = async (form: ParentForm) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/auth/register-parent`,
      form,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (!response.status) {
      throw new Error('Erro ao registrar usuário');
    }
    return response.data;
  } catch (error) {
    console.error('Erro:', error);
    throw error;
  }
};
