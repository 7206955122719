import React, { useState } from 'react';
import * as SolarIconSet from 'solar-icon-set';
import { User, StudentProfile } from '../../../@types/usersTypes';
import { useNavigate } from 'react-router-dom';
import ChatBot from './ChatBot';

interface HeaderProps {
  user: User | null;
  selectedChild: StudentProfile | null;
  handleChildSelect: (child: StudentProfile) => void;
  handleAddChild: () => void;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({
  user,
  selectedChild,
  handleChildSelect,
  handleAddChild,
  activePage,
  setActivePage,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    navigate('/login');
  };

  const handleMenuOptionClick = (page: string) => {
    setActivePage(page);
    setIsMenuOpen(false);
  };

  return (
    <header className="flex justify-between items-center mb-1 p-2 h-auto z-10 relative">
      {/* Child Tabs */}
      <div className="flex gap-4">
        {activePage !== 'Account' &&
          activePage !== 'PaymentSettings' &&
          user?.Parent?.children.map((child) => (
            <button
              key={child.id}
              onClick={() => handleChildSelect(child)}
              className={`rounded-3xl pl-2 py-2 w-[125px] h-[48px] font-nunito font-bold text-[#059669] flex items-center gap-1 ${
                selectedChild?.id === child.id
                  ? 'text-[#059669] bg-white'
                  : 'bg-[#F3F4F6] text-[#64748B]'
              }`}
            >
              <img
                src={`https://storage.googleapis.com/e-pitinglish/${child.avatarUrl}`}
                alt={`Avatar de ${child.childName}`}
                className="w-[48px] h-[48px]"
              />
              {child.childName}
            </button>
          ))}

        {/* Botão de adicionar mais filhos */}
        {activePage !== 'Account' && activePage !== 'PaymentSettings' && (
          <button
            className="rounded-3xl px-4 py-2 w-[48px] h-[48px] font-nunito font-bold text-[#059669] flex items-center justify-center text-2xl"
            onClick={handleAddChild}
          >
            +
          </button>
        )}
      </div>

      {/* Header Icons */}
      <div className="flex items-center gap-8 mr-5">
        {/* <button>
          <SolarIconSet.Magnifer size={24} color="#1C274C" />
        </button>
        <button>
          <SolarIconSet.ChatRound size={24} color="#1C274C" />
        </button> */}
        <button>
          <SolarIconSet.Bell size={24} color="#1C274C" />
        </button>
        <div className="relative flex items-center gap-[6.5px]">
          <img
            src={user?.profilePicture || ''}
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
          <button onClick={toggleMenu}>
            <SolarIconSet.AltArrowDown size={12} />
          </button>

          {/* Dropdown Menu */}
          {isMenuOpen && (
            <div className="absolute top-9 right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-20">
              <ul className="py-1">
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={() => handleMenuOptionClick('Family')}
                  >
                    Mi Familia
                  </button>
                </li>
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={() => handleMenuOptionClick('Account')}
                  >
                    Mi Cuenta
                  </button>
                </li>
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={() => handleMenuOptionClick('Subscription')}
                  >
                    Suscripción
                  </button>
                </li>
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={() => handleMenuOptionClick('PaymentSettings')}
                  >
                    Settings
                  </button>
                </li>
                <li>
                  <button
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={handleLogout}
                  >
                    Cerrar Sesión
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Chat Bot Button */}
      <ChatBot />
    </header>
  );
};

export default Header;
