import React from 'react';
import { User } from '../../../@types/usersTypes';

interface AccountSettingsProps {
  user: User | null;
  setActivePage: (page: string) => void;
}

const AccountSettings: React.FC<AccountSettingsProps> = () => {
  return (
    <div className="flex flex-col max-w-[1160px] h-full mt-4 gap-6">
      <div className="bg-[#F3F4F6]">
        <p className="font-mont text-[30px] text-[#0F172A] font-extrabold leading-[38.34px]">
          Ajustes de cuenta
        </p>
        <p className="font-mont text-[14px] text-[#94A3B8] font-semibold leading-[20px]">
          Administra tus ajustes
        </p>
      </div>
      <div className="flex flex-col justify-around bg-white rounded-2xl p-8 gap-6 h-[197px]">
        <p className="font-nunito text-[24px] text-[#000000] font-bold leading-[32.74px]">
          Autenticación en Dos Pasos (2FA)
        </p>
        <p className="font-nunito text-[14px] text-[#64748B] font-bold leading-[20px]">
          Activa la autenticación en dos pasos (2 Factor Authentication) para
          mayor seguridad en tu cuenta.
        </p>
        <button className="font-mont font-bold text-[14px] leading-[20px] py-3 w-[191px] h-[44px] bg-[#34D399] text-white rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition">
          Activar 2FA
        </button>
      </div>
    </div>
  );
};

export default AccountSettings;
