import React from 'react';
import { User } from '../../../@types/usersTypes';
import headphone from '../../../assets/Headphone.png';
import mic from '../../../assets/mic.png';
import camara from '../../../assets/camera.png';
import pointingUp from '../../../assets/pointingUp.png';
import * as SolarIconSet from 'solar-icon-set';

interface SettingsProps {
  user: User | null;
  setActivePage: (page: string) => void;
}

const Settings: React.FC<SettingsProps> = () =>
  // {
  //   // user,
  //   // setActivePage,
  // }
  {
    let hasScheduledLesson;
    // const nextLesson = null;

    return (
      <div className="flex max-w-[1160px] h-full overflow-hidden mt-10">
        <div className="flex-grow bg-[#F3F4F6] overflow-y-auto w-[1160px] mx-auto mb-20">
          {/* Primeira Section com fundo verde */}
          <section className="w-full h-[135px] bg-[#34D399] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] flex items-center justify-start">
            {hasScheduledLesson ? (
              <div className="text-white flex items-center justify-between w-full px-6">
                <div className="text-start">
                  <h3 className="text-[30px] font-extrabold font-nunito text-[#0F172A] mb-2 leading-[40.92px]">
                    ¡Configura tu equipo antes de la clase!
                  </h3>
                  <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                    Revisa tu configuración de audio, micrófono y cámara para
                    asegurarte de que <br /> todo funcione correctamente. Así
                    podrás disfrutar de una clase in interrupciones.
                  </p>
                </div>

                {/* {nextLesson &&
                (() => {
                  const lessonStartTime = new Date(nextLesson.startTime);
                  const currentTime = new Date();
                  const timeDifference =
                    lessonStartTime.getTime() - currentTime.getTime();
                  const fifteenMinutesInMs = 15 * 60 * 1000; // 15 minutos em milissegundos

                  // Verifica se estamos no intervalo de 15 minutos antes da aula
                  const isWithin15Minutes =
                    timeDifference <= fifteenMinutesInMs && timeDifference > 0;

                  return (
                    <button
                      className={`font-mont font-extrabold text-[14px] leading-[17.89px] w-[228px] h-[38px] px-4 py-2 rounded-md ${
                        isWithin15Minutes
                          ? 'bg-[#FFFFFF] text-[#065F46] hover:bg-[#059669] hover:text-white cursor-pointer border-2 border-[#069668]'
                          : 'bg-[#FFFFFF] text-[#065F46]'
                      } shadow-[0px_1px_2px_0px_#0000000D]`} // Sombra adicionada
                      disabled={!isWithin15Minutes} // Desabilita o clique fora do intervalo
                    >
                      {isWithin15Minutes
                        ? 'Unirme Ahora'
                        : `Entrar a clase a las ${new Date(
                            lessonStartTime.getTime() - 15 * 60000 // Subtrai 15 minutos
                          ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`}
                    </button>
                  );
                })()} */}
              </div>
            ) : (
              <div className="text-white text-start ml-6">
                <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                  ¡Configura tu equipo antes de la clase!
                </h3>
                <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                  Revisa tu configuración de audio, micrófono y cámara para
                  asegurarte de que <br /> todo funcione correctamente. Así
                  podrás disfrutar de una clase in interrupciones.
                </p>
              </div>
            )}
          </section>
          <div className="flex flex-col gap-4 mt-6 justify-center max-w-[1160px] mx-auto">
            <h2 className="font-bold leading-[32.74px] text-[24px] text-[#64748B] font-nunito ml-4">
              Prepárate para la clase
            </h2>
            {/* Primeira section com 3 cards */}
            <section className="flex gap-1 justify-center w-full">
              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full">
                  {/* Ícone de número 2 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    1
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Prueba tu sonido
                  </h3>
                </div>

                {/* Contêiner para o texto e a imagem */}
                <div className="relative">
                  {/* Descrição */}
                  <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal pr-[140px]">
                    Asegúrate de que puedes escuchar correctamente seleccionando
                    el dispositivo de salida de audio.
                  </p>

                  {/* Imagem ao lado direito do texto com posição absoluta */}
                  <img
                    src={headphone}
                    alt="Headphone"
                    className="w-[166.32px] h-[166.32px] rounded-2xl absolute top-[-70px] right-[0px]"
                  />
                </div>

                {/* Botão */}
                <button className="mt-[18px] py-2 px-4 text-[#059669] border-2 border-[#059669] rounded-lg font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors">
                  Haz el chequeo
                </button>
              </div>

              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6 relative">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full">
                  {/* Ícone de número 2 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    2
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Prueba de micrófono
                  </h3>
                </div>

                {/* Contêiner para o texto e a imagem */}
                <div className="relative">
                  {/* Descrição */}
                  <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal pr-[140px]">
                    Configura tu micrófono para que tus profesores puedan
                    escucharte claramente.
                  </p>

                  {/* Imagem ao lado direito do texto com posição absoluta */}
                  <img
                    src={mic}
                    alt="Micrófono"
                    className="w-[128px] h-[128px] rounded-2xl absolute top-[-55px] right-[0px]"
                  />
                </div>

                {/* Botão */}
                <button className="mt-[38px] py-2 px-4 text-[#059669] border-2 border-[#059669] rounded-lg font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors">
                  Haz el chequeo
                </button>
              </div>

              <div className="w-[376px] h-[284px] bg-white rounded-2xl p-6 shadow-md flex flex-col gap-6 relative">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full">
                  {/* Ícone de número 3 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    3
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Prueba de cámara
                  </h3>
                </div>

                {/* Contêiner para o texto e a imagem */}
                <div className="relative">
                  {/* Descrição */}
                  <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal pr-[140px]">
                    Asegúrate de que tu cámara esté funcionando bien y de que el
                    encuadre se acorrecto.
                  </p>

                  {/* Imagem ao lado direito do texto com posição absoluta */}
                  <img
                    src={camara}
                    alt="Cámara"
                    className="w-[142.32px] h-[142.32px] rounded-2xl absolute top-[-50px] right-[-10px]"
                  />
                </div>

                {/* Botão */}
                <button className="mt-[38px] py-2 px-4 text-[#059669] border-2 border-[#059669] rounded-lg font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors">
                  Haz el chequeo
                </button>
              </div>
            </section>

            {/* Segunda section (Aviso para padres) */}
            <section className=" bg-[#E6E7EB] rounded-2xl flex items-center shadow-md mx-auto pl-2 w-full h-[88px]">
              <div className="flex items-center">
                <img src={pointingUp} alt="Hand Icon" className="w-16 h-16" />
                <div>
                  <h4 className="font-bold text-[#334155] font-nunito text-[16px] leading-[19.2px]">
                    Aviso adicional!
                  </h4>
                  <p className="text-[#64748B] font-nunito font-normal text-[14px]">
                    Si nada funciona, por favor, revisa tus permisos de
                    cámara/micrófono en el navegador y vuelve a intentarlo.
                  </p>
                </div>
              </div>
            </section>
          </div>
          {/* Two Column Section */}
          <section className="flex max-w-[1160px] h-[276px] justify-center items-center mx-auto mt-6">
            {/* Seção de Perguntas Frequentes */}
            <div className="w-full h-[283px]">
              <h2 className="font-bold leading-[32.74px] text-[24px] text-[#64748B] font-nunito mb-3">
                Preguntas frecuentes
              </h2>
              <div className="flex flex-col gap-2">
                {/* Pergunta 1 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px] ">
                    ¿Por qué no funciona mi cámara durante la clase?
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 2 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    No se escucha mi micrófono, ¿cómo lo arreglo?
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 3 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    ¿Cómo pruebo si mi audio funciona correctamente?
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                {/* Pergunta 4 */}
                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    ¿Cómo cambio el dispositivo de audio o micrófono?
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>

                <details className="bg-[#FFFFFF] rounded-2xl p-4 cursor-pointer mb-[40px] border border-[#E5E7EB]">
                  <summary className="flex justify-between items-center font-bold text-[#64748B] text-[16px] font-nunito leading-[19.2px]">
                    Mi video se ve con retraso o mala calidad, ¿qué puedo hacer?
                    <span className="icon transition-transform duration-300">
                      <SolarIconSet.AltArrowDown size={16} color="#64748B" />
                    </span>
                  </summary>
                  <p className="mt-[10px] text-[#64748B] leading-[20px] font-normal font-nunito text-[14px] mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus tempor lorem non est congue blandit.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };

export default Settings;
