import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const loginUser = async (emailOrUsername: string, password: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/auth/login`,
      { emailOrUsername, password }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao fazer login:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao fazer login:', error);
    }
    throw error;
  }
};
