import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar/Sidebar';
import Account from './Account/Account';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import Dashboard from './Dashboard/Dashboard';
import Calendar from './Calendar/Calendar';
import Teachers from './Teachers/Teachers';
import Club from './Club/Club';
import Header from './Header/PortalHeader';
import { User, StudentProfile, TeacherProfile } from '../../@types/usersTypes';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../service/auth/user/getUser.service';
import axios from 'axios';
import LoadingSpinner from '../Loading/Spinner/Spinner';
import Bonus from './Bonus/Bonus';
import Settings from './Settings/Settings';
import Subscription from './Subscription/Subscription';
import Family from './Family/Family';
import TeacherClasses from './Teachers/TeacherClasses';

const Portal: React.FC = () => {
  const [activePage, setActivePage] = useState('Dashboard');
  const [user, setUser] = useState<User | null>(null);
  const [selectedChild, setSelectedChild] = useState<StudentProfile | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<TeacherProfile>();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('authToken');

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      if (userId) {
        const response = await getUser(userId);
        setUser(response);

        // Definir a primeira criança como selecionada por padrão se houver crianças
        if (response?.Parent?.children.length > 0) {
          setSelectedChild(response.Parent.children[0]);
        }
      }
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);

      if (
        axios.isAxiosError(error) &&
        [401, 404].includes(error.response?.status || 0)
      ) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchUserData();
  }, [token, userId, navigate]);

  useEffect(() => {
    if (user) {
      if (user.role !== 'PARENT') {
        navigate('/teacher-portal');
      }
    }
  }, [user, navigate]);

  // Função para selecionar a criança
  const handleChildSelect = (child: StudentProfile) => {
    setSelectedChild(child);
  };

  // Função para adicionar filhos (redirecionar para o onboarding ou algo similar)
  const handleAddChild = () => {
    navigate('/parent-onboarding');
  };

  const renderContent = () => {
    switch (activePage) {
      case 'Dashboard':
        return (
          <Dashboard
            user={user}
            selectedChild={selectedChild}
            setActivePage={setActivePage}
          />
        );
      case 'Calendar':
        return (
          <Calendar
            user={user}
            selectedChild={selectedChild}
            setActivePage={setActivePage}
          />
        );
      case 'Account':
        return <Account user={user} setActivePage={setActivePage} />;
      case 'PaymentSettings':
        return <PaymentSettings user={user} setActivePage={setActivePage} />;
      case 'Family':
        return (
          <Family
            user={user}
            selectedChild={selectedChild}
            setActivePage={setActivePage}
          />
        );
      case 'Teachers':
        return (
          <Teachers
            user={user}
            selectedChild={selectedChild}
            setActivePage={setActivePage}
            setSelectedTeacher={setSelectedTeacher}
          />
        );
      case 'TeacherCalendar':
        return (
          <TeacherClasses
            teacher={selectedTeacher}
            setActivePage={setActivePage}
          />
        );
      case 'Club':
        return (
          <Club
            user={user}
            selectedChild={selectedChild}
            setActivePage={setActivePage}
          />
        );
      case 'Bonus':
        return (
          <Bonus
            // user={user}
            selectedChild={selectedChild}
            setActivePage={setActivePage}
          />
        );
      case 'Settings':
        return (
          <Settings
            selectedChild={selectedChild}
            setActivePage={setActivePage}
          />
        );
      case 'Subscription':
        return <Subscription user={user} />;
      default:
        return (
          <Dashboard
            user={user}
            selectedChild={selectedChild}
            setActivePage={setActivePage}
          />
        );
    }
  };

  return (
    <div className="relative flex max-w-[1416px] w-full mx-auto h-screen">
      {/* Spinner de carregamento com o fundo escurecido cobrindo 100% da tela */}
      {isLoading && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{ backgroundColor: '#0F172A99' }} // Cor escurecida com opacidade
        >
          <LoadingSpinner />
        </div>
      )}

      {/* Sidebar */}
      <Sidebar setActivePage={setActivePage} activePage={activePage} />

      {/* Conteúdo principal */}
      <div className="flex-grow p-5 bg-[#F3F4F6] overflow-y-hidden w-full">
        {/* Header */}
        <Header
          user={user}
          activePage={activePage}
          setActivePage={setActivePage}
          selectedChild={selectedChild}
          handleChildSelect={handleChildSelect}
          handleAddChild={handleAddChild}
        />

        {/* Conteúdo renderizado */}
        {renderContent()}
      </div>
    </div>
  );
};

export default Portal;
