import React from 'react';

interface ToastProps {
  onClick: () => void;
  message: string;
  description?: string;
  variant: string;
}

const Toast: React.FC<ToastProps> = ({
  onClick,
  message,
  description,
  variant,
}) => {
  return (
    <div
      className={`${variant === 'success' ? 'bg-[#F0FDF4]' : 'bg-[#FEF2F2]'} fixed z-[999] top-0 right-[17rem] w-[381px] my-4 whitespace-pre`}
      onClick={onClick}
    >
      <div className="flex px-4 py-6 items-center">
        <span
          className={`${variant === 'success' ? 'text-[#15803D]' : 'text-[#B91C1C]'} flex flex-col leading-[19.2px] text-[16px] font-nunito font-bold`}
        >
          {message}
          {description && (
            <span
              className={`${variant === 'success' ? 'text-[#15803D]' : 'text-[#B91C1C]'} text-sm font-semibold`}
            >
              {description}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default Toast;
