import React, { useState } from 'react';
import logo from '../../../assets/logo.png';
import loginImage from '../../../assets/login.png';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../Loading/Spinner/Spinner';
import { getUserByEmail } from '../../../service/auth/user/getUserByEmail.service';
import { useMediaQuery } from 'usehooks-ts';
import * as SolarIconSet from 'solar-icon-set';
import GreenButton from '../../UI/GreenButton';
import Input from '../../UI/Input';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 766px)');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handlePasswordRecover = async (e: React.FormEvent) => {
    e.preventDefault(); // Previne o recarregamento da página
    setEmailError(''); // Limpa o erro anterior, se houver
    setIsLoading(true);

    if (!email) {
      setEmailError('Por favor, insira um email válido.');
      return;
    }

    try {
      const response = await getUserByEmail(email);
      if (response.status === 404) {
        setEmailError('Email incorrecto');
      } else {
        // Logica para enviar o email de recuperaçao para o usuario.
      }
    } catch (error) {
      console.log(error);
      setEmailError('Email incorrectos');
    } finally {
      setIsLoading(false);
    }
  };

  if (isMobile) {
    return (
      <div className="flex flex-col m-8 gap-16">
        <div className="flex items-center">
          <SolarIconSet.ArrowLeft color="#334155" size={32} />
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="text-[16px] font-bold font-nunito leading-[19.2px] text-center text-[#334155] cursor-pointer"
          >
            Volver
          </button>
          <div className="flex-1 flex justify-center pr-20">
            <img
              src={logo}
              alt="Logo"
              className="max-w-full w-[75.16px] h-[80px] mb-[36px]"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 px-16">
          <h2 className="text-[36px] font-extrabold font-mont leading-[36px] text-center text-[#0F172A]">
            ¿Olvidaste tu Password?
          </h2>
          <p className="text-[16px] font-bold font-nunito leading-[19.2px] text-center text-[#334155]">
            No te preocupes, escribe tu email y te enviaremos un email con un
            link para recuperar tu cuenta
          </p>
        </div>
        <form
          className="w-full flex flex-col items-center gap-[8px]"
          onSubmit={handlePasswordRecover}
        >
          <div className="mb-4 flex flex-col">
            <label
              htmlFor="email"
              className="w-[340px] h-[19px] px-[12px] font-nunito text-[16px] font-bold leading-[19.2px] text-left text-[#334155] opacity-100"
            >
              Email
            </label>
            <input
              type="text"
              name="email"
              className="w-[340px] h-[44px] pl-4 py-2 font-mont  bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
              style={{
                boxShadow: 'inset 0px 2px 5px 0px #0000001A',
              }}
              placeholder="Escribe tu Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {emailError && (
              <p className="text-[#EF4444] text-sm mt-1">{emailError}</p>
            )}
          </div>
          <GreenButton type="submit" width="332px" height="52px">
            <span className="whitespace-nowrap font-mont mr-[6px]">
              RECUPERA TU PASSWORD
            </span>
            <SolarIconSet.RoundAltArrowRight size={20} iconStyle="Bold" />
          </GreenButton>
        </form>
      </div>
    );
  }

  return (
    <div className="min-h-screen max-h-[890px] w-screen flex items-center justify-center overflow-hidden">
      <div className="flex w-full h-full items-center justify-center">
        {/* Contêiner Principal */}
        <div className="flex justify-center mx-auto max-h-[890px]">
          {/* Área da Imagem */}
          <div className="hidden md:flex items-center justify-center max-h-[890px] overflow-hidden">
            <img
              src={loginImage}
              alt="Descrição da imagem"
              className="object-cover w-[897.87px] max-h-[890px] h-auto opacity-100"
            />
          </div>

          {/* Área ao lado da Imagem */}
          <div className="flex flex-col items-start justify-between w-full max-w-[629px] h-full max-h-[890px] gap-[72px] opacity-100">
            {/* Logo e Formulário */}
            <div className="flex flex-col items-center w-full h-full">
              <div className="flex items-center justify-between w-full mb-[72px] ml-12">
                {/* Link Volver */}

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="flex items-center mt-[38px] font-nunito text-[18px] font-bold leading-[32.74px] text-center text-[#334155] cursor-pointer"
                >
                  <SolarIconSet.ArrowLeft color="#334155" size={32} />
                  Volver
                </button>

                {/* Logo */}
                <div className="flex-1 flex justify-center">
                  <img
                    src={logo}
                    alt="Logo"
                    className="max-w-full w-[75.16px] h-[80px] mb-[36px] mr-[145px]"
                  />
                </div>
              </div>

              {/* Formulário de Login */}
              <div className="flex flex-col items-center w-full max-h-[750px] overflow-y-auto">
                <div
                  className="flex flex-col items-center w-full gap-[8px]"
                  style={{ contain: 'inline-size', textWrap: 'balance' }}
                >
                  <h2 className="text-[36px] font-extrabold font-mont leading-[36px] text-center text-[#0F172A]">
                    ¿Olvidaste tu Password?
                  </h2>
                  <p className="text-[16px] font-bold leading-[19.2px] text-center text-[#334155] font-nunito mb-8">
                    No te preocupes, escribe tu email y te enviaremos un email{' '}
                    <br />
                    con un link para recuperar tu cuenta
                  </p>
                </div>

                <form
                  className="w-full flex flex-col items-center gap-[8px]"
                  onSubmit={handlePasswordRecover}
                >
                  <div className="mb-8 flex flex-col">
                    <div className="relative flex flex-col">
                      <Input
                        type="text"
                        name="Email"
                        label="Email"
                        width="340px"
                        height="44px"
                        placeholder="Escribe tu Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      {emailError && (
                        <SolarIconSet.DangerCircle
                          iconStyle="BoldDuotone"
                          size={20}
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#EF4444] mt-1"
                          color="#EF4444"
                        />
                      )}
                    </div>
                    {emailError && (
                      <p className="text-[#EF4444] text-sm">{emailError}</p>
                    )}
                  </div>

                  <GreenButton type="submit" width="332px" height="52px">
                    <span className="whitespace-nowrap font-mont mr-[6px]">
                      RECUPERA TU PASSWORD
                    </span>
                    <SolarIconSet.RoundAltArrowRight
                      size={20}
                      iconStyle="Bold"
                    />
                  </GreenButton>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
