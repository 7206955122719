import React from 'react';
import {
  FaUser,
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTiktok,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Policy: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div>
      {/* Top Bar */}
      <div className="flex justify-end items-center p-2.5 bg-[#6DD2BF]">
        <div className="flex items-center space-x-5">
          <button
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
            onClick={handleLoginRedirect}
          >
            <FaUser className="mr-2" /> Iniciar Sesión
          </button>
          <a
            href="https://wa.me/34622713212"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
          >
            <FaWhatsapp className="mr-2" /> Whatsapp
          </a>
          <a
            href="https://www.linkedin.com/company/e-pitinglish/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.instagram.com/epitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/ePitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.tiktok.com/@epitinglish"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaTiktok />
          </a>
        </div>
      </div>

      {/* Header Menu */}
      <div className="flex justify-between items-center p-4 bg-[#6DD2BF]">
        <div className="flex items-center">
          <img src="/logo.jpeg" alt="Logo" className="h-12" />
        </div>
        <div className="flex justify-around items-center space-x-8">
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            NUESTRA METODOLOGIA
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PROFESORES
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PRECIOS
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            BLOG
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            CONTACTO
          </button>
        </div>
      </div>

      {/* Política de Privacidad */}
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-4 text-[#333]">
          Política de privacidad
        </h1>
        <p className="mb-4 text-[#444] leading-7">
          EL TITULAR se compromete a proteger la privacidad de los usuarios que
          accedan a esta web y/o cualquiera de sus servicios. La utilización de
          la web y/o de cualquiera de los servicios ofrecidos por EL TITULAR
          implica la aceptación por el usuario de las disposiciones contenidas
          en la presente Política de Privacidad y que sus datos personales sean
          tratados según se estipula en ella. Por favor, tenga en cuenta que a
          pesar de que pueda haber enlaces de nuestra web a otras webs o redes
          sociales, esta Política de Privacidad no se aplica a las webs de otras
          compañías u organizaciones a las que la web esté redirigida. EL
          TITULAR no controla el contenido de las webs de terceros, ni acepta
          cualquier responsabilidad por el contenido o las políticas de
          privacidad de estas webs.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Información básica sobre el tratamiento de datos
        </h2>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>
            <strong>Responsable del tratamiento:</strong> EL TITULAR. Nuestros
            datos figuran en el aviso legal de esta web.
          </li>
          <li>
            <strong>Finalidad del tratamiento:</strong> Ofrecer y gestionar
            nuestros productos i/o servicios.
          </li>
          <li>
            <strong>Legitimación:</strong> Consentimiento obtenido del
            interesado. Ejecución del contrato de servicios.
          </li>
          <li>
            <strong>Destinatarios:</strong> Los datos no serán comunicados a
            terceros, salvo que lo exija una Ley o sea necesario para cumplir
            con la finalidad del tratamiento.
          </li>
          <li>
            <strong>Derechos de las personas:</strong> Los interesados tienen
            derecho a ejercer los derechos de acceso, rectificación, limitación
            de tratamiento, supresión, portabilidad y oposición, enviando su
            solicitud a nuestra dirección.
          </li>
          <li>
            <strong>Plazo de conservación de los datos:</strong> Mientras se
            mantenga la relación comercial o durante los años necesarios para
            cumplir con las obligaciones legales.
          </li>
          <li>
            <strong>Reclamación:</strong> Los interesados pueden dirigirse a la
            AEPD para presentar la reclamación que considere oportuna.
          </li>
          <li>
            <strong>Información adicional:</strong> Puede consultar la
            información adicional y detallada a continuación en las “Preguntas
            sobre privacidad”.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          PREGUNTAS SOBRE PRIVACIDAD
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y
          del Consejo, de 27 de abril de 2016, (RGPD), y la Ley Orgánica 3/2018,
          de 5 de diciembre, de Protección de Datos Personales y garantía de los
          derechos digitales (LOPDGDD le ofrecemos la siguiente información
          sobre el tratamiento de sus datos personales:
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Quién es el responsable del tratamiento de sus datos?
        </h2>
        <p className="mb-4 text-[#444] leading-7">EL TITULAR.</p>
        <p className="mb-4 text-[#444] leading-7">
          Nuestros datos figuran en el{' '}
          <a
            href="/legal-advice"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#099E65] underline"
          >
            aviso legal de esta web
          </a>
          .
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Con qué finalidad tratamos sus datos personales?
        </h2>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>
            Tratamos la información que se nos facilita para prestar y facturar
            nuestros servicios y productos.
          </li>
          <li>
            Si nos da su consentimiento también podremos tratar sus datos para
            enviarle información sobre nuestras actividades, servicios y
            productos.
          </li>
          <li>
            Si participa en alguno de nuestros sorteos y/o concursos
            publicaremos su nombre y apellidos, y su imagen, en los diferentes
            espacios y medios de comunicación y difusión que utilizamos,
            incluidas las redes sociales, con el fin de promocionar nuestras
            actividades, servicios y productos.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Cuánto tiempo conservaremos sus datos?
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Los datos personales proporcionados se conservarán mientras sea
          usuario de nuestros servicios o quiera recibir información, y si
          participa en una promoción mientras esté activa, y luego, durante los
          plazos establecidos para cumplir con nuestras obligaciones legales.
        </p>
        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Cuál es la legitimación para el tratamiento de sus datos?
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          La base legal para los tratamientos de sus datos son los
          consentimientos que nos da.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Respecto a la información que nos sea enviada por menores de 14 años,
          se entenderá que lo ha sido con el consentimiento de sus
          representantes legales. Si no es así, el representante legal del menor
          nos lo tiene que comunicar tan pronto como tenga conocimiento.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿A qué destinatarios se comunicarán sus datos?
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Los datos no serán comunicados a terceros, salvo que lo exija una Ley
          o sea necesario para cumplir con la finalidad del tratamiento.
        </p>
        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Cuáles son sus derechos cuando nos facilita sus datos?
        </h2>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>
            Cualquier persona tiene derecho a obtener confirmación sobre si
            estamos tratando o no sus datos personales.
          </li>
          <li>
            Los interesados tienen derecho a acceder a sus datos personales, así
            como a solicitar la rectificación de los datos inexactos o, en su
            caso, solicitar su supresión cuando, entre otros motivos, los datos
            ya no sean necesarios para las finalidades para las que se
            recogieron.
          </li>
          <li>
            En determinadas circunstancias los interesados podrán solicitar la
            limitación del tratamiento de sus datos, en este caso únicamente los
            conservaremos para el ejercicio o la defensa de reclamaciones.
          </li>
          <li>
            También, en determinadas circunstancias y por motivos relacionados
            con su situación particular, los interesados pueden oponerse al
            tratamiento de sus datos.
          </li>
          <li>
            Los interesados también tienen derecho a la portabilidad de sus
            datos.
          </li>
          <li>
            Finalmente, los interesados tienen derecho a presentar una
            reclamación ante la Autoridad de Control competente.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Cómo puede ejercer sus derechos?{' '}
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Enviando un escrito adjuntando una copia de un documento que lo
          identifique, a nuestra dirección física o electrónica.
        </p>
        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Cómo hemos obtenido sus datos?
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Los datos personales que tratamos proceden del propio interesado. El
          interesado garantiza que los datos personales facilitados son ciertos
          y se hace responsable de comunicar cualquier modificación de estos.
          Los datos que estén marcados con un asterisco serán obligatorios para
          poder darle el servicio solicitado.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Qué datos tratamos?
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Las categorías de datos que podemos tratar en la prestación de
          nuestros servicios son:
        </p>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>Datos de carácter identificativo</li>
          <li>Direcciones postales o electrónicas</li>
          <li>Otros datos solicitados en nuestros formularios</li>
        </ul>
        <p className="mb-4 text-[#444] leading-7">
          Los datos son limitados, dado que únicamente tratamos los datos
          necesarios para la prestación de nuestros servicios y la gestión de
          nuestra actividad.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Usamos cookies?
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Utilizamos cookies durante la navegación en nuestra web con el
          consentimiento informado del usuario. El usuario puede configurar su
          navegador para ser avisado de la utilización de cookies y para evitar
          su uso. Para obtener más información visite nuestra{' '}
          <a
            href="/cookies-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#099E65] underline"
          >
            política de cookies.
          </a>
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Qué medidas de seguridad aplicamos?
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Aplicamos las medidas de seguridad establecidas en el artículo 32 del
          RGPD, por tanto, hemos adoptado las medidas de seguridad necesarias
          para garantizar un nivel de seguridad adecuado al riesgo del
          tratamiento de datos que realizamos, con mecanismos que nos permiten
          garantizar la confidencialidad, integridad, disponibilidad y
          resiliencia permanente de los sistemas y servicios de tratamiento.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Algunas de estas medidas son:
        </p>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>
            Información de las políticas de tratamiento de datos al personal.
          </li>
          <li>Realización de copias de seguridad periódicas.</li>
          <li>Control de acceso a los datos.</li>
          <li>Procesos de verificación, evaluación y valoración regulares.</li>
        </ul>

        {/* Resto da política */}
        {/* Continue adicionando as seções restantes usando tags de parágrafo, títulos e listas, seguindo o estilo acima */}
      </div>
    </div>
  );
};

export default Policy;
