import React, { useEffect, useState } from 'react';
import onboardingImage from '../../assets/onboarding.png';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import * as SolarIconSet from 'solar-icon-set';
import outdoor from '../../assets/Outdoor.png';
import artMusic from '../../assets/ArtMusic.png';
import deportes from '../../assets/Deportes.png';
import videojuegos from '../../assets/Videojuegos.png';
import peliculas from '../../assets/Peliculas.png';
import libros from '../../assets/Libros.png';
import { format, addDays } from 'date-fns';
import { es } from 'date-fns/locale';
import { addChild } from '../../service/parent/addChild.service';
import { checkAvailability } from '../../service/teacher/checkAvailability.service';
import { scheduleLesson } from '../../service/teacher/scheduleLesson.service';
import OnboardingLoading from '../Loading/OnboardingLoading/OnboardingLoading';
import RegisteringLoading from '../Loading/RegisteringLoading/RegisteringLoading';
import {
  StudentProfile,
  recommendTeacher,
} from '../../service/teacher/recommendTeacher.service';

// Indicador de progresso
const ProgressIndicator = ({ currentStep }: { currentStep: number }) => {
  return (
    <div className="flex justify-center items-center gap-2 mt-4">
      {[1, 2, 3, 4, 5].map((step) => (
        <div
          key={step}
          className={`w-[35.2px] h-[4px] rounded-full ${
            step < currentStep
              ? 'bg-[#059669]'
              : step === currentStep
                ? 'bg-[#059669]'
                : 'bg-[#E5E7EB]'
          }`}
        />
      ))}
    </div>
  );
};

const childLikes = [
  {
    image: outdoor,
    name: 'Actividades al aire libre',
  },
  {
    image: artMusic,
    name: 'Arte y música',
  },
  {
    image: deportes,
    name: 'Deporte y juegos en equipo',
  },
  {
    image: videojuegos,
    name: 'Videojuegos',
  },
  {
    image: peliculas,
    name: 'Películas',
  },
  {
    image: libros,
    name: 'Leer libros',
  },
];

const disorders = [
  'Dislexia',
  'TEA',
  'TDAH',
  'Otro',
  'No tiene ningún trastorno',
];

const ParentOnboarding: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [gender, setGender] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [selectedDisorders, setSelectedDisorders] = useState<string[]>([]);
  const [englishLevel, setEnglishLevel] = useState<string>();
  const [improveArea, setImproveArea] = useState<string>();
  const [error, setError] = useState('');
  const [selectedLikes, setSelectedLikes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [otherDisorder, setOtherDisorder] = useState('');
  const [isOtherDisorderActive, setIsOtherDisorderActive] =
    useState<boolean>(false);
  const today = new Date();
  const monthYear = format(today, 'MMMM yyyy', { locale: es });
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [likesDetails, setLikesDetails] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const [childProfile, setChildProfile] = useState<StudentProfile>();

  useEffect(() => {
    if (!userId) {
      navigate('/login');
      return;
    }
  }, []);

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAge(e.target.value);
  };

  // Tipagem para os campos que podem ser validados pela função isEmpty
  type Field = string | string[] | Date[] | Record<string, string> | undefined;

  // Função para verificar se um campo está vazio
  const isEmpty = (field: Field): boolean => {
    if (field === undefined) {
      return true;
    }

    if (typeof field === 'string') {
      return field.trim() === '';
    }

    if (Array.isArray(field)) {
      return field.length === 0;
    }

    // Verificação específica para objetos do tipo Record<string, string>
    if (typeof field === 'object' && field !== null) {
      // Checa se algum valor do objeto é uma string vazia
      return Object.values(field).some((value) => value.trim() === '');
    }

    return false;
  };

  // Função para avançar os passos do formulário
  const handleNext = async () => {
    setError('');

    // Objeto de validação para cada passo do formulário
    const validationRules: { [key: number]: () => boolean } = {
      1: () => isEmpty(gender) || isEmpty(name) || isEmpty(age),
      2: () => isEmpty(englishLevel) || isEmpty(improveArea),
      3: () =>
        isEmpty(likesDetails) ||
        selectedLikes.some((like) => isEmpty(likesDetails[like])),
      4: () => isEmpty(selectedDisorders),
      5: () => isEmpty(selectedDates) || isEmpty(selectedTimes),
    };

    // Verifica se o passo atual tem campos inválidos
    const hasError = validationRules[currentStep]?.();

    if (hasError) {
      setError('Por favor complete todos los campos antes de continuar');
      return;
    }

    // Lógica para adicionar a criança no passo 4
    if (currentStep === 4) {
      const userId = localStorage.getItem('userId');
      const childData = {
        name,
        age,
        gender,
        selectedDisorders,
        englishLevel: englishLevel || '',
        improveArea: improveArea || '',
        likesDetails: likesDetails || {},
      };

      try {
        setRegistering(true);
        if (userId) {
          const response = await addChild(userId, childData);
          setChildProfile(response.child);

          if (response.childId && response.parentId) {
            localStorage.setItem('childId', response.childId);
            localStorage.setItem('parentId', response.parentId);
          } else {
            console.error('Erro: ID do estudante não encontrado.');
            return;
          }
        }
      } catch (error) {
        console.error('Erro ao adicionar filho:', error);
        setError('Ocorreu um erro ao registrar a criança.');
        return;
      } finally {
        setRegistering(false);
      }
    }

    // Avança para o próximo passo se não estiver no último
    if (currentStep < 5) {
      setError(''); // Limpa o erro se todos os campos estiverem preenchidos
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    setError('');

    if (currentStep === 3 && isConfirmed === true) {
      setIsConfirmed(false);
      return;
    }
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleRegistration = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    const childId = localStorage.getItem('childId');
    const parentId = localStorage.getItem('parentId');

    if (!childId || !parentId) {
      setError('Erro ao agendar: criança não registrada.');
      return;
    }

    if (isEmpty(selectedDates) || isEmpty(selectedTimes)) {
      setError('Por favor seleccione al menos una fecha y hora');
      return;
    }

    const selectedDaysWithWeekday = selectedDates.map((date) => ({
      date,
      dayOfWeek: date.getDay(),
    }));

    const requestData = {
      selectedDays: selectedDaysWithWeekday,
      selectedTimes,
    };

    setIsLoading(true);

    try {
      // Verifica a disponibilidade dos professores
      const teachersResponse = await checkAvailability(requestData);
      if (childProfile) {
        const resAI = await recommendTeacher(childProfile, requestData, {
          availableTeachers: teachersResponse.availableTeachers,
        });

        if (
          resAI &&
          resAI.selected_teacher &&
          resAI.scheduled_start &&
          resAI.scheduled_end
        ) {
          const { selected_teacher, scheduled_start, scheduled_end } = resAI;

          const lessonData = {
            teacherId: selected_teacher,
            studentId: childId,
            parentId: parentId,
            startTime: scheduled_start,
            endTime: scheduled_end,
          };

          console.log(lessonData);

          const scheduledLesson = await scheduleLesson(lessonData);
          if (scheduledLesson.scheduledLesson) {
            navigate('/portal');
          }
        } else {
          console.log('Nenhuma sessão disponível para agendamento.');
        }
      }
    } catch (error) {
      console.error('Erro ao verificar disponibilidade ou agendar:', error);
      setError(
        'No hay profesores disponibles para las fechas seleccionadas, elija más días y horarios.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggleLikeSelection = (like: string) => {
    if (selectedLikes.includes(like)) {
      setSelectedLikes(selectedLikes.filter((item) => item !== like));
    } else {
      setSelectedLikes([...selectedLikes, like]);
    }
  };

  const handleDateClick = (date: Date) => {
    if (
      selectedDates.some(
        (selectedDate) => selectedDate.toDateString() === date.toDateString()
      )
    ) {
      // Remove o dia se já estiver selecionado
      setSelectedDates(
        selectedDates.filter(
          (selectedDate) => selectedDate.toDateString() !== date.toDateString()
        )
      );
    } else {
      // Adiciona o dia se não estiver selecionado
      setSelectedDates([...selectedDates, date]);
    }
  };

  // Função para gerenciar as mudanças na lista de desordens
  const handleDisorderChange = (disorder: string) => {
    if (disorder === 'No tiene ningún trastorno') {
      // Se "No tiene ningún trastorno" for marcado/desmarcado
      if (selectedDisorders.includes(disorder)) {
        setSelectedDisorders([]); // Desmarcar "No tiene ningún trastorno" limpa tudo
      } else {
        setSelectedDisorders(['No tiene ningún trastorno']); // Marcar e limpar os outros
        setIsOtherDisorderActive(false);
        setOtherDisorder('');
      }
    } else if (disorder === 'Otro') {
      // Se "Otro" for marcado/desmarcado
      if (isOtherDisorderActive) {
        setIsOtherDisorderActive(false); // Desmarcar "Otro"
        setSelectedDisorders(
          selectedDisorders.filter((item) => item !== 'Otro')
        );
      } else {
        setIsOtherDisorderActive(true); // Marcar "Otro"
        setSelectedDisorders([
          ...selectedDisorders.filter(
            (item) => item !== 'No tiene ningún trastorno'
          ),
          'Otro',
        ]);
      }
    } else {
      // Outros transtornos
      if (selectedDisorders.includes(disorder)) {
        // Desmarcar se já estiver selecionado
        setSelectedDisorders(
          selectedDisorders.filter((item) => item !== disorder)
        );
      } else {
        // Marcar transtorno e remover "No tiene ningún trastorno" se necessário
        setSelectedDisorders([
          ...selectedDisorders.filter(
            (item) => item !== 'No tiene ningún trastorno'
          ),
          disorder,
        ]);
      }
    }
  };

  const handleOtherDisorderBlur = () => {
    if (otherDisorder.trim() !== '') {
      // Adiciona o valor ao selectedDisorders se não estiver lá ainda
      if (!selectedDisorders.includes(otherDisorder)) {
        setSelectedDisorders([...selectedDisorders, otherDisorder]);
      }
    }
  };

  const handleConfirm = () => {
    if (!isConfirmed) {
      if (selectedLikes.length === 0) {
        setError(
          'Por favor, seleccione al menos una opción antes de continuar.'
        );
        return;
      }
      setError(''); // Limpa o erro se uma seleção foi feita
      setIsConfirmed(true);
    } else {
      handleNext();
    }
  };

  const handleDetailChange = (like: string, value: string) => {
    setLikesDetails((prevDetails) => ({
      ...prevDetails,
      [like]: value,
    }));
  };

  const getNextSevenDays = () => {
    return Array.from({ length: 7 }, (_, index) => {
      const day = addDays(today, index + 1);
      return {
        date: day,
        dayOfWeek: format(day, 'EE', { locale: es }).slice(0, 2).toUpperCase(),
        dayOfMonth: format(day, 'd'),
      };
    });
  };

  const nextSevenDays = getNextSevenDays();

  const handleTimeClick = (time: string) => {
    if (selectedTimes.includes(time)) {
      // Remove o horário se já estiver selecionado
      setSelectedTimes(
        selectedTimes.filter((selectedTime) => selectedTime !== time)
      );
    } else {
      // Adiciona o horário se não estiver selecionado
      setSelectedTimes([...selectedTimes, time]);
    }
  };

  const renderTimeSlots = () => {
    const timeSlots = [
      { label: '15:00', value: '14:00:00.000Z' },
      { label: '15:30', value: '14:30:00.000Z' },
      { label: '16:00', value: '15:00:00.000Z' },
      { label: '16:30', value: '15:30:00.000Z' },
      { label: '17:00', value: '16:00:00.000Z' },
      { label: '17:30', value: '16:30:00.000Z' },
      { label: '18:00', value: '17:00:00.000Z' },
      { label: '18:30', value: '17:30:00.000Z' },
      { label: '19:00', value: '18:00:00.000Z' },
      { label: '19:30', value: '18:30:00.000Z' },
      { label: '20:00', value: '19:00:00.000Z' },
      { label: '20:30', value: '19:30:00.000Z' },
    ];

    return (
      <div className="flex flex-wrap gap-2 justify-center mb-6 w-full">
        {timeSlots.map((slot, index) => (
          <label
            key={index}
            className={`flex items-center justify-center w-[114px] font-bold text-[#334155] text-[14px] h-[36px] border-2 rounded-3xl cursor-pointer transition-colors font-mont duration-300 ${
              selectedTimes.includes(slot.value)
                ? 'bg-[#059669] border-[#34D399] text-white'
                : 'bg-white text-gray-500 border-gray-300'
            }`}
            style={{ flexBasis: 'calc(16.66% - 8px)' }}
          >
            <input
              type="checkbox"
              checked={selectedTimes.includes(slot.value)}
              onChange={() => handleTimeClick(slot.value)}
              className="hidden"
            />
            {slot.label}
          </label>
        ))}
      </div>
    );
  };

  const handleEnglishLevelChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setEnglishLevel(event.target.value);
  };

  const handleImproveAreaChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setImproveArea(event.target.value);
  };

  return (
    <div className="relative min-h-screen">
      {/* Overlay de carregamento */}
      {isLoading && (
        <div
          className="absolute inset-0 flex items-center justify-center z-50"
          style={{ backgroundColor: '#0F172A99' }} // Aplica a cor e opacidade ao fundo
        >
          <OnboardingLoading />
        </div>
      )}
      {registering && (
        <div
          className="absolute inset-0 flex items-center justify-center z-50"
          style={{ backgroundColor: '#0F172A99' }} // Aplica a cor e opacidade ao fundo
        >
          <RegisteringLoading />
        </div>
      )}
      <div
        className={`relative z-10 ${isLoading ? 'opacity-50' : ''} min-h-screen max-h-[890px] w-screen flex items-center justify-center overflow-hidden`}
      >
        <div className="flex w-full h-full items-center justify-center">
          <div className="flex items-center justify-center mx-auto max-h-[890px]">
            <div className="hidden md:flex items-center justify-center max-h-[890px] overflow-hidden">
              <img
                src={onboardingImage}
                alt="Descrição da imagem"
                className="object-cover w-[700px] max-h-[890px] h-auto opacity-100 rounded-2xl"
              />
            </div>

            <div className="flex flex-col items-start justify-between w-full max-w-[629px] h-full max-h-[890px] pl-[48px] gap-[72px] opacity-100">
              <div className="flex flex-col items-center w-full h-full mt-5">
                <img
                  src={logo}
                  alt="Logo"
                  className="max-w-full w-[75.16px] h-[80px] mb-[36px]"
                />

                {/* Cabeçalho com espaçamento */}
                <div className="flex flex-col items-center w-full gap-[8px] mt-[40px] mb-[8px]">
                  <h2 className="text-[36px] font-mont font-extrabold leading-[36px] text-center text-[#0F172A]">
                    Cuéntanos un poco más
                  </h2>
                  <p className="text-[16px] font-bold font-nunito leading-[19.2px] text-center text-[#334155]">
                    Ya casi estás! Antes de empezar, necesitamos saber algunas
                    cosas de tu peque
                  </p>
                  <ProgressIndicator currentStep={currentStep} />
                </div>

                {/* Contêiner das Etapas com espaçamento para evitar sobreposição */}
                <div className="relative flex flex-col items-center w-full h-[750px] overflow-hidden mt-11 mb-11">
                  {/* Step 1 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
                      currentStep === 1
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform -translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    <form className="w-full flex flex-col items-center gap-[8px]">
                      <div className="mb-4 flex flex-col">
                        <div className="flex items-center gap-4 justify-between mb-8">
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="gender"
                              value="niño"
                              checked={gender === 'niño'}
                              onChange={handleGenderChange}
                              className="hidden" // Esconde o input original
                            />
                            <div
                              className={`h-5 w-5 border-2 rounded-full flex items-center justify-center transition-colors duration-300 ${
                                gender === 'niño'
                                  ? 'bg-[#059669] border-[#059669]'
                                  : 'border-gray-300'
                              }`}
                            >
                              {gender === 'niño' && (
                                <SolarIconSet.CheckCircle
                                  size={20}
                                  color="#FFFFFF"
                                />
                              )}
                            </div>
                            <span className="text-[16px] font-bold text-[#64748B] font-nunito">
                              Es un niño
                            </span>
                          </label>

                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="gender"
                              value="niña"
                              checked={gender === 'niña'}
                              onChange={handleGenderChange}
                              className="hidden" // Esconde o input original
                            />
                            <div
                              className={`w-5 h-5 border-2 rounded-full flex items-center justify-center transition-colors duration-300 ${
                                gender === 'niña'
                                  ? 'bg-[#059669] border-[#059669]'
                                  : 'border-gray-300'
                              }`}
                            >
                              {gender === 'niña' && (
                                <SolarIconSet.CheckCircle
                                  size={20}
                                  color="#FFFFFF"
                                />
                              )}
                            </div>
                            <span className="text-[16px] font-bold text-[#64748B] font-nunito">
                              Es una niña
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="name"
                          className="w-[340px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          ¿Como se llama?
                        </label>
                        <input
                          type="text"
                          className="w-[340px] h-[44px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          placeholder="El nombre de tu peque"
                          value={name}
                          onChange={handleNameChange}
                          required
                        />
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="age"
                          className="w-[340px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          ¿Qué edad tiene?
                        </label>
                        <select
                          name="age"
                          className="w-[340px] h-[44px] pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] font-mont placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={age}
                          onChange={handleAgeChange}
                          required
                        >
                          <option value="" className="text-slate-700">
                            Selecciona una edad
                          </option>
                          <option value="5" className="text-slate-700">
                            5
                          </option>
                          <option value="6" className="text-slate-700">
                            6
                          </option>
                          <option value="7" className="text-slate-700">
                            7
                          </option>
                          <option value="8" className="text-slate-700">
                            8
                          </option>
                          <option value="9" className="text-slate-700">
                            9
                          </option>
                          <option value="10" className="text-slate-700">
                            10
                          </option>
                          <option value="11" className="text-slate-700">
                            11
                          </option>
                          <option value="12" className="text-slate-700">
                            12
                          </option>
                        </select>
                      </div>
                      {error && (
                        <div
                          className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                          style={{
                            boxShadow:
                              '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                          }}
                          role="alert"
                        >
                          <SolarIconSet.DangerCircle
                            iconStyle="BoldDuotone"
                            size={24}
                            color="#EF4444"
                          />
                          <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                            {error}
                          </span>
                        </div>
                      )}

                      <button
                        type="button"
                        onClick={handleNext}
                        className="flex items-center justify-center w-[318px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition mt-20 font-mont"
                        style={{
                          boxShadow: '0px 1px 2px 0px #0000000D',
                        }}
                      >
                        PRUEBA UNA CLASE GRATUITA
                        <SolarIconSet.RoundAltArrowRight
                          size={20}
                          iconStyle="Bold"
                          className="ml-[6px]"
                        />
                      </button>
                    </form>
                  </div>
                  {/* Fim da Etapa 1 */}
                  {/* Step 2 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
                      currentStep === 2
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    <div className="w-full flex flex-col items-center gap-[8px]">
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="englishLevel"
                          className="w-[340px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          ¿Cuál es su nivel de inglés actual?
                        </label>
                        <select
                          name="englishLevel"
                          className="w-[340px] h-[44px] pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] font-mont placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={englishLevel}
                          onChange={handleEnglishLevelChange}
                          required
                        >
                          <option value="" className="text-gray-400">
                            Selecciona una opción
                          </option>
                          <option value="beginner" className="text-slate-700">
                            No sabe nada de inglés
                          </option>
                          <option value="basic" className="text-slate-700">
                            Sabe algunas palabras sueltas
                          </option>
                          <option
                            value="intermediate"
                            className="text-slate-700"
                          >
                            Sabe formular algunas frases enteras y lo entiende
                            un poco
                          </option>
                        </select>
                      </div>
                      <div className="mb-4 flex flex-col">
                        <label
                          htmlFor="improveArea"
                          className="w-[340px] h-[19px] font-nunito px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#64748B] opacity-100"
                        >
                          ¿En qué área crees que necesita mejorar?
                        </label>
                        <select
                          name="improveArea"
                          className="w-[340px] h-[44px] font-mont pl-4 py-2 bg-white border border-t border-gray-300 rounded-md opacity-100 placeholder:text-[#9CA3AF] placeholder:font-mont placeholder:text-[14px] placeholder:leading-[20px] shadow-inner"
                          style={{
                            boxShadow: 'inset 0px 2px 5px 0px #0000001A',
                          }}
                          value={improveArea}
                          onChange={handleImproveAreaChange}
                          required
                        >
                          <option value="" className="text-gray-400">
                            Selecciona una opción
                          </option>
                          <option value="grammar" className="text-slate-700">
                            Gramática
                          </option>
                          <option value="vocabulary" className="text-slate-700">
                            Vocabulario
                          </option>
                          <option value="Reading" className="text-slate-700">
                            Lectura
                          </option>
                          <option value="Writing" className="text-slate-700">
                            Escritura
                          </option>
                          <option value="Speaking" className="text-slate-700">
                            Conversación
                          </option>
                          <option value="all" className="text-slate-700">
                            Todas las opciones anteriores
                          </option>
                        </select>
                      </div>

                      {error && (
                        <div
                          className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                          style={{
                            boxShadow:
                              '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                          }}
                          role="alert"
                        >
                          <SolarIconSet.DangerCircle
                            iconStyle="BoldDuotone"
                            size={24}
                            color="#EF4444"
                          />
                          <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                            {error}
                          </span>
                        </div>
                      )}

                      {/* Botão para avançar para o próximo passo */}
                      <div className="flex w-full justify-between mt-20">
                        <button
                          onClick={handlePrevious}
                          className="flex items-center font-mont text-[14px] font-bold leading-[19.2px] text-center text-[#64748B] cursor-pointer"
                        >
                          <SolarIconSet.ArrowLeft size={20} color="#64748B" />
                          Paso Anterior
                        </button>

                        <button
                          type="button"
                          onClick={handleNext}
                          className="flex items-center justify-center w-[253px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition font-mont"
                          style={{
                            boxShadow: '0px 1px 2px 0px #0000000D',
                          }}
                        >
                          SIGUIENTE PASO
                          <SolarIconSet.RoundAltArrowRight
                            size={20}
                            iconStyle="Bold"
                            className="ml-[6px]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Final Step 2 */}
                  {/* Step 3 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out flex flex-col items-center ${
                      currentStep === 3
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    <p className="text-[16px] font-bold font-nunito text-center text-[#64748B] mb-4">
                      ¿Qué le gusta a {name}?
                    </p>

                    {/* Renderização das opções selecionáveis ou dos inputs de texto com imagens */}
                    {!isConfirmed ? (
                      <div className="grid grid-cols-3 gap-4 mb-6">
                        {childLikes.map((like, index) => (
                          <div
                            key={index}
                            onClick={() => toggleLikeSelection(like.name)}
                            className={`flex flex-col items-center p-2 border-2 rounded-lg shadow-md hover:shadow-lg transition cursor-pointer ${
                              selectedLikes.includes(like.name)
                                ? 'border-[#059669]'
                                : 'border-gray-300'
                            }`}
                          >
                            <img
                              src={like.image}
                              alt={like.name}
                              className="w-[154.95px] h-[120px] object-cover rounded-full"
                            />
                            <p className="text-[12px] font-nunito font-medium text-center text-[#64748B] mt-2">
                              {like.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-col gap-4">
                        {selectedLikes.map((like, index) => {
                          const likeItem = childLikes.find(
                            (item) => item.name === like
                          );

                          // Renderiza apenas se o item correspondente for encontrado
                          return likeItem ? (
                            <div
                              key={index}
                              className="flex items-center gap-4"
                            >
                              <img
                                src={likeItem.image}
                                alt={like}
                                className="w-16 h-16 object-cover"
                              />
                              <input
                                type="text"
                                placeholder={`Cuéntanos más sobre ${like.toLowerCase()} que le gustan a ${name}...`}
                                value={likesDetails[like] || ''}
                                onChange={(e) =>
                                  handleDetailChange(like, e.target.value)
                                }
                                className="flex-1 p-2 border border-gray-300 rounded-md w-[492.69px] h-[56px]"
                              />
                            </div>
                          ) : null;
                        })}
                      </div>
                    )}

                    {error && (
                      <div
                        className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner mt-4"
                        style={{
                          boxShadow:
                            '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                        }}
                        role="alert"
                      >
                        <SolarIconSet.DangerCircle
                          iconStyle="BoldDuotone"
                          size={24}
                          color="#EF4444"
                        />
                        <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                          {error}
                        </span>
                      </div>
                    )}

                    {/* Botões de navegação */}
                    <div className="flex w-full justify-between mt-10">
                      <button
                        onClick={handlePrevious}
                        className="flex items-center font-mont text-[14px] font-bold leading-[19.2px] text-center text-[#64748B] cursor-pointer"
                      >
                        <SolarIconSet.ArrowLeft size={20} color="#64748B" />
                        {isConfirmed
                          ? 'Volver a la selección'
                          : 'Paso Anterior'}
                      </button>

                      <button
                        type="button"
                        onClick={handleConfirm}
                        className="flex items-center justify-center w-[253px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary font-mont hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition"
                        style={{
                          boxShadow: '0px 1px 2px 0px #0000000D',
                        }}
                      >
                        {isConfirmed ? 'Siguiente Paso' : 'CONFIRMA'}
                        <SolarIconSet.RoundAltArrowRight
                          size={20}
                          iconStyle="Bold"
                          className="ml-[6px]"
                        />
                      </button>
                    </div>
                  </div>
                  {/* Final Step 3 */}
                  {/* Step 4 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out flex flex-col items-center ${
                      currentStep === 4
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    {/* Contêiner para alinhar a frase com os checkboxes */}
                    <div className="flex flex-col gap-4 items-start mb-6">
                      <p className="text-[16px] font-bold font-nunito text-left text-[#64748B]">
                        ¿Tiene tu peque algún trastorno del aprendizaje
                        diagnosticado?
                      </p>

                      <div className="flex flex-col gap-4">
                        {disorders.map((disorder, index) => (
                          <div key={index} className="flex flex-col gap-2">
                            <div className="flex items-center gap-2">
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  value={disorder}
                                  checked={
                                    disorder === 'Otro'
                                      ? isOtherDisorderActive
                                      : selectedDisorders.includes(disorder)
                                  }
                                  onChange={() =>
                                    handleDisorderChange(disorder)
                                  }
                                  className="appearance-none w-5 h-5 border-2 rounded-full checked:bg-[#059669] checked:border-[#059669] focus:outline-none focus:ring-0"
                                />

                                {((disorder === 'Otro' &&
                                  isOtherDisorderActive) ||
                                  selectedDisorders.includes(disorder)) && (
                                  <div className="absolute inset-0 flex justify-center items-center">
                                    <SolarIconSet.CheckCircle
                                      size={20}
                                      color="#FFFFFF"
                                      className="mb-[6px]"
                                    />
                                  </div>
                                )}
                              </div>

                              <p className="text-[#64748B] font-nunito text-[16px] font-bold">
                                {disorder}
                              </p>
                            </div>

                            {disorder === 'Otro' && isOtherDisorderActive && (
                              <input
                                type="text"
                                placeholder="¿Que trastorno del aprendizaje es?"
                                value={otherDisorder}
                                onChange={(e) =>
                                  setOtherDisorder(e.target.value)
                                }
                                onBlur={handleOtherDisorderBlur}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-[393px] h-[56px]"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {error && (
                      <div
                        className="flex items-center w-[340px] h-[44px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                        style={{
                          boxShadow:
                            '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                        }}
                        role="alert"
                      >
                        <SolarIconSet.DangerCircle
                          iconStyle="BoldDuotone"
                          size={24}
                          color="#EF4444"
                        />
                        <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                          {error}
                        </span>
                      </div>
                    )}

                    <div className="flex w-full justify-between mt-20">
                      <button
                        onClick={handlePrevious}
                        className="flex items-center font-mont text-[14px] font-bold leading-[19.2px] text-center text-[#64748B] cursor-pointer"
                      >
                        <SolarIconSet.ArrowLeft size={20} color="#64748B" />
                        Paso Anterior
                      </button>

                      <button
                        type="button"
                        onClick={handleNext}
                        className="flex items-center justify-center w-[253px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary font-mont hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition"
                        style={{
                          boxShadow: '0px 1px 2px 0px #0000000D',
                        }}
                      >
                        ÚLTIMO PASO
                        <SolarIconSet.RoundAltArrowRight
                          size={20}
                          iconStyle="Bold"
                          className="ml-[6px]"
                        />
                      </button>
                    </div>
                  </div>
                  {/* Final Step 4 */}
                  {/* Step 5 */}
                  <div
                    className={`absolute inset-0 transition-transform duration-500 ease-in-out flex flex-col items-center ${
                      currentStep === 5
                        ? 'transform translate-x-0 opacity-100'
                        : 'transform translate-x-full opacity-0 pointer-events-none'
                    }`}
                  >
                    {/* Título com o mês e o ano */}
                    <p className="text-[16px] font-bold font-nunito text-center text-[#64748B] mb-6">
                      Elige una fecha{' '}
                      <span className="text-[#334155] font-nunito">
                        {monthYear}
                      </span>
                    </p>

                    {/* Renderização dos próximos sete dias */}
                    <div className="flex gap-2 justify-center">
                      {nextSevenDays.map((day, index) => {
                        const isSelected = selectedDates.some(
                          (selectedDate) =>
                            selectedDate.toDateString() ===
                            day.date.toDateString()
                        );
                        return (
                          <div
                            key={index}
                            onClick={() => handleDateClick(day.date)}
                            className={`flex flex-col items-center justify-center font-nunito font-bold w-[72px] h-[80px] border-2 rounded-3xl cursor-pointer transition-colors duration-300 ${
                              isSelected
                                ? 'bg-[#059669] border-[#34D399] text-white text-[16px]'
                                : 'border-[#E5E7EB] text-[#94A3B8]'
                            }`}
                          >
                            <p className="font-bold font-nunito">
                              {day.dayOfWeek}
                            </p>
                            <p className="font-bold font-nunito">
                              {day.dayOfMonth}
                            </p>
                          </div>
                        );
                      })}
                    </div>

                    {/* Botões de seleção de período */}
                    <p className="text-[16px] font-bold font-nunito text-center text-[#64748B] mb-6 mt-10">
                      Elige la hora
                    </p>
                    {/* Renderização dos horários com base no período selecionado */}
                    {renderTimeSlots()}

                    {error && (
                      <div
                        className="flex items-center w-[340px] h-[60px] pl-4 py-2 gap-2 bg-[#FEF2F2] border border-[#EF4444] rounded-md shadow-inner"
                        style={{
                          boxShadow:
                            '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                        }}
                        role="alert"
                      >
                        <SolarIconSet.DangerCircle
                          iconStyle="BoldDuotone"
                          size={24}
                          color="#EF4444"
                        />
                        <span className="font-mont font-semibold text-[14px] leading-[20px] text-[#EF4444]">
                          {error}
                        </span>
                      </div>
                    )}

                    <div className="flex w-full justify-between mt-20">
                      <button
                        onClick={handlePrevious}
                        className="flex items-center font-mont text-[14px] font-bold leading-[19.2px] text-center text-[#64748B] cursor-pointer"
                      >
                        <SolarIconSet.ArrowLeft size={20} color="#64748B" />
                        Paso Anterior
                      </button>

                      <button
                        type="button"
                        onClick={handleRegistration}
                        className="flex items-center justify-center w-[253px] h-[52px] py-[16px] px-[32px] bg-[#34D399] text-white font-extrabold text-[14px] leading-[17.89px] rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-secondary font-mont hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition"
                        style={{
                          boxShadow: '0px 1px 2px 0px #0000000D',
                        }}
                      >
                        RESERVA TU CLASE
                        <SolarIconSet.RoundAltArrowRight
                          size={20}
                          iconStyle="Bold"
                          className="ml-[6px]"
                        />
                      </button>
                    </div>
                  </div>
                  {/* Final Step 5*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentOnboarding;
