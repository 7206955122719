import React, { useEffect, useState } from 'react';
import {
  StudentProfile,
  TeacherProfile,
  User,
} from '../../../@types/usersTypes';
import { getTeachers } from '../../../service/teacher/getTeachers.service';
import LoadingSpinner from '../../Loading/Spinner/Spinner';
import circle from '../../../assets/circle.png';
import closeIcon from '../../../assets/close.svg';
import play from '../../../assets/play.png';
import * as SolarIconSet from 'solar-icon-set';
import Filtros from './Filters';
import YouTube, { YouTubeProps } from 'react-youtube';

interface CalendarProps {
  user: User | null;
  selectedChild: StudentProfile | null;
  setActivePage: (page: string) => void;
  setSelectedTeacher: (teacher: TeacherProfile) => void;
}

const Teachers: React.FC<CalendarProps> = ({
  // user,
  selectedChild,
  setActivePage,
  setSelectedTeacher,
}) => {
  const [teachers, setTeachers] = useState<TeacherProfile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [teacherVideo, setTeacherVideo] = useState('');

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '630',
    width: '985',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const fetchTeachers = async () => {
    setIsLoading(true);
    try {
      const response = await getTeachers();
      setTeachers(response);
    } catch (error) {
      console.error('Erro ao buscar professores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  let hasScheduledLesson;
  let totalLessons;
  if (selectedChild) {
    hasScheduledLesson = selectedChild?.scheduledLessons?.length > 0;
    totalLessons = selectedChild?.totalLessons || 0;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Função para pegar a próxima aula do filho selecionado
  const getNextLessonForChild = (child: StudentProfile | null) => {
    if (!child || !child.scheduledLessons) return null;

    if (child.scheduledLessons.length) {
      // Ordenar as aulas por data para pegar a próxima
      const sortedLessons = [...child.scheduledLessons].sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      return sortedLessons[0]; // Retorna a próxima aula
    }
    return null; // Caso não tenha aulas
  };

  // Aula filtrada para o filho selecionado
  const nextLesson = getNextLessonForChild(selectedChild);

  return (
    <div className="flex max-w-[1160px] h-full overflow-hidden mt-10">
      {showVideoModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col gap-4 absolute bg-white border border-[#E5E7EB] shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_8px_10px_-6px_rgba(0,0,0,0.1)] rounded-[6px] z-50 p-[24px]">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-[#0F172A] font-bold text-[18px] font-nunito leading-[18px]">
                <p className="font-nunito font-bold text-[18px] leading-[18px] text-[#0F172A]">
                  {`Introdución de ${teacherVideo}`}
                </p>
              </h4>
              <button onClick={() => setShowVideoModal(false)}>
                <img src={closeIcon} alt="Close Icon" width="24" height="24" />
              </button>
            </div>
            <YouTube
              videoId="ez8m4PXksQs"
              opts={opts}
              onReady={onPlayerReady}
            />
          </div>
        </div>
      )}
      <div className="flex-grow bg-[#F3F4F6] overflow-y-auto w-[1160px] mx-auto mb-20">
        {/* Primeira Section com fundo verde */}
        <section className="w-full h-[116px] bg-[#34D399] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] flex items-center justify-start">
          {hasScheduledLesson ? (
            <div className="text-white flex items-center justify-between w-full px-6">
              <div className="text-start">
                <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                  ¡{selectedChild?.childName} tu clase está reservada!
                </h3>
                <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                  Entra a clase 15 minutos antes para configurar la cámara y el
                  micrófono.
                </p>
              </div>

              {nextLesson &&
                (() => {
                  const lessonStartTime = new Date(nextLesson.startTime);
                  const currentTime = new Date();
                  const timeDifference =
                    lessonStartTime.getTime() - currentTime.getTime();
                  const fifteenMinutesInMs = 15 * 60 * 1000; // 15 minutos em milissegundos

                  // Verifica se estamos no intervalo de 15 minutos antes da aula
                  const isWithin15Minutes =
                    timeDifference <= fifteenMinutesInMs && timeDifference > 0;

                  return (
                    <button
                      className={`font-mont font-extrabold text-[14px] leading-[17.89px] w-[228px] h-[38px] px-4 py-2 rounded-md ${
                        isWithin15Minutes
                          ? 'bg-[#FFFFFF] text-[#065F46] hover:bg-[#059669] hover:text-white cursor-pointer border-2 border-[#069668]'
                          : 'bg-[#FFFFFF] text-[#065F46]'
                      } shadow-[0px_1px_2px_0px_#0000000D]`} // Sombra adicionada
                      disabled={!isWithin15Minutes} // Desabilita o clique fora do intervalo
                    >
                      {isWithin15Minutes
                        ? 'Unirme Ahora'
                        : `Entrar a clase a las ${new Date(
                            lessonStartTime.getTime() - 15 * 60000 // Subtrai 15 minutos
                          ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`}
                    </button>
                  );
                })()}
            </div>
          ) : totalLessons === 0 ? (
            <div className="text-white text-start ml-6">
              <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                ¡Reserva tu clase de prueba gratuita!
              </h3>
              <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                Conoce a nuestros profesores y elige el horario que mejor se
                adapte a ti.
              </p>
            </div>
          ) : (
            <div className="text-white text-start ml-6">
              <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                ¡Reserva ahora tu clase!
              </h3>
              <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                Elige uno de nuestros profesores y la fecha que más te convenga.
              </p>
            </div>
          )}
        </section>

        <Filtros />

        {/* Segunda Section com duas divs */}
        <section className="space-y-4 mb-4">
          {isLoading ? (
            <p>Carregando professores...</p>
          ) : (
            teachers.map((teacher) => (
              <div
                key={teacher.id}
                className="w-full h-[238px] bg-white border border-gray-200 border-l-[#059669] border-l-4 rounded-2xl flex items-center"
                style={{
                  boxShadow:
                    '0px 1px 3px 0px #0000001A, 0px 1px 2px -1px #0000001A',
                }}
              >
                {/* Div Esquerda */}
                <div className="w-[788px] h-[190px] flex flex-col justify-center pl-4  mr-4">
                  <h2 className="mt-6 font-mont font-extrabold text-[30px] leading-[38.34px] text-[#0F172A]">
                    {teacher.fullName}
                  </h2>
                  <p className="mt-[6px] font-nunito font-medium text-[12px] leading-[16.37px] text-[#0F172A]">
                    <SolarIconSet.MapPoint size={14} color="#059669" />{' '}
                    {teacher.countryOfResidence}
                  </p>
                  <p className="mt-1 font-nunito font-medium text-[12px] leading-[16.37px] text-[#0F172A]">
                    <SolarIconSet.VolumeLoud size={14} color="#059669" />
                  </p>
                  <p className="mt-1 font-nunito font-medium text-[12px] leading-[16.37px] text-[#0F172A]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt ...
                  </p>
                  <button
                    className="flex items-center justify-center w-[191px] h-[44px] py-[16px] bg-[#34D399] text-white font-bold text-[14px] leading-[20px] rounded-md  shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition font-mont mt-[14px]"
                    style={{
                      boxShadow: '0px 1px 2px 0px #0000000D',
                    }}
                    onClick={() => {
                      setSelectedTeacher(teacher);
                      setActivePage('TeacherCalendar');
                    }}
                  >
                    Reservar una Clase
                  </button>
                </div>

                {/* Div Direita */}
                <div className="h-full relative flex items-center justify-center pr-4">
                  <img
                    src={teacher.profilePicture || ''}
                    alt={`${teacher.fullName}'s profile`}
                    className="w-[308px] h-[188px] object-cover rounded-2xl"
                  />
                  {/* Ícone de Play sobreposto */}
                  <button
                    onClick={() => {
                      setShowVideoModal(true);
                      setTeacherVideo(teacher.fullName);
                    }}
                    className="absolute inset-0 flex items-center justify-center"
                    aria-label="Play Video"
                  >
                    {/* Círculo vazado */}
                    <img
                      src={circle} // Caminho da imagem do círculo vazado
                      alt="Circle Icon"
                      className="h-[41.67px] w-[41.67px]" // Ajuste o tamanho conforme necessário
                    />
                    {/* Ícone de Play sobreposto ao círculo */}
                    <img
                      src={play} // Caminho da imagem do ícone de play
                      alt="Play Icon"
                      className="absolute h-[16.67px] w-[12.5px]" // Centraliza o ícone de play dentro do círculo
                    />
                  </button>
                </div>
              </div>
            ))
          )}
        </section>
      </div>
    </div>
  );
};

export default Teachers;
