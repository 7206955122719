import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getPassword = async (token: string, password: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/client/endpoints/user/profile/verify-password`,
      { password }, // Corrigido para enviar como um objeto
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao consultar senha:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao consultar a senha:', error);
    }
    throw error;
  }
};
