import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getUser = async (userId: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/client/endpoints/user/profile/${userId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao fazer login:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao retornar usuario:', error);
    }
    throw error;
  }
};
