import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../service/auth/user/getUser.service';
import axios from 'axios';
import { User } from '../../@types/usersTypes';
import { avatarUrls } from '../../constants/avatarUrls';
import { updateUser } from '../../service/auth/user/updateUser.service';
import { getPassword } from '../../service/auth/user/getPassword.service';
import LoadingSpinner from '../Loading/Spinner/Spinner';

const UserProfile: React.FC = () => {
  const [passwordChanged, setPasswordChanged] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [editAvatar, setEditAvatar] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState<string | null>(null);
  const [newAvatar, setNewAvatar] = useState<string | null>(null);
  const [lastSavedAvatar, setLastSavedAvatar] = useState<string | null>(null);
  const [editEmail, setEditEmail] = useState(false);
  const [newEmail, setNewEmail] = useState<string | null>(null);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);

  // Novos estados para campos de professores
  const [editDescription, setEditDescription] = useState(false);
  const [newDescription, setNewDescription] = useState<string | null>(null);
  const [editAvailability, setEditAvailability] = useState(false);
  const [newAvailability, setNewAvailability] = useState<string | null>(null);

  const token = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        if (userId) {
          const response = await getUser(userId);
          setPasswordChanged(response.passwordChanged);
          setUser(response);
          setCurrentAvatar(response.profilePicture);
          setLastSavedAvatar(response.profilePicture);
          setNewDescription(response.Teacher?.description || '');
          setNewAvailability(response.Teacher?.availability || '');
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);

        if (axios.isAxiosError(error) && error.response?.status === 401) {
          localStorage.removeItem('authToken');
          localStorage.removeItem('userId');
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [token, userId, navigate]);

  const handleAvatarEdit = () => setEditAvatar(!editAvatar);

  const handleGenerateAvatar = async () => {
    const randomIndex = Math.floor(Math.random() * avatarUrls.length);
    const newAvatarUrl = avatarUrls[randomIndex];

    const previousAvatar = currentAvatar;

    try {
      setIsLoading(true);
      const response = await fetch(newAvatarUrl);

      if (response.status === 429) {
        console.warn(
          'Limite de requisições atingido. Mantendo o avatar anterior.'
        );
        setNewAvatar(previousAvatar);
      } else {
        setNewAvatar(newAvatarUrl);
        setCurrentAvatar(newAvatarUrl);
      }
    } catch (error) {
      console.error('Erro ao gerar novo avatar:', error);
      setNewAvatar(previousAvatar);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadAvatar = () => {
    // Lógica para fazer upload de uma nova imagem de avatar
  };

  const handleRevertAvatar = () => {
    setNewAvatar(lastSavedAvatar);
    setCurrentAvatar(lastSavedAvatar);
  };

  const handleSaveAvatar = () => {
    if (newAvatar) {
      setUser((prevUser) =>
        prevUser ? { ...prevUser, profilePicture: newAvatar } : prevUser
      );
      setLastSavedAvatar(newAvatar);
      setEditAvatar(false);
    }
  };

  const handleEmailEdit = () => setEditEmail(!editEmail);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(e.target.value);
  };

  const handleSaveEmail = () => {
    if (newEmail) {
      setUser((prevUser) =>
        prevUser ? { ...prevUser, email: newEmail } : prevUser
      );
      setEditEmail(false);
    }
  };

  const handleChangePassword = () => setChangePassword(!changePassword);

  const validateCurrentPassword = async () => {
    try {
      if (token && currentPassword) {
        setIsLoading(true);
        const response = await getPassword(token, currentPassword);
        if (response.message === 'Senha correta.') {
          setPasswordError(null);
          return true;
        } else {
          setPasswordError('Senha atual incorreta');
          return false;
        }
      }
    } catch (error) {
      console.log('Erro ao validar a senha atual:', error);
      setPasswordError('Erro ao validar a senha atual');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const validateNewPasswords = () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('As senhas não coincidem');
      return false;
    }
    setConfirmPasswordError(null);
    return true;
  };

  const handleSavePassword = async () => {
    if (passwordChanged) {
      const isCurrentPasswordValid = await validateCurrentPassword();
      if (!isCurrentPasswordValid) return;
    }

    const isNewPasswordsValid = validateNewPasswords();
    if (!isNewPasswordsValid) return;

    setNewPassword(newPassword);
    setPasswordChanged(true);
    setChangePassword(false);
  };

  const handleSaveDescription = () => {
    if (newDescription) {
      setUser((prevUser) => {
        if (prevUser && prevUser.Teacher) {
          return {
            ...prevUser,
            Teacher: {
              ...prevUser.Teacher,
              description: newDescription,
            },
          };
        }
        return prevUser;
      });
    }
    setEditDescription(false);
  };

  const handleSaveAvailability = () => {
    if (newAvailability) {
      setUser((prevUser) => {
        if (prevUser && prevUser.Teacher) {
          return {
            ...prevUser,
            Teacher: {
              ...prevUser.Teacher,
              availability: newAvailability,
            },
          };
        }
        return prevUser;
      });
    }
    setEditAvailability(false);
  };

  const handleSaveProfile = async () => {
    if (!userId) return;

    const updatedUser = {
      email: newEmail || user?.email,
      profilePicture: newAvatar || user?.profilePicture || undefined, // Evita null no profilePicture
      password: newPassword || undefined, // Envia undefined se a senha não for alterada
      passwordChanged,
      description: newDescription || undefined, // Envia undefined se não for um professor ou não houver mudanças
      availability: newAvailability || undefined, // Envia undefined se não for alterada
    };

    try {
      setIsLoading(true);
      const response = await updateUser(userId, updatedUser);
      navigate(`${response.redirectTo}`);
    } catch (error) {
      console.error('Erro ao salvar perfil:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const renderPasswordChangeSection = () => {
    return (
      <div className="mb-6 p-4 bg-gray-100 rounded-lg shadow-inner">
        {!passwordChanged ? (
          <>
            <button
              onClick={handleChangePassword}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
            >
              Cadastrar nova senha
            </button>
            {changePassword && (
              <div className="mt-4 space-y-3">
                <label htmlFor="password">Digite sua nova senha:</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Senha..."
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <label htmlFor="passwordCheck"></label>
                <input
                  type="password"
                  name="passwordCheck"
                  id="passwordCheck"
                  placeholder="Senha..."
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {confirmPasswordError && (
                  <p className="text-red-500 text-sm">{confirmPasswordError}</p>
                )}
                <button
                  onClick={handleSavePassword}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 w-full"
                >
                  Guardar nova senha
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            <button
              onClick={handleChangePassword}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
            >
              Alterar senha
            </button>
            {changePassword && (
              <div className="mt-4 space-y-3">
                <label htmlFor="currentPassword">Senha atual:</label>
                <input
                  type="password"
                  name="currentPassword"
                  placeholder="Senha..."
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                {passwordError && (
                  <p className="text-red-500 text-sm">{passwordError}</p>
                )}
                <label htmlFor="newPassword">Nova senha:</label>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Senha..."
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <label htmlFor="passwordCheck">
                  Digite novamente sua nova senha:
                </label>
                <input
                  type="password"
                  name="passwordCheck"
                  placeholder="Senha..."
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {confirmPasswordError && (
                  <p className="text-red-500 text-sm">{confirmPasswordError}</p>
                )}
                <button
                  onClick={handleSavePassword}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 w-full"
                >
                  Guardar nova senha
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex justify-center items-center bg-[#6DD2BF] min-h-screen">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg">
        <h1 className="text-2xl font-semibold mb-6 text-center">
          {user?.fullName}
        </h1>
        <div className="flex flex-col items-center mb-6">
          <div className="w-24 h-24 relative overflow-hidden rounded-full border-2 border-white mb-2">
            <img
              src={user?.profilePicture ?? 'path/to/default-image.jpg'}
              alt="Imagem de Perfil"
              className="w-full h-full object-cover object-center"
            />
          </div>
          <button
            onClick={handleAvatarEdit}
            className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
          >
            Editar imagem
          </button>
        </div>
        {editAvatar && (
          <div className="mb-6 p-4 bg-gray-100 rounded-lg shadow-inner flex flex-col items-center space-y-4">
            <div className="flex space-x-4">
              {user?.role === 'STUDENT' && (
                <button
                  onClick={handleGenerateAvatar}
                  className="bg-green-500 text-white py-1 px-4 rounded hover:bg-green-600"
                >
                  Gerar novo avatar
                </button>
              )}
              <button
                onClick={handleUploadAvatar}
                className="bg-yellow-500 text-white py-1 px-4 rounded hover:bg-yellow-600"
              >
                Fazer upload de foto
              </button>
            </div>
            <button
              onClick={handleRevertAvatar}
              className="bg-red-500 text-white py-1 px-4 rounded hover:bg-red-600"
            >
              Voltar à última imagem salva
            </button>
            <button
              onClick={handleSaveAvatar}
              className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
            >
              Salvar imagem
            </button>
          </div>
        )}
        <div className="mb-6 p-4 bg-gray-100 rounded-lg shadow-inner">
          <p className="text-lg text-gray-700">
            Email: {newEmail || user?.email}
          </p>
          <button
            onClick={handleEmailEdit}
            className="mt-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600 w-full"
          >
            Editar email
          </button>
          {editEmail && (
            <div className="mt-4">
              <label htmlFor="email">Novo e-Mail</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Digite o novo e-Mail"
                value={newEmail || ''}
                onChange={handleEmailChange}
                className="block w-full p-2 border border-gray-300 rounded"
              />
              <button
                onClick={handleSaveEmail}
                className="mt-2 bg-green-500 text-white py-1 px-4 rounded hover:bg-green-600 w-full"
              >
                Guardar
              </button>
            </div>
          )}
        </div>
        {/* Campos específicos para TEACHER */}
        {user?.role === 'TEACHER' && (
          <>
            <div className="mb-6 p-4 bg-gray-100 rounded-lg shadow-inner">
              <h2 className="text-lg font-semibold mb-2">Descrição</h2>
              {editDescription ? (
                <>
                  <textarea
                    value={newDescription || ''}
                    onChange={(e) => setNewDescription(e.target.value)}
                    className="block w-full p-2 border border-gray-300 rounded"
                  />
                  <button
                    onClick={handleSaveDescription}
                    className="mt-2 bg-green-500 text-white py-1 px-4 rounded hover:bg-green-600 w-full"
                  >
                    Salvar Descrição
                  </button>
                </>
              ) : (
                <>
                  <p>
                    {newDescription ||
                      user.Teacher?.description ||
                      'Nenhuma descrição'}
                  </p>
                  <button
                    onClick={() => setEditDescription(true)}
                    className="mt-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600 w-full"
                  >
                    Editar Descrição
                  </button>
                </>
              )}
            </div>
            <div className="mb-6 p-4 bg-gray-100 rounded-lg shadow-inner">
              <h2 className="text-lg font-semibold mb-2">Disponibilidade</h2>
              {editAvailability ? (
                <>
                  <input
                    type="text"
                    value={newAvailability || ''}
                    onChange={(e) => setNewAvailability(e.target.value)}
                    className="block w-full p-2 border border-gray-300 rounded"
                  />
                  <button
                    onClick={handleSaveAvailability}
                    className="mt-2 bg-green-500 text-white py-1 px-4 rounded hover:bg-green-600 w-full"
                  >
                    Salvar Disponibilidade
                  </button>
                </>
              ) : (
                <>
                  <p>
                    {newAvailability ||
                      user.Teacher?.availability ||
                      'Nenhuma disponibilidade'}
                  </p>
                  <button
                    onClick={() => setEditAvailability(true)}
                    className="mt-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600 w-full"
                  >
                    Editar Disponibilidade
                  </button>
                </>
              )}
            </div>
          </>
        )}
        {renderPasswordChangeSection()}
        <div className="mt-8 flex justify-center">
          <button
            onClick={handleSaveProfile}
            className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600"
          >
            Salvar alterações do perfil
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
