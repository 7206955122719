import React from 'react';
import {
  FaUser,
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTiktok,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Terms: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };
  return (
    <div>
      {/* Top Bar */}
      <div className="flex justify-end items-center p-2.5 bg-[#6DD2BF]">
        <div className="flex items-center space-x-5">
          <button
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
            onClick={handleLoginRedirect}
          >
            <FaUser className="mr-2" /> Iniciar Sesión
          </button>
          <a
            href="https://wa.me/34622713212"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#6DD2BF] border-2 border-[#6DD2BF] text-white text-lg flex items-center cursor-pointer p-2.5 rounded transition-colors duration-300 ease-in-out hover:bg-white hover:text-[#6DD2BF]"
          >
            <FaWhatsapp className="mr-2" /> Whatsapp
          </a>
          <a
            href="https://www.linkedin.com/company/e-pitinglish/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.instagram.com/epitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/ePitinglish/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.tiktok.com/@epitinglish"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6DD2BF] text-2xl cursor-pointer bg-white rounded-full p-1.5 transition-colors duration-300 ease-in-out hover:bg-[#f1da7b]"
          >
            <FaTiktok />
          </a>
        </div>
      </div>

      {/* Header Menu */}
      <div className="flex justify-between items-center p-4 bg-[#6DD2BF]">
        <div className="flex items-center">
          <img src="/logo.jpeg" alt="Logo" className="h-12" />
        </div>
        <div className="flex justify-around items-center space-x-8">
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            NUESTRA METODOLOGIA
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PROFESORES
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            PRECIOS
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            BLOG
          </button>
          <button className="text-white text-xl cursor-pointer py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#FA877A]">
            CONTACTO
          </button>
        </div>
      </div>
      {/* New Section */}
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-4 text-[#333]">
          Condiciones generales
        </h1>
        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Propiedad de nuestra página web y servicios
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Las presentes Condiciones Generales regulan la compra de los productos
          ofrecidos a través de la web www.e-pitinglish.com, propiedad de
          e-Pitinglish S.L, con NIF B09703067, y domicilio en c. Lluís Domènech
          i Montaner, 6, esc. 7, 4-B 08950 Esplugues de Llobregat; Correo
          electrónico hello@e-pitinglish.com. En adelante, “LA EMPRESA”. Estos
          Términos establecen las condiciones bajo las cuales puedes usar
          nuestra página web y los servicios ofrecidos. Al acceder o usar la
          página web, confirmas que has leído, entendido y aceptado estos
          Términos.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          ¿Quién puede usar nuestra página web?**
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Para usar nuestra página web y/o recibir nuestros servicios, debes
          tener al menos 18 años de edad o la mayoría de edad legal en tu
          jurisdicción, y poseer la autoridad legal, el derecho y la libertad
          para participar en estos Términos como un acuerdo vinculante. No
          tienes permitido utilizar esta página web y/o recibir servicios si
          hacerlo está prohibido en tu país o en virtud de cualquier ley o
          regulación aplicable a tu caso.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Términos comerciales
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Al comprar un artículo, aceptas que: (i) eres responsable de leer el
          listado completo del artículo antes de comprometerte a comprarlo; (ii)
          celebras un contrato legalmente vinculante para comprar un artículo
          cuando te comprometes a comprarlo y completas el proceso de check-out.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          Los precios de nuestros servicios/productos se enumeran en la página
          web. Nos reservamos el derecho de cambiar nuestros precios en
          cualquier momento y de corregir errores de precios que puedan ocurrir
          inadvertidamente. Información adicional sobre precios e impuestos está
          disponible en la página de pagos.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Política de cancelación
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          En caso de no poder asistir a una clase programada, debe notificarse
          con un mínimo de 24 horas de antelación; de lo contrario, se perderá
          la cantidad correspondiente a esa clase.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Duración de los packs de clases
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Cada pack contratado tiene una duración finita. Para garantizar que el
          aprendizaje sea sólido y efectivo, estos packs están diseñados para
          realizar entre 1 o 2 clases a la semana. Una vez expirada la fecha del
          pack, se perderán las clases no realizadas, salvo causas de fuerza
          mayor. Las duraciones de cada pack son:
        </p>
        <ul className="list-disc list-inside mb-6 text-[#444] leading-7">
          <li>
            Pack 10 e-Classes: 12 semanas desde la contratación del servicio.
          </li>
          <li>
            Pack 20 e-Classes: 22 semanas desde la contratación del servicio.
          </li>
          <li>
            Pack 40 e-Classes: 42 semanas desde la contratación del servicio.
          </li>
          <li>Pack 1 e-Club: 2 semanas desde la contratación del servicio.</li>
          <li>Pack 5 e-Clubs: 7 semanas desde la contratación del servicio.</li>
          <li>
            Pack 10 e-Clubs: 12 semanas desde la contratación del servicio.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Política de devoluciones y reembolsos
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          De conformidad con el artículo 102 y siguientes del Real Decreto
          Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto
          refundido de la Ley General para la Defensa de los Consumidores y
          Usuarios y otras leyes complementarias, tienes derecho a un reembolso
          dentro de los 14 días desde la fecha de compra.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Retención del derecho a cambiar de oferta
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Podemos, sin aviso previo, cambiar los servicios; dejar de
          proporcionar los servicios o cualquier característica de los servicios
          que ofrecemos; o crear límites para los servicios. Podemos suspender
          de manera permanente o temporal el acceso a los servicios sin previo
          aviso ni responsabilidad por cualquier motivo, o sin ningún motivo.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Posesión de propiedad intelectual, derechos de autor y logos
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          El Servicio y todos los materiales incluidos o transferidos,
          incluyendo, sin limitación, software, imágenes, texto, gráficos,
          logotipos, patentes, marcas registradas, marcas de servicio, derechos
          de autor, fotografías, audio, videos, música y todos los Derechos de
          Propiedad Intelectual relacionados, son propiedad exclusiva de
          e-Pitinglish, representada legalmente por Ariadna Pié Rubió. Salvo que
          se indique explícitamente en este documento, no se considera que estos
          Términos crean una licencia en o bajo ninguno de dichos Derechos de
          Propiedad Intelectual. Aceptas no vender, licenciar, alquilar,
          modificar, distribuir, copiar, reproducir, transmitir, exhibir
          públicamente, realizar públicamente, publicar, adaptar, editar o crear
          trabajos derivados de los mismos.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Derecho a suspender o cancelar la cuenta de usuario
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Podemos terminar o suspender de manera permanente o temporal tu acceso
          al servicio sin previo aviso y sin responsabilidad por cualquier
          razón, incluyendo si, a nuestra sola determinación, violas alguna
          disposición de estos Términos o cualquier ley o regulación aplicable.
          Puedes descontinuar el uso y solicitar cancelar tu cuenta y/o
          cualquier servicio en cualquier momento. Las suscripciones renovadas
          automáticamente a los servicios pagados se suspenderán solo al
          vencimiento del período correspondiente por el que ya has realizado el
          pago.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Indemnización
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Aceptas indemnizar y eximir de responsabilidad a e-Pitinglish de
          cualquier demanda, pérdida, responsabilidad, reclamación o gasto
          (incluidos los honorarios de abogados) que terceros realicen en tu
          contra como consecuencia de, o derivado de, tu uso de la página web o
          cualquiera de los servicios ofrecidos en la página web.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Limitación de responsabilidad
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          En la máxima medida permitida por la ley aplicable, en ningún caso
          e-Pitinglish será responsable por daños indirectos, punitivos,
          incidentales, especiales, consecuentes o ejemplares, incluidos, entre
          otros, daños por pérdida de beneficios, buena voluntad, uso, datos u
          otras pérdidas intangibles, que surjan de o estén relacionadas con el
          uso o la imposibilidad de utilizar el servicio.
        </p>
        <p className="mb-4 text-[#444] leading-7">
          En la máxima medida permitida por la ley aplicable, e-Pitinglish no
          asume responsabilidad alguna por (i) errores, errores o inexactitudes
          de contenido; (ii) lesiones personales o daños a la propiedad, de
          cualquier naturaleza que sean, como resultado de tu acceso o uso de
          nuestro servicio; y (iii) cualquier acceso no autorizado o uso de
          nuestros servidores seguros y/o toda la información personal
          almacenada en los mismos.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Derecho a cambiar y modificar los Términos
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Nos reservamos el derecho de modificar estos Términos a nuestra entera
          discreción. Debes revisar estas páginas periódicamente. Cuando
          cambiemos los Términos de una manera material, te notificaremos que se
          han realizado cambios importantes. El uso continuado de la página web
          o nuestro servicio después de dicho cambio constituye tu aceptación de
          los nuevos Términos. Si no aceptas alguno de estos términos o
          cualquier versión futura de los Términos, no uses ni accedas a la
          página web o al servicio.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Emails de promociones y contenido
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Aceptas recibir de vez en cuando nuestros mensajes y materiales de
          promoción, por correo postal, correo electrónico o cualquier otro
          formulario de contacto que nos proporciones (incluido tu número de
          teléfono para llamadas o mensajes de texto). Si no deseas recibir
          dichos materiales o avisos de promociones, avísanos en cualquier
          momento.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Preferencia de ley y resolución de disputas
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Estos Términos, los derechos y recursos provistos aquí, y todos y cada
          uno de los reclamos y disputas relacionados con estos y/o los
          servicios, se regirán, interpretarán y aplicarán exclusivamente de
          conformidad con las leyes sustantivas internas de España, sin respeto
          a sus principios de conflicto de leyes. Todos los reclamos y disputas
          se presentarán y aceptas que sean decididos exclusivamente por un
          tribunal de jurisdicción competente ubicado en Barcelona. La
          aplicación de la Convención de Contratos de las Naciones Unidas para
          la Venta Internacional de Bienes queda expresamente excluida.
        </p>

        <h2 className="text-2xl font-semibold mb-3 text-[#333]">
          Atención al cliente e información de contacto
        </h2>
        <p className="mb-4 text-[#444] leading-7">
          Para cualquier consulta, puedes dirigirte a nuestro correo de atención
          al cliente: hello@pitinglish.com.
        </p>
      </div>
    </div>
  );
};

export default Terms;
