import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const updateVideos = async (videoId: string, userID: string) => {
  try {
    const response = await axios.put(
      `${API_URL}/client/endpoints/teacher/profile/${userID}/videos`,
      { videoId }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao atualizar videos:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao atualizar videos:', error);
    }
    throw error;
  }
};
