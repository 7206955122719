import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CountryFlag from 'react-country-flag';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getUser } from '../../service/auth/user/getUser.service';
import { User } from '../../@types/usersTypes';
import LoadingSpinner from '../Loading/Spinner/Spinner';
import countries from '../../constants/countries.json';
import nationalities from '../../constants/nationalities.json';
import { SingleValue } from 'react-select';
import axios from 'axios';
import {
  UpdateUserData,
  updateUser,
} from '../../service/auth/user/updateUser.service';
import { uploadProfileImage } from '../../service/auth/user/uploadProfileImage.service';
import { validateDni } from '../../validators/dni';
import { validateNie } from '../../validators/nie';
import { validatePassport } from '../../validators/passport';
import { validateIban } from '../../validators/iban';
import { updateAvailability } from '../../service/teacher/updateAvailability.service';

const TeacherDashboard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [phoneWithCountryCode, setPhoneWithCountryCode] = useState<string>('');
  const token = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const [selectedDays, setSelectedDays] = useState<number[]>([]); // Dias selecionados
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]); // Horários selecionados

  const [formValues, setFormValues] = useState({
    answerIntroduction: '',
    phone: '',
    answerEnglishLevel: '',
    answerLikesChildren: '',
    answerSituation1: '',
    answerSituation2: '',
    nationality: '',
    countryOfResidence: '',
    profilePicture: null as File | null,
    documentType: '',
    documentNumber: '',
    iban: '',
    masteredDisabilities: '',
    billingAddress: '',
  });
  const [errors, setErrors] = useState({
    documentNumber: '',
    iban: '',
  });

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchUserData();
  }, [token, userId, navigate]);

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      if (userId) {
        const response = await getUser(userId);
        setUser(response);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);

      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    setIsLoading(true);
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    navigate('/');
    setIsLoading(false);
  };

  const handleConfig = () => {
    navigate('/user-profile');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Função para lidar com o upload da imagem
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFormValues((prevValues) => ({
        ...prevValues,
        profilePicture: file,
      }));
    }
  };
  const handlePhoneChange = (value: string) => {
    setPhoneWithCountryCode(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      phone: value,
    }));
  };

  // Função para lidar com a seleção dos dias da semana
  const handleDayChange = (dayValue: number) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(dayValue)
        ? prevDays.filter((day) => day !== dayValue)
        : [...prevDays, dayValue]
    );
  };

  // Função para lidar com a seleção dos horários
  const handleTimeChange = (timeValue: string) => {
    setSelectedTimes((prevTimes) =>
      prevTimes.includes(timeValue)
        ? prevTimes.filter((time) => time !== timeValue)
        : [...prevTimes, timeValue]
    );
  };

  const countryOptions = Object.keys(countries as Record<string, string>).map(
    (code) => ({
      value: code,
      label: countries[code as keyof typeof countries],
    })
  );

  const documentOptions = [
    { value: 'DNI', label: 'DNI' },
    { value: 'NIE', label: 'NIE' },
    { value: 'PASSPORT', label: 'PASAPORTE' },
  ];

  const handleNationalityChange = (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    if (selectedOption) {
      setFormValues((prevValues) => ({
        ...prevValues,
        nationality: selectedOption.label,
      }));
    }
  };

  const handleCountryChange = (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    if (selectedOption) {
      setFormValues((prevValues) => ({
        ...prevValues,
        countryOfResidence: selectedOption.label,
      }));
    }
  };

  const nationalityOptions = Object.keys(
    nationalities as Record<string, string>
  ).map((code) => ({
    value: code,
    label: (nationalities as Record<string, string>)[code],
  }));

  const handleDocumentTypeChange = (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    if (selectedOption) {
      setFormValues((prevValues) => ({
        ...prevValues,
        documentType: selectedOption.value,
      }));
    }
  };

  const validateDocument = () => {
    let errorMsg = '';
    const { documentType, documentNumber } = formValues;

    if (documentType === 'DNI') {
      if (!validateDni(documentNumber)) {
        errorMsg = 'Número de DNI inválido';
      }
    } else if (documentType === 'NIE') {
      if (!validateNie(documentNumber)) {
        errorMsg = 'Número de NIE inválido';
      }
    } else if (documentType === 'PASSPORT') {
      if (!validatePassport(documentNumber)) {
        errorMsg = 'Número de Passaporte inválido';
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      documentNumber: errorMsg,
    }));
  };

  const validateIbanField = () => {
    const { iban } = formValues;
    if (!validateIban(iban)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        iban: 'IBAN inválido',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        iban: '',
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Chama as funções de validação
    validateDocument();
    validateIbanField();

    // Verifica se há erros de validação
    if (errors.documentNumber || errors.iban) {
      console.log('Erro de validação nos documentos ou IBAN');
      return;
    }

    setIsLoading(true);

    let imageUrl = user?.profilePicture || null;

    // Verifica se há uma nova imagem para upload
    if (formValues.profilePicture && userId) {
      try {
        imageUrl = await uploadProfileImage(userId, formValues.profilePicture);
      } catch (uploadError) {
        console.error('Erro ao fazer upload da imagem:', uploadError);
        setIsLoading(false);
        return;
      }
    }

    // Monta os dados de atualização do usuário
    const formData: UpdateUserData = {
      answerIntroduction: formValues.answerIntroduction,
      phone: phoneWithCountryCode,
      answerEnglishLevel: formValues.answerEnglishLevel,
      answerLikesChildren: formValues.answerLikesChildren,
      answerSituation1: formValues.answerSituation1,
      answerSituation2: formValues.answerSituation2,
      profilePicture: imageUrl ?? undefined,
      selectionPhase: 'UNDER_REVIEW',
      nationality: formValues.nationality,
      countryOfResidence: formValues.countryOfResidence,
      documentNumber: formValues.documentNumber,
      iban: formValues.iban,
      masteredDisabilities: formValues.masteredDisabilities,
      billingAddress: formValues.billingAddress,
    };

    // Gera os slots de disponibilidade baseados nos dias e horários selecionados
    const availability = selectedDays.flatMap((day) =>
      selectedTimes.map((time) => ({
        dayOfWeek: day,
        startTime: time,
        endTime: `${parseInt(time.split(':')[0]) + 1}:00`, // Adiciona uma hora ao horário final
      }))
    );

    try {
      if (userId) {
        // Atualiza as informações do usuário
        const response = await updateUser(userId, formData);
        console.log('Resposta do servidor após atualização:', response);

        // Atualiza as disponibilidades
        if (availability.length > 0) {
          const avResponse = await updateAvailability(userId, availability);
          console.log('Resposta do servidor após atualização:', avResponse);
        }
      }
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
    } finally {
      fetchUserData();
      setIsLoading(false);
    }
  };

  const renderDashboardContent = () => {
    switch (user?.Teacher?.selectionPhase) {
      case 'REGISTRATION':
        return (
          <div className="flex flex-1 flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg mx-5 max-h-screen">
            <div className="w-full max-w-lg h-full overflow-y-auto">
              <h3 className="text-2xl font-semibold mb-4">
                Complete seu perfil
              </h3>
              <p className="text-gray-700 mb-6">
                Responda às perguntas abaixo para avançar no processo.
              </p>
              <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Cuéntanos: Quién eres, qué edad tienes, cuáles son tus
                    gustos e intereses, años de experiencia como profesor...
                  </label>
                  <textarea
                    className="w-full p-2 border rounded"
                    name="answerIntroduction"
                    value={formValues.answerIntroduction}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Experiencia */}
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Experiencia com transtornos de aprendizagem
                  </label>
                  <textarea
                    className="w-full p-2 border rounded"
                    name="masteredDisabilities"
                    value={formValues.masteredDisabilities}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Input de Telefone */}
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Número de telefone
                  </label>
                  <PhoneInput
                    country={'es'}
                    value={formValues.phone}
                    onChange={handlePhoneChange}
                    containerClass="mb-4"
                    inputClass="w-full p-2 border rounded"
                  />
                </div>

                {/* Input de upload de foto */}
                <div className="mb-4">
                  <label className="block text-gray-700">Foto de perfil</label>
                  <input
                    type="file"
                    accept="image/*"
                    className="w-full p-2 border rounded"
                    onChange={handleFileChange} // Lida com o upload da foto
                  />
                </div>

                {/* Select de País de Residência com Bandeira */}
                <div className="mb-4">
                  <label className="block text-gray-700">
                    País de Residência
                  </label>
                  <Select
                    options={countryOptions}
                    onChange={handleCountryChange}
                    value={countryOptions.find(
                      (option) => option.label === formValues.countryOfResidence
                    )}
                    formatOptionLabel={(option) => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CountryFlag
                          countryCode={option.value}
                          svg
                          style={{ marginRight: '8px' }}
                        />
                        {option.label}
                      </div>
                    )}
                  />
                </div>

                {/* Input de endereço */}
                <div className="mb-4">
                  <label className="block text-gray-700">Endereço</label>
                  <textarea
                    className="w-full p-2 border rounded"
                    name="billingAddress"
                    value={formValues.billingAddress}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Select de Nacionalidade */}
                <div className="mb-4">
                  <label className="block text-gray-700">Nacionalidade</label>
                  <Select
                    options={nationalityOptions}
                    onChange={handleNationalityChange}
                    value={nationalityOptions.find(
                      (option) => option.label === formValues.nationality
                    )}
                  />
                </div>

                {/* Select do tipo de documento */}
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Tipo de documento de identidad*
                  </label>
                  <Select
                    options={documentOptions}
                    onChange={handleDocumentTypeChange}
                    className="w-full"
                  />
                </div>

                {/* Input do número de documento */}
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Documento de identidad*
                  </label>
                  <input
                    type="text"
                    name="documentNumber"
                    className="w-full p-2 border rounded"
                    value={formValues.documentNumber}
                    onChange={handleInputChange}
                    onBlur={validateDocument}
                  />
                  {errors.documentNumber && (
                    <p className="text-red-500 mt-4">{errors.documentNumber}</p>
                  )}
                </div>

                {/* Input do IBAN */}
                <div className="mb-4">
                  <label className="block text-gray-700">IBAN*</label>
                  <input
                    type="text"
                    name="iban"
                    className="w-full p-2 border rounded"
                    value={formValues.iban}
                    onChange={handleInputChange}
                    onBlur={validateIbanField}
                    maxLength={24}
                  />
                  {errors.iban && (
                    <p className="text-red-500 mt-4">{errors.iban}</p>
                  )}

                  <span className="text-xs label-text-alt my-2">
                    <div>
                      El IBAN debe empezar con ES y terminar con 22 números.
                    </div>
                    <div>Ejemplo: ES1234567891234567891234.</div>
                  </span>
                </div>

                {/* Select para Nível de Inglês */}
                <div className="mb-4">
                  <label className="block text-gray-700">Nível de Inglês</label>
                  <select
                    className="w-full p-2 border rounded"
                    name="answerEnglishLevel"
                    value={formValues.answerEnglishLevel}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione um nível</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="FCE">FCE</option>
                    <option value="CAE">CAE</option>
                    <option value="Proficiency">Proficiency</option>
                    <option value="Nativo">Nativo</option>
                  </select>
                </div>

                {/* Seção de Disponibilidade - Dias da Semana */}
                <div className="mb-4">
                  <h4 className="block text-gray-700">
                    Selecione os dias da semana disponíveis
                  </h4>
                  {[
                    { label: 'Domingo', value: 0 },
                    { label: 'Lunes', value: 1 },
                    { label: 'Martes', value: 2 },
                    { label: 'Miercules', value: 3 },
                    { label: 'Jueves', value: 4 },
                    { label: 'Viernes', value: 5 },
                    { label: 'Sábado', value: 6 },
                  ].map((day) => (
                    <label key={day.value} className="block">
                      <input
                        type="checkbox"
                        checked={selectedDays.includes(day.value)}
                        onChange={() => handleDayChange(day.value)}
                      />
                      <span className="ml-2">{day.label}</span>
                    </label>
                  ))}
                </div>

                {/* Seção de Disponibilidade - Horários */}
                <div className="mb-4">
                  <h4 className="block text-gray-700">
                    Selecione os horários disponíveis
                  </h4>
                  {['15:00', '16:00', '17:00', '18:00', '19:00', '20:00'].map(
                    (time) => (
                      <label key={time} className="block">
                        <input
                          type="checkbox"
                          checked={selectedTimes.includes(time)}
                          onChange={() => handleTimeChange(time)}
                        />
                        <span className="ml-2">{time}</span>
                      </label>
                    )
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">
                    ¿Qué es lo que más te gusta de los niños?
                  </label>
                  <textarea
                    className="w-full p-2 border rounded"
                    name="answerLikesChildren"
                    value={formValues.answerLikesChildren}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">
                    SITUACIÓN 1: ¿Cómo captarías la atención de un niño
                    despistado en una clase online?
                  </label>
                  <textarea
                    className="w-full p-2 border rounded"
                    name="answerSituation1"
                    value={formValues.answerSituation1}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">
                    SITUACIÓN 2: ¿Cómo planificarías clases personalizadas sobre
                    Dora la Exploradora?
                  </label>
                  <textarea
                    className="w-full p-2 border rounded"
                    name="answerSituation2"
                    value={formValues.answerSituation2}
                    onChange={handleInputChange}
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-teal-500 text-white py-2 px-4 rounded hover:bg-teal-700 transition-colors"
                >
                  Enviar
                </button>
              </form>
            </div>
          </div>
        );

      case 'UNDER_REVIEW':
        return (
          <div className="flex flex-1 flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg mx-5 text-center">
            <h3 className="text-2xl font-semibold mb-4">Perfil em Revisão</h3>
            <p className="text-gray-700 mb-6">
              Seu perfil está sendo revisado por nossa equipe. Em breve
              entraremos em contato com você. Fique atento às notificações.
            </p>
          </div>
        );

      case 'INTERVIEW':
        return (
          <div className="flex flex-1 flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg mx-5 text-center">
            <h3 className="text-2xl font-semibold mb-4">Parabéns!</h3>
            <p className="text-gray-700 mb-6">
              Você passou para a próxima fase! Vamos agendar uma entrevista por
              videochamada para conhecê-lo melhor.
            </p>
            <button className="bg-teal-500 text-white py-2 px-4 rounded hover:bg-teal-700 transition-colors">
              Agendar Entrevista
            </button>
          </div>
        );

      case 'ACADEMY':
        return (
          <div className="flex flex-1 flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg mx-5 text-center">
            <h3 className="text-2xl font-semibold mb-4">Bem-vindo à Academy</h3>
            <p className="text-gray-700 mb-6">
              Complete os cursos abaixo para continuar seu processo:
            </p>
            <div className="grid grid-cols-3 gap-4">
              {/* Cards de cursos */}
              <div className="bg-gray-200 p-4 rounded-lg shadow">
                <h4 className="text-lg font-semibold">Curso 1</h4>
                <p className="text-gray-600">Descrição do curso.</p>
              </div>
              <div className="bg-gray-200 p-4 rounded-lg shadow">
                <h4 className="text-lg font-semibold">Curso 2</h4>
                <p className="text-gray-600">Descrição do curso.</p>
              </div>
              <div className="bg-gray-200 p-4 rounded-lg shadow">
                <h4 className="text-lg font-semibold">Curso 3</h4>
                <p className="text-gray-600">Descrição do curso.</p>
              </div>
            </div>
          </div>
        );

      case 'REJECTED':
        return (
          <div className="flex flex-1 flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg mx-5 text-center">
            <h3 className="text-2xl font-semibold mb-4">
              Obrigado pelo seu interesse!
            </h3>
            <p className="text-gray-700 mb-6">
              No momento, não estamos contratando. Agradecemos sua candidatura e
              desejamos sucesso no futuro.
            </p>
          </div>
        );

      case 'APPROVED':
      default:
        return (
          <>
            <div className="flex-1 flex flex-col p-5 gap-5">
              <div className="flex-1 bg-white rounded-lg p-5 shadow-lg">
                <h3 className="mb-3">Próxima Aula</h3>
                {/* Detalhes da próxima aula */}
              </div>
              <div className="flex-1 bg-white rounded-lg p-5 shadow-lg">
                <h3 className="mb-3">Notas</h3>
                {/* Detalhes das notas */}
              </div>
            </div>

            <div className="w-1/3 flex flex-col justify-between bg-white rounded-r-lg p-5 shadow-lg">
              <div>
                <h3 className="mb-3">Notificações</h3>
                {/* Lista de notificações */}
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="flex bg-white shadow-lg rounded-lg w-full max-w-7xl h-5/6">
        {/* Sidebar à esquerda */}
        <div className="bg-[#6DD2BF] w-1/4 p-5 flex flex-col items-center relative rounded-l-lg">
          {/* Centralizar a imagem de perfil no topo */}
          <div className="flex flex-col items-center text-center mb-5">
            <div className="w-24 h-24 relative overflow-hidden rounded-full border-2 border-white mb-2">
              <img
                src={user?.profilePicture ?? 'path/to/default-image.jpg'}
                alt="Imagem de Perfil"
                className="w-full h-full object-cover object-center"
              />
            </div>
            <h2 className="text-white text-lg">
              {user?.fullName || 'Nome indisponível'}
            </h2>
            <p className="text-white text-sm">
              {user?.email || 'Email indisponível'}
            </p>
          </div>

          {/* Botões de ação */}
          <button className="bg-[#FA877A] text-white py-2 px-4 rounded-md mb-2 w-full text-center hover:bg-white hover:text-[#6DD2BF]">
            Acessar Calendário
          </button>
          <button className="bg-[#FA877A] text-white py-2 px-4 rounded-md mb-2 w-full text-center hover:bg-white hover:text-[#6DD2BF]">
            Acessar Carteira
          </button>
          <button className="bg-[#FA877A] text-white py-2 px-4 rounded-md mb-2 w-full text-center hover:bg-white hover:text-[#6DD2BF]">
            Acessar Notas
          </button>
          <button className="bg-[#FA877A] text-white py-2 px-4 rounded-md mb-2 w-full text-center hover:bg-white hover:text-[#6DD2BF]">
            Acessar Aulas
          </button>
          <button
            className="bg-[#FA877A] text-white py-2 px-4 rounded-md mb-2 w-full text-center hover:bg-white hover:text-[#6DD2BF]"
            onClick={handleConfig}
          >
            Acessar Configurações
          </button>

          {/* Botão de Logout fixo na parte inferior */}
          <button
            className="bg-[#FA877A] text-white py-2 px-4 rounded-md w-full text-center mt-auto hover:bg-white hover:text-[#FA877A]"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>

        {/* Conteúdo principal e notificações */}
        {renderDashboardContent()}
      </div>
    </div>
  );
};

export default TeacherDashboard;
