import React from 'react';
import { StudentProfile, User } from '../../../@types/usersTypes';
import comingSoon from '../../../assets/comingSoon.png';

interface ClubProps {
  user: User | null;
  selectedChild: StudentProfile | null;
  setActivePage: (page: string) => void;
}

const Club: React.FC<ClubProps> = ({
  // user,
  selectedChild,
  // setActivePage,
}) => {
  let hasScheduledLesson;
  let totalLessons;
  if (selectedChild) {
    hasScheduledLesson = selectedChild?.scheduledLessons?.length > 0;
    totalLessons = selectedChild?.totalLessons || 0;
  }

  // Função para pegar a próxima aula do filho selecionado
  const getNextLessonForChild = (child: StudentProfile | null) => {
    if (!child || !child.scheduledLessons) return null;

    if (child.scheduledLessons.length) {
      // Ordenar as aulas por data para pegar a próxima
      const sortedLessons = [...child.scheduledLessons].sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      return sortedLessons[0]; // Retorna a próxima aula
    }
    return null; // Caso não tenha aulas
  };

  // Aula filtrada para o filho selecionado
  const nextLesson = getNextLessonForChild(selectedChild);

  return (
    <div className="flex max-w-[1160px] h-full overflow-hidden mt-10">
      <div className="flex-grow bg-[#F3F4F6] overflow-y-auto w-[1160px] mx-auto mb-20">
        {/* Primeira Section com fundo verde */}
        <section className="w-full h-[113px] bg-[#34D399] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] flex items-center justify-start">
          {hasScheduledLesson ? (
            <div className="text-white flex items-center justify-between w-full px-6">
              <div className="text-start">
                <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                  ¡{selectedChild?.childName} tu clase está reservada!
                </h3>
                <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                  Entra a clase 15 minutos antes para configurar la cámara y el
                  micrófono.
                </p>
              </div>

              {nextLesson &&
                (() => {
                  const lessonStartTime = new Date(nextLesson.startTime);
                  const currentTime = new Date();
                  const timeDifference =
                    lessonStartTime.getTime() - currentTime.getTime();
                  const fifteenMinutesInMs = 15 * 60 * 1000; // 15 minutos em milissegundos

                  // Verifica se estamos no intervalo de 15 minutos antes da aula
                  const isWithin15Minutes =
                    timeDifference <= fifteenMinutesInMs && timeDifference > 0;

                  return (
                    <button
                      className={`font-mont font-extrabold text-[14px] leading-[17.89px] w-[228px] h-[38px] px-4 py-2 rounded-md ${
                        isWithin15Minutes
                          ? 'bg-[#FFFFFF] text-[#065F46] hover:bg-[#059669] hover:text-white cursor-pointer border-2 border-[#069668]'
                          : 'bg-[#FFFFFF] text-[#065F46]'
                      } shadow-[0px_1px_2px_0px_#0000000D]`} // Sombra adicionada
                      disabled={!isWithin15Minutes} // Desabilita o clique fora do intervalo
                    >
                      {isWithin15Minutes
                        ? 'Unirme Ahora'
                        : `Entrar a clase a las ${new Date(
                            lessonStartTime.getTime() - 15 * 60000 // Subtrai 15 minutos
                          ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`}
                    </button>
                  );
                })()}
            </div>
          ) : totalLessons === 0 ? (
            <div className="w-full flex justify-between items-center text-start ml-6">
              {/* Conteúdo de texto à esquerda */}
              <div>
                <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                  ¡Reserva tu clase de prueba gratuita!
                </h3>
                <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                  Conoce a nuestros profesores y elige el horario que mejor se
                  adapte a ti.
                </p>
              </div>

              {/* Botão à direita */}
              <div>
                <button
                  className="flex items-center justify-center w-[191px] h-[44px] py-[16px] bg-[#FFFFFF] text-[#059669] border-2 border-[#059669] font-extrabold text-[14px] leading-[20px] rounded-md shadow hover:opacity-100 transition font-mont mr-6"
                  style={{
                    boxShadow: '0px 1px 2px 0px #0000000D',
                  }}
                >
                  Reservar Ahora!
                </button>
              </div>
            </div>
          ) : (
            <div className="text-white text-start ml-6">
              <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
                ¡Reserva ahora tu clase!
              </h3>
              <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
                Elige uno de nuestros profesores y la fecha que más te convenga.
              </p>
            </div>
          )}
        </section>

        <div className="w-full h-[296px] bg-white mr-[20px] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] mt-4 flex justify-between items-start">
          {/* Conteúdo da esquerda */}
          <div className="flex flex-col w-[740px] h-[164px] mt-8 ml-8">
            <h1 className="text-[30px] font-extrabold font-mont leading-[38.34px] text-[#0F172A] mb-4">
              ¡Estamos construyendo algo increíble!
            </h1>
            <p className="font-nunito font-medium text-[16px] leading-[21.82px] mb-6 text-[#334155]">
              Pronto podrás acceder a un espacio donde tu hijo podrá conectar,
              aprender y jugar con otros alumnos. <br /> Nos estamos asegurando
              de crear una experiencia divertida y educativa solo para ellos.
            </p>
            <p className="font-nunito font-medium text-[16px] leading-[21.82px] text-[#334155] mb-6">
              <span className="font-nunito font-extrabold text-[16px] leading-[21.82px] text-[#334155]">
                ¿Y mientras tanto? <br />
              </span>
              Agenda tu próxima clase y sigue disfrutando de todo lo que
              e-Pitinglish tiene para ofrecer.
            </p>
            <button
              className="flex items-center justify-center w-[191px] h-[44px] py-[16px] bg-[#34D399] text-white font-bold text-[14px] leading-[20px] rounded-md  shadow hover:bg-gradient-to-b hover:from-hover-green-secondary hover:via-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition font-mont"
              style={{
                boxShadow: '0px 1px 2px 0px #0000000D',
              }}
            >
              Reservar una Clase
            </button>
          </div>

          {/* Imagem no canto direito */}
          <div className="w-[353.08px] h-[351px] flex justify-center items-center">
            <img
              src={comingSoon}
              alt="Descrição da imagem"
              className="absolute top-[212.65px] right-[80px] max-w-[356.19px] max-h-[457.96px] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Club;
