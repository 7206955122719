import React, { useState } from 'react';
import * as SolarIconSet from 'solar-icon-set';

const Filtros: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`w-full bg-white rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] mt-4 mb-4 transition-all duration-300 ${
        isExpanded ? 'h-[156px]' : 'h-[56px]'
      }`}
    >
      {/* Header da Div */}
      <div
        className="flex justify-between items-center px-4 h-[56px] cursor-pointer"
        onClick={toggleExpand}
      >
        <div className="flex items-center">
          <SolarIconSet.Filter size={24} color="#059669" />
          <span className="ml-2 font-mont font-semibold text-[16px] leading-[20px] text-[#334155]">
            Filtros
          </span>
        </div>
        <SolarIconSet.AltArrowUp
          size={16}
          color="#64748B"
          className={`${
            isExpanded ? 'rotate-180' : ''
          } transition-transform duration-300`}
        />
      </div>

      {/* Filtros - Mostra somente quando expandido */}
      {isExpanded && (
        <div className="px-4 py-2">
          <div className="grid grid-cols-6">
            {/* Linha 1 */}
            <input
              type="text"
              placeholder="Procurar por nome"
              className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1 mb-[14px]"
            />
            <select className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1">
              <option value="">Dia</option>
              <option value="monday">Segunda-feira</option>
              <option value="tuesday">Terça-feira</option>
              <option value="wednesday">Quarta-feira</option>
              <option value="thursday">Quinta-feira</option>
              <option value="friday">Sexta-feira</option>
              <option value="saturday">Sábado</option>
              <option value="sunday">Domingo</option>
            </select>
            <select className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1">
              <option value="">Hora</option>
              {[...Array(7)].map((_, i) => {
                const hour = 15 + i;
                return (
                  <option key={hour} value={hour}>
                    {hour}:00
                  </option>
                );
              })}
            </select>
            <select className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1">
              <option value="">País</option>
              <option value="br">Brasil</option>
              <option value="us">Estados Unidos</option>
              <option value="uk">Reino Unido</option>
              <option value="es">Espanha</option>
            </select>
            <select className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1">
              <option value="">Tipo de Professor</option>
              <option value="native">Nativo</option>
              <option value="non-native">Não Nativo</option>
            </select>
            <select className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1">
              <option value="">Nível</option>
              <option value="beginner">Iniciante</option>
              <option value="intermediate">Intermediário</option>
              <option value="advanced">Avançado</option>
            </select>

            {/* Linha 2 */}
            <select className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1">
              <option value="">Acento</option>
              <option value="br">Brasileiro</option>
              <option value="us">Americano</option>
              <option value="uk">Britânico</option>
            </select>
            <select className="border border-gray-300 rounded h-[36px] w-[176.33px] px-2 col-span-1">
              <option value="">Personalidade</option>
              <option value="funny">Engraçado</option>
              <option value="serious">Sério</option>
              <option value="patient">Paciente</option>
              <option value="energetic">Energético</option>
            </select>
            <div className="col-span-2 flex items-center">
              <button className="w-[176px] h-[36px] px-4 py-auto rounded-lg hover:bg-[#059669] hover:text-white transition mr-[14px] text-[#059669] border-2 border-[#059669] font-mont font-bold text-[14px] leading-[20px]">
                Aplicar Filtros
              </button>
              <button className="w-[176px] h-[36px] px-4 py-auto rounded-lg hover:bg-[#EF4444] hover:text-white transition mr-[14px] text-[#EF4444] border-2 border-[#EF4444] font-mont font-bold text-[14px] leading-[20px]">
                Borrar Filtros
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filtros;
