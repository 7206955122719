import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export interface AvailabilitySlot {
  dayOfWeek: number; // 0 = Domingo, 1 = Segunda, ..., 6 = Sábado
  startTime: string; // Ex: '09:00'
  endTime: string; // Ex: '12:00'
}

export const updateAvailability = async (
  teacherId: string,
  availability: AvailabilitySlot[]
) => {
  try {
    const response = await axios.put(
      `${API_URL}/client/endpoints/teacher/profile/${teacherId}/availability`,
      { availability }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro ao atualizar disponibilidade:',
        error.response?.data || error.message
      );
    } else {
      console.error('Erro desconhecido ao atualizar disponibilidade:', error);
    }
    throw error;
  }
};
