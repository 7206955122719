import React from 'react';
import logo from '../../../assets/logoText.png';
import * as SolarIconSet from 'solar-icon-set';

interface SidebarProps {
  setActivePage: (page: string) => void;
  activePage: string;
}

const Sidebar: React.FC<SidebarProps> = ({ setActivePage, activePage }) => {
  return (
    <aside className="w-[236px] bg-white flex flex-col items-center h-screen border-r border-[#E5E7EB]">
      <img
        src={logo}
        alt="Logo"
        className="mb-10 mt-5 w-[150px] h-[42.7px] mr-[15px]"
      />

      {/* Menu */}
      <nav className="flex flex-col flex-grow w-full h-auto">
        <MenuItem
          icon={<SolarIconSet.HomeSmile size={24} color="#059669" />}
          label="Dashboard"
          onClick={() => setActivePage('Dashboard')}
          isActive={activePage === 'Dashboard'}
        />
        <MenuItem
          icon={<SolarIconSet.CalendarMark size={24} color="#059669" />}
          label="Calendario"
          onClick={() => setActivePage('Calendar')}
          isActive={activePage === 'Calendar'}
        />
        <MenuItem
          icon={<CustomAcademicCapIcon />}
          label="Nuestros Profesores"
          onClick={() => setActivePage('Teachers')}
          isActive={
            activePage === 'Teachers' || activePage === 'TeacherCalendar'
          }
        />
        <MenuItem
          icon={<SolarIconSet.ConfettiMinimalistic size={24} color="#059669" />}
          label="Social Club"
          onClick={() => setActivePage('Club')}
          isActive={activePage === 'Club'}
        />
      </nav>

      {/* Bottom Menu */}
      <nav className="w-full">
        <MenuItem
          icon={<SolarIconSet.Gift size={24} color="#059669" />}
          label="Conseguir Bonos"
          onClick={() => setActivePage('Bonus')}
          isActive={activePage === 'Bonus'}
        />
        <button
          className="flex items-center ml-6 mb-4 mt-1 justify-center w-[191px] h-[40px] border-2 border-[#059669] rounded-lg text-[#059669] font-semibold text-[14px] hover:bg-[#059669] hover:text-white transition duration-300 ease-in-out font-mont"
          onClick={() => {
            setActivePage('Subscription');
          }}
        >
          Tu Subscripción
        </button>
        <div className="mt-auto w-full">
          <MenuItem
            icon={
              <SolarIconSet.SettingsMinimalistic size={24} color="#059669" />
            }
            label="Configuración"
            onClick={() => setActivePage('Settings')}
            isActive={activePage === 'Settings'}
            hasTopBorder={true}
          />
        </div>
      </nav>
    </aside>
  );
};

const CustomAcademicCapIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.78272 3.49965C11.2037 2.83345 12.7962 2.83345 14.2172 3.49965L20.9084 6.63664C22.3639 7.31899 22.3639 9.68105 20.9084 10.3634L14.2173 13.5004C12.7963 14.1665 11.2038 14.1665 9.78281 13.5004L3.0916 10.3634C1.63613 9.68101 1.63614 7.31895 3.0916 6.63659L9.78272 3.49965Z"
      stroke="#059669"
      strokeWidth="1.5"
    />
    <path
      d="M2 8.5V14"
      stroke="#059669"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M19 11.5V16.6254C19 17.6334 18.4965 18.5772 17.6147 19.0656C16.1463 19.8787 13.796 21 12 21C10.204 21 7.8537 19.8787 6.38533 19.0656C5.5035 18.5772 5 17.6334 5 16.6254V11.5"
      stroke="#059669"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

interface MenuItemProps {
  icon: React.ReactNode;
  label: string;
  className?: string;
  onClick: () => void;
  isActive: boolean;
  hasTopBorder?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  label,
  onClick,
  isActive,
  hasTopBorder = false,
}) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 text-[14px] w-[236px] h-[56px]  pl-[24px] py-4 font-nunito font-semibold transition-colors ${
      isActive ? 'text-[#059669] bg-[#F3F4F6]' : 'text-[#64748B]'
    } ${hasTopBorder ? 'border-t border-[#E5E7EB]' : ''}`} // Adiciona borda superior se hasTopBorder for true
  >
    <span>{icon}</span>
    {label}
  </button>
);

export default Sidebar;
