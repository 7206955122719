import validator from 'validator';

export const PASSPORT_REGEX = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;

export const validatePassport = (
  passportString: string | undefined
): boolean => {
  try {
    if (!passportString) {
      return false;
    }

    // Verifica se a string do passaporte corresponde ao regex
    const isValid = validator.matches(passportString, PASSPORT_REGEX);

    return isValid;
  } catch (error) {
    console.log('Erro ao validar passaporte:', error);
    return false;
  }
};
