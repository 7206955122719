// import { User } from 'firebase/auth';
import React from 'react';
import { StudentProfile } from '../../../@types/usersTypes';
import canguruGift from '../../../assets/canguruGift.png';

interface BonusProps {
  // user: User | null;
  selectedChild: StudentProfile | null;
  setActivePage: (page: string) => void;
}

const Bonus: React.FC<BonusProps> = () => {
  return (
    <div className="flex max-w-[1160px] h-full">
      {/* Main Content */}
      <div className="flex-grow bg-[#F3F4F6] w-[1160px] mx-auto mb-10">
        <div>
          {/* Class Info Section */}
          <section className="relative flex gap-10 bg-[#34D399] p-5 mt-7 rounded-2xl max-w-[1160px] max-h-[310px] overflow-visible">
            {/* Conteúdo à esquerda */}
            <div className="flex flex-col space-y-4">
              <h1 className="font-nunito font-extrabold text-[40px] leading-[40px] text-[#0F172A]">
                ¡Invita a tus amigos a <br />
                e-Pitinglish y gana <br />
                recompensas exclusivas!
              </h1>
              <p className="font-nunito font-semibold text-[16px] leading-[19.2px] text-[#0F172A]">
                Por cada amigo que se registre y comience a aprender en <br />
                e-Pitinglish, recibirás increíbles recompensas como <br />
                descuentos en tus clases y acceso a contenido exclusivo. <br />
                ¡Es fácil, divertido y todos ganan!
              </p>
              <button className="w-[161px] h-[38px] bg-white border-2 border-[#059669] text-[#059669] font-extrabold font-mont text-[14px] leading-[17.89px] rounded-md shadow-md hover:bg-gray-200">
                Invitar Ahora!
              </button>
            </div>

            {/* Imagem do canguru saindo da section */}
            <img
              src={canguruGift}
              alt="Descrição da imagem"
              className="absolute right-[100px] top-[-39px] max-w-[358.87px] max-h-[462.06px] z-10"
            />
          </section>

          <div className="flex flex-col gap-4 mt-4 justify-center max-w-[1160px] mx-auto">
            {/* Primeira section com 3 cards */}
            <section className="flex gap-1 justify-center w-full">
              <div className="w-[376px] h-[284px] bg-white rounded-lg p-6 shadow-md flex flex-col">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-[312px]">
                  {/* Ícone de número 1 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
                      0px 4px 20px 0px #FFFAFA40 inset, 
                      0px -4px 20px 0px #00000040 inset, 
                      0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    1
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className=" font-bold text-[#64748B] font-nunito text-[16px] w-[264px] h-[32px] leading-[19.2px]">
                    Comparte tu enlace personal con tus amigos
                  </h3>
                </div>
                <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal mt-6">
                  Solo copia tu enlace exclusivo y compártelo para que puedan
                  disfrutar de los beneficios de <br /> aprender inglés de
                  manera divertida y efectiva.
                </p>
                <div className=" min-h-[44px] border border-[#CED3CE] rounded-lg bg-[#FFFFFF] shadow-[inset_0px_2px_5px_0px_#0000001A] flex items-center justify-center mt-6">
                  <p className="font-mont font-semibold text-[14px] leading-[20px] text-[#6B7280]">
                    https://www.epitenglish.com/r/tulink
                  </p>
                </div>

                {/* Botão */}
                <button className="py-2 px-4 text-[#059669]  border-2 border-[#059669] rounded-lg font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors mt-6">
                  Copiar enlace
                </button>
              </div>

              <div className="w-[376px] h-[284px] bg-white rounded-lg p-6 shadow-md flex flex-col relative">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full ">
                  {/* Ícone de número 2 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    2
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Tu amigo se registra y recibe una clase gratuita
                  </h3>
                </div>

                <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal mt-4">
                  Cuando tu amigo se registre utilizando tu enlace, podrá
                  acceder a una clase de prueba gratuita y comenzar su viaje de
                  aprendizaje.
                </p>

                <div className=" min-h-[44px] border border-[#CED3CE] rounded-lg bg-[#FFFFFF] shadow-[inset_0px_2px_5px_0px_#0000001A] flex items-center justify-center mt-6">
                  <p className="font-mont font-semibold text-[14px] leading-[20px] text-[#6B7280]">
                    https://www.epitenglish.com/r/tulink
                  </p>
                </div>

                {/* Botão */}
                <button className="py-2 px-4 text-[#059669]  border-2 border-[#059669] rounded-lg font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors mt-6">
                  Copiar enlace
                </button>
              </div>

              <div className="w-[376px] h-[284px] bg-white rounded-lg p-6 shadow-md flex flex-col relative">
                {/* Contêiner para o ícone e o título */}
                <div className="flex flex-row items-center gap-4 w-full">
                  {/* Ícone de número 3 */}
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: '32px',
                      height: '32px',
                      padding: '6px 16px',
                      gap: '8px',
                      borderRadius: '50%',
                      background:
                        'linear-gradient(149.36deg, #16C984 2.78%, #099E65 96.84%)',
                      boxShadow: `
        0px 4px 20px 0px #FFFAFA40 inset, 
        0px -4px 20px 0px #00000040 inset, 
        0px 6px 15px 0px #00000040`,
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    3
                  </div>

                  {/* Título ao lado do número */}
                  <h3 className="font-bold text-[#64748B] font-nunito text-[16px] w-[264px] leading-[19.2px]">
                    Gana recompensas cuando tu <br /> amigo se suscriba
                  </h3>
                </div>

                {/* Contêiner para o texto e a imagem */}

                {/* Descrição */}
                <p className="text-[#64748B] text-[14px] leading-[20px] font-nunito font-normal mt-4">
                  Si tu amigo adquiere una sscripción, recibirás un bono directo
                  en tu cuenta. Además, ganarás un porcentaje de sus futuras
                  compras.
                </p>
                <div className=" min-h-[44px] border border-[#CED3CE] rounded-lg bg-[#FFFFFF] shadow-[inset_0px_2px_5px_0px_#0000001A] flex items-center justify-center mt-6">
                  <p className="font-mont font-semibold text-[14px] leading-[20px] text-[#6B7280]">
                    https://www.epitenglish.com/r/tulink
                  </p>
                </div>

                {/* Botão */}
                <button className="py-2 px-4 text-[#059669]  border-2 border-[#059669] rounded-lg font-bold text-[14px] font-nunito leading-[20px] hover:bg-[#14B577] hover:text-white transition-colors mt-6">
                  Copiar enlace
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonus;
