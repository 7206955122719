import React, { useState } from 'react';
import { User } from '../../../@types/usersTypes';
import Input from '../../UI/Input';
import PhoneInput from 'react-phone-input-2';
import { Eye } from 'solar-icon-set';
import Toast from '../../Toast';
import useToast from '../../../hooks/useToast';

interface AccountProps {
  user: User | null;
  setActivePage: (page: string) => void;
}

const Account: React.FC<AccountProps> = () => {
  const { toast, handleShowToast, handleHideToast } = useToast();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="flex flex-col max-w-[1160px] h-full mt-4 gap-6">
      {toast.showToast && (
        <Toast
          message={toast.message}
          variant={toast.variant}
          description={toast.description}
          onClick={() => handleHideToast()}
        />
      )}
      {/* Main Content */}
      <div className="bg-[#F3F4F6]">
        <p className="font-mont text-[30px] text-[#0F172A] font-extrabold leading-[38.34px]">
          Mi Cuenta
        </p>
        <p className="font-mont text-[14px] text-[#94A3B8] font-semibold leading-[20px]">
          Administra tu información personal
        </p>
      </div>
      <div className="bg-white rounded-2xl p-8 gap-6">
        <div>
          <p className="font-nunito text-[24px] text-[#000000] font-semibold leading-[32.74px]">
            Información personal
          </p>
        </div>
        <div className="flex items-center gap-4 py-6">
          <Input
            name="name"
            label="Nombre Completo *"
            width="350px"
            height="44px"
            placeholder="Nombre completo"
          />
          <Input
            name="email"
            label="E-mail *"
            width="350px"
            height="44px"
            placeholder="E-mail"
          />
          <div>
            <label
              htmlFor="phone"
              className="h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#334155] opacity-100 font-nunito"
            >
              Teléfono *
            </label>
            <PhoneInput
              country={'es'} // País inicial
              value="" // Valor do input
              /* onChange={handlePhoneChange} // Função para lidar com alterações */
              containerStyle={{
                width: '350px',
                height: '44px',
                backgroundColor: 'white',
                border: '1px solid #D1D5DB', // Border similar ao input de email
                borderRadius: '8px',
                boxShadow: 'inset 0px 2px 5px 0px #0000001A', // Ajustando a sombra interna para ser igual ao input de email
                position: 'relative',
              }}
              inputStyle={{
                width: '100%',
                height: '100%',
                paddingLeft: '48px', // Espaço para a bandeira do país
                paddingRight: '16px',
                paddingTop: '8px',
                paddingBottom: '8px',
                backgroundColor: 'white',
                border: 'none',
                fontFamily: 'Mont, sans-serif',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#334155',
                boxShadow: 'inset 0px 2px 5px 0px #0000001A', // Ajustando a sombra interna para ser igual ao input de email
              }}
              buttonStyle={{
                position: 'absolute',
                top: '0',
                left: '0',
                height: '100%',
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingTop: '12px',
                paddingBottom: '12px',
                border: 'none',
                backgroundColor: 'transparent',
              }}
              dropdownStyle={{
                backgroundColor: 'white',
              }}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true,
                placeholder: 'Escribe tu Teléfono',
              }} // Propriedades adicionais do input
            />
          </div>
        </div>
        <button
          onClick={() =>
            handleShowToast(
              'success',
              'Teste titulo toast',
              'teste mensagem toast'
            )
          }
          className="font-mont font-bold text-[14px] leading-[20px] py-3 w-[191px] h-[44px] bg-[#34D399] text-white rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition"
        >
          Actualizar Datos
        </button>
      </div>
      <div className="flex flex-col bg-white rounded-2xl p-8 gap-6">
        <p className="font-nunito text-[24px] text-[#000000] font-semibold leading-[32.74px]">
          Contraseña
        </p>
        <div className="flex items-center gap-4">
          <div className="">
            <label
              className="h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#334155] opacity-100 font-nunito"
              htmlFor="old-password"
            >
              Contraseña anterior *
            </label>
            <input
              type={`${showPassword ? 'text' : 'password'}`}
              id="old-password"
              placeholder="Contraseña anterior"
              /* value={password}
              onChange={(e) => setPassword(e.target.value)} */
              className="w-[350px] h-[44px] px-4 py-2 border rounded-md"
            />
            <button
              type="button"
              onClick={() => setShowPassword((prev) => !prev)}
              className="relative -top-[30px] left-[320px] flex items-center pr-3 text-[#94A3B8]"
            >
              <Eye className="w-5 h-5" />
            </button>
          </div>
          <div className="">
            <label
              className="h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#334155] opacity-100 font-nunito"
              htmlFor="new-password"
            >
              Nueva contraseña *
            </label>
            <input
              type={`${showPassword ? 'text' : 'password'}`}
              id="new-password"
              placeholder="Nueva contraseña"
              /* value={password}
              onChange={(e) => setPassword(e.target.value)} */
              className="w-[350px] h-[44px] px-4 py-2 border rounded-md"
            />
            <button
              type="button"
              onClick={() => setShowPassword((prev) => !prev)}
              className="relative -top-[30px] left-[320px] flex items-center pr-3 text-[#94A3B8]"
            >
              <Eye className="w-5 h-5" />
            </button>
          </div>
          <div className="">
            <label
              className="h-[19px] px-[12px] text-[16px] font-bold leading-[19.2px] text-left text-[#334155] opacity-100 font-nunito"
              htmlFor="confirm-password"
            >
              Repetir nueva contraseña *
            </label>
            <input
              type={`${showPassword ? 'text' : 'password'}`}
              id="confirm-password"
              placeholder="Repetir nueva contraseña"
              /* value={password}
              onChange={(e) => setPassword(e.target.value)} */
              className="w-[350px] h-[44px] px-4 py-2 border rounded-md"
            />
            <button
              type="button"
              onClick={() => setShowPassword((prev) => !prev)}
              className="relative -top-[30px] left-[320px] flex items-center pr-3 text-[#94A3B8]"
            >
              <Eye className="w-5 h-5" />
            </button>
          </div>
        </div>
        <button className="font-mont font-bold text-[14px] leading-[20px] py-3 w-[191px] h-[44px] bg-[#34D399] text-white rounded-md opacity-50 shadow hover:bg-gradient-to-b hover:from-hover-green-primary hover:to-hover-green-secondary hover:opacity-100 transition">
          Activar Contraseña
        </button>
      </div>
    </div>
  );
};

export default Account;
