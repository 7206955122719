import React from 'react';
import starBlue from '../../../assets/StarBlue.png';
import * as SolarIconSet from 'solar-icon-set';
// import starYellow from '../../../assets/StarYellow.png';
import dashboardBG from '../../../assets/dashboardBG.jpeg';

const plansMock: {
  plan: string;
  expirationDate: string;
  subscribeDate: string;
  status: string;
}[] = [
  /* {
    plan: "10 e-Classes",
    expirationDate: "01/01/2025",
    subscribeDate: "10/10/2024",
    status: "Activa",
  },
  {
    plan: "20 e-Classes",
    expirationDate: "01/01/2025",
    subscribeDate: "10/10/2024",
    status: "Completada",
  }, */
];

const NoCredits: React.FC = () => {
  return (
    <>
      <section
        className="w-full h-[208px] rounded-2xl shadow-[0px_1px_3px_0px_#0000001A,0px_1px_2px_-1px_#0000001A] flex items-center justify-start mt-7"
        style={{ backgroundImage: `url(${dashboardBG})` }}
      >
        <div className="text-white text-start ml-6">
          <h3 className="text-[30px] font-extrabold font-mont text-[#0F172A] mb-2 leading-[38.34px]">
            ¡Elige el plan perfecto <br /> para tu aprendizaje!
          </h3>
          <p className="text-[16px] font-nunito font-bold text-[#0F172A] leading-[19.2px]">
            Nuestras opciones de suscripción se adaptan a tus <br /> necesidades
            y ritmo de estudio.
            <br />
            Ya sea que prefieras clases individuales o en grupos, <br /> tenemos
            el plan ideal para ti.
          </p>
        </div>
      </section>

      {/* Ajuste para garantir o scroll */}
      <div className="w-full h-auto mt-6 flex flex-col items-center rounded-2xl overflow-visible">
        <h3 className="mb-3 font-nunito font-bold text-[24px] leading-[32.74px] text-[#64748B]">
          Paquetes de e-Class: Clases Individuales
        </h3>

        <div className="flex justify-center gap-4">
          {/* Div 1 */}
          <div className="w-[368px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between">
            <div className="bg-[#0284C7] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                10 e-Classes
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                8,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 80,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[312px] h-[40px] border-2 border-[#0284C7] rounded-lg text-[#0284C7] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#0284C7] hover:text-white transition-colors">
                Comprar 10 e-Classes
              </button>
            </div>
          </div>

          {/* Div 2 */}
          <div className="w-[368px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between relative overflow-visible">
            {/* Selo posicionado com overflow visível */}
            <div
              className="absolute top-[-14px] right-[-7px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white z-10"
              style={{
                backgroundImage: `url('${starBlue}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="font-nunito font-normal text-[10px] leading-[14px]">
                Ahorro
              </p>
              <span className="font-nunito font-bold text-[14px] leading-[14px]">
                12,5%
              </span>
            </div>

            <div className="bg-[#0284C7] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                20 e-Classes
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                7,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 140,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[312px] h-[40px] border-2 border-[#0284C7] rounded-lg text-[#0284C7] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#0284C7] hover:text-white transition-colors">
                Comprar 20 e-Classes
              </button>
            </div>
          </div>

          {/* Div 3 */}
          <div className="relative w-[368px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between overflow-visible">
            {/* Selo posicionado com overflow visível */}
            <div
              className="absolute top-[-14px] right-[0px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white z-10"
              style={{
                backgroundImage: `url('${starBlue}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="font-nunito font-normal text-[10px] leading-[14px]">
                Ahorro
              </p>
              <span className="font-nunito font-bold text-[14px] leading-[14px]">
                25%
              </span>
            </div>

            <div className="bg-[#0284C7] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                40 e-Classes
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                6,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 240,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[312px] h-[40px] border-2 border-[#0284C7] rounded-lg text-[#0284C7] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#0284C7] hover:text-white transition-colors">
                Comprar 40 e-Classes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full h-auto mt-6 flex flex-col items-center rounded-2xl">
        <h3 className="mb-3 font-nunito font-bold text-[24px] leading-[32.74px] text-[#64748B]">
          Paquetes de e-Club: Clubs de conversación (mínimo 2 alumnos)
        </h3>

        <div className="flex justify-center gap-4">
          
          <div className="w-[368px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between">
            <div className="bg-[#F59E0B] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                1 e-Club
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                16,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 16,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[312px] h-[40px] border-2 border-[#F59E0B] rounded-lg text-[#F59E0B] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#F59E0B] hover:text-white transition-colors">
                Comprar 1 e-Club
              </button>
            </div>
          </div>

          <div className="w-[368px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between relative">
            <div
              className="absolute top-[-14px] right-[-7px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white"
              style={{
                backgroundImage: `url('${starYellow}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="font-nunito font-normal text-[10px] leading-[14px]">
                Ahorro
              </p>
              <span className="font-nunito font-bold text-[14px] leading-[14px]">
                12,5%
              </span>
            </div>

            <div className="bg-[#F59E0B] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                5 e-Club
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                14,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 70,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[312px] h-[40px] border-2 border-[#F59E0B] rounded-lg text-[#F59E0B] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#F59E0B] hover:text-white transition-colors">
                Comprar 5 e-Club
              </button>
            </div>
          </div>

          <div className="relative w-[368px] h-[246px] bg-white shadow-md rounded-2xl flex flex-col justify-between">
            <div
              className="absolute top-[-14px] right-[0px] w-[60px] h-[60px] flex flex-col items-center justify-center text-white z-10"
              style={{
                backgroundImage: `url('${starYellow}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="font-nunito font-normal text-[10px] leading-[14px]">
                Ahorro
              </p>
              <span className="font-nunito font-bold text-[14px] leading-[14px]">
                25%
              </span>
            </div>

            <div className="bg-[#F59E0B] text-center rounded-t-2xl pt-6 pb-3">
              <h3 className="text-white font-nunito font-extrabold text-[24px] leading-[32.74px]">
                10 e-Club
              </h3>
            </div>

            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-[#64748B] font-nunito font-normal text-[14px] leading-[20px]">
                12,00 € por sesión
              </p>
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-nunito font-extrabold text-[18px] leading-[24.55px] text-[#334155]">
                Total: 120,00 €
              </p>
            </div>

            <div className="flex justify-center mt-6 mb-8">
              <button className="w-[312px] h-[40px] border-2 border-[#F59E0B] rounded-lg text-[#F59E0B] font-nunito font-bold text-[14px] leading-[20px] hover:bg-[#F59E0B] hover:text-white transition-colors">
                Comprar 10 e-Club
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="bg-white rounded-2xl p-8 gap-6 shadow-md mt-6">
        <div>
          <p className="font-nunito text-[20px] text-[#64748B] font-bold leading-[27px]">
            Historial de Subscripciones
          </p>
        </div>
        <div>
          {plansMock.length > 0 ? (
            <table className="w-full my-4">
              <thead>
                <tr className="font-mont text-[#334155] text-[14px] leading-[20px] font-bold text-left">
                  <th className="w-[221.6px] h-[44px]">
                    Fecha de Subscripción
                  </th>
                  <th className="w-[221.6px] h-[44px]">Paquete</th>
                  <th className="w-[221.6px] h-[44px]">Vencimiento</th>
                  <th className="w-[221.6px] h-[44px]">Estado</th>
                </tr>
              </thead>
              <tbody>
                {plansMock.map((item) => {
                  return (
                    <tr
                      key={`${item.plan}-${item.status}-${item.expirationDate}`}
                      className="text-sm font-normal h-[58px] text-[#64748B] text-left border-b border-solid border-[#E5E7EB]"
                    >
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.subscribeDate}</p>
                      </td>
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.plan}</p>
                      </td>
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.expirationDate}</p>
                      </td>
                      <td className="w-[221.6px]">
                        <p className="font-mont">{item.status}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="w-max h-[24px] px-2 mt-4 flex items-center justify-center rounded-lg bg-[#FEECEF] text-[#BE123C] border-opacity-10 border-2 border-rose-700">
              <SolarIconSet.DangerCircle
                color="#B91C1C"
                size={16}
                className="mx-1"
              />
              <span className="font-nunito font-normal text-[16px] leading-[21.82px]">
                Aún no tienes historial de suscripciones disponible.
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NoCredits;
